import React from "react";
// import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
// import Select from "react-select";
import moment from "moment";
import Chart from "react-apexcharts";

// import LoadingIndicator from "./LoadingIndicator";
import { Locale } from "../utilities/localization/CustomLocalization";
import { CapitalizeJsonKeys, CheckNullValue, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, Delay, GetDurationText, GetPropIds, PopulateRoomData } from "../utilities/GlobalFunctions";
import { GlobalSetting, LayoutScreen, ResultTabPage, RoomTypeOptions } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";

import { get, ref } from "firebase/database";
import { doc, onSnapshot, setDoc, collection, getDocs, limit, query, where } from "firebase/firestore";
import { dbLogs, firestore } from "../utilities/Firebase";
import { AlertMode } from "./AlertComponent";

import * as alasql from 'alasql';
import * as XLSX from 'xlsx';
alasql.setXLSX(XLSX);

let ProcessingMessage_listenToNode = null;

export default class RoomResultComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({
        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        // redirect: false,
        // redirectLink: '/',
        isLoading: false,

        //Room Result.
        RoomData: this.props.RoomData,
        RoomResultReport: {},
        RoomResultList_Participant: [],
        RoomResultList_Outsider: [],
        RoomResultList_Absent: [],
        RoomResultList_Profiles: [],
        RoomResult_TotalRows: 0,
        RoomResult_PageIndex: 0,
        RoomResult_PageSize: 10,
        ShowRoomResultByRoomCodeModal: false,
        ToggleRoomResultButtons: true,
        // RoomResult_TabPage: ResultTabPage.List,
        Hidden_RoomResult: null,
        Hidden_RoomData: null,
        Hidden_RoomResult_PlayerQuestionResultDetail: null,
        Toggle_RoomResult_Detail: false,
        RoomResult_Detail_Components: null,
        Toggle_RoomResult_Logs: false,
        RoomResult_Logs: null,
        RoomResult_Logs_Components: null,
        RoomResult_RefDate: null,
        RoomResult_Logs_ToggleFull: false,
        Hidden_Room_Attendance_Present: null,
        Hidden_Room_Attendance_Absent: null,

        //2024.11.11
        Hidden_RoomResult_Outsider: null,
        Hidden_RoomResult_PlayerQuestionResultDetail_Outsider: null,

        //2024.07.19
        ResetResultModal_Toggle: false,
        IsAllowResetOnRoomResult: CheckObjectBoolean(useGlobal.getState().user, 'IsAllowResetOnRoomResult'),
        ResetResult_TargetIndex: -1,
        ResetResult_Processing: false,
        ResetResult_Modal: { authorId: 0, roomId: 0, uid: '', email: '', name: '' },

        //2024.11.19
        ReferenceLayoutScreen: LayoutScreen.None,
        ReferenceFilterByClassroom: '',
    });

    componentWillUnmount = () => {
        this.ProcessingMessage_Unsubscribe();
    }

    componentDidMount = () => { }

    //#region === Listen State Progression Message ===
    ProcessingMessage_Subscribe = (uid = '', roomCode = '') => {
        if (CheckNullValue(uid) !== null) {
            if (this.state.isDevMode)
                console.log('ProcessingMessage (Subscribed)');
            ProcessingMessage_listenToNode = onSnapshot(
                query(
                    collection(firestore, 'ILE_Portal_Status_Logs'),
                    where('uid', '==', uid)
                ), (querySnapshot) => {
                    const statusLogs = [];
                    querySnapshot.forEach((doc) => {
                        statusLogs.push(doc.data().status);
                    });
                    const preText = 'Loading Room Result for &#60;' + roomCode + '&#62;';
                    const status = statusLogs.join('');
                    if (CheckStringEmpty(status) !== '')
                        useAppService.getState().setModal('', preText + '<br />' + status, null, AlertMode.Loading);
                    if (this.state.isDevMode)
                        console.log('ProcessingMessage (Logs)\n' + preText + '\n' + statusLogs.join('\n'));
                });
        }
    }
    ProcessingMessage_Unsubscribe = () => {
        if (ProcessingMessage_listenToNode !== null) {
            if (typeof (ProcessingMessage_listenToNode) === 'function') {
                ProcessingMessage_listenToNode();
                useAppService.getState().setModal();
            }
        }
        if (this.state.isDevMode)
            console.log('ProcessingMessage (Unsubscribed)');
    }
    //#endregion === Listen State Progression Message ===

    //#region === Room Result === start ===//
    ToggleRoomResultModal = (showButtons = true) => {
        const toggle = !this.state.ShowRoomResultByRoomCodeModal;
        this.setState({
            ShowRoomResultByRoomCodeModal: toggle,
            ToggleRoomResultButtons: showButtons,
        }, async () => {
            if (toggle === false) {
                await Delay(200);
                this.ResetRoomResultParams();
            }
        });
    }
    LoadResultFromSelectedRoom = async (roomCode = '', roomId = '', forceToSyncAgain = false, forceToCalcAgain = false, skipSync = false, layoutScreen = LayoutScreen.ManageRoom, filterByClassroom = '') => {
        // useAppService.getState().setModal('Room Result', 'Coming soon...');

        if (CheckNullValue(roomCode) === null || CheckNullValue(roomId) === null)
            return null;

        this.ResetRoomResultParams();
        useAppService.getState().setModal('', 'Loading Result(s) of Room <' + roomCode + '>...', null, AlertMode.Loading);
        await Delay(500);

        const room_list = Array.isArray(this.props.List) ? CapitalizeJsonKeys(this.props.List) : [];
        if (this.state.isDevMode)
            console.log('LoadResultFromSelectedRoom = ' + roomCode + ' / ' + roomId
                //  + '\nList:\n' + JSON.stringify(room_list)
            );

        let roomData = null;
        const selectedRoomIndex = room_list.findIndex(x => x.RoomCode === roomCode && x.RoomId === roomId);
        if (selectedRoomIndex > -1)
            roomData = await PopulateRoomData(room_list[selectedRoomIndex]);

        if (roomData !== null) {
            if (this.state.isDevMode)
                console.log('LoadResultFromSelectedRoom =\n' + JSON.stringify(roomData));

            let roomResultReport = {};
            let roomResultList_Absent = null;
            let roomResultList_Outsider = null;
            let roomResultList_Participant = null;
            let roomResultList_Profiles = null;
            let totalRows = 0;
            let errorMessage = [];

            //SyncRoomResultToDB.
            const { success: syncSuccess, message: syncStatus } = await this.TriggerToSyncRoomResultByRoomId_ViaApi(roomCode, roomId, forceToSyncAgain, forceToCalcAgain, skipSync);
            if (!syncSuccess) {
                // useAppService.getState().setModal('Error', 'Failed to receive Room Result for &#60;' + roomCode + '&#62;.<br /><br />' + syncStatus, null, AlertMode.Alert);
                errorMessage.push(syncStatus);
            }
            // if (this.state.isDevMode)
            //     console.log('TriggerToSyncRoomResultByRoomId_ViaApi (result) / ' + syncSuccess + ' / ' + syncStatus);

            //GetRoomResult.
            if (syncSuccess) {
                await Delay(500);
                useAppService.getState().setModal('', 'Retrieving Result(s) of Room <' + roomCode + '>...', null, AlertMode.Loading);
                // await Delay(1000);
                const { success, report, participant, outsider, absent, profiles, message } = await this.GetRoomResult_ViaApi(roomCode, roomId, filterByClassroom);
                if (success) {
                    roomResultReport = report;
                    roomResultList_Participant = participant;
                    roomResultList_Outsider = outsider;
                    roomResultList_Absent = absent;
                    roomResultList_Profiles = profiles;
                    totalRows = participant.length;
                }
                else {
                    errorMessage.push(message);
                }
                if (this.state.isDevMode)
                    console.log('GetRoomResult_ViaApi / ' + success + ' / ' + CheckStringEmpty(message, '(n/a)') + ' / ' + totalRows);
            }

            this.setState({
                RoomData: roomData,
                RoomResultReport: roomResultReport,
                RoomResultList_Participant: roomResultList_Participant,
                RoomResultList_Outsider: roomResultList_Outsider,
                RoomResultList_Absent: roomResultList_Absent,
                RoomResultList_Profiles: roomResultList_Profiles,
                RoomResult_TotalRows: totalRows,
                RoomResult_PageIndex: 0,

                //2024.11.19
                ReferenceLayoutScreen: layoutScreen,
                ReferenceFilterByClassroom: filterByClassroom,
                IsAllowResetOnRoomResult: layoutScreen === LayoutScreen.ManageRoom,
            });
            useAppService.getState().setModal();
            await Delay(300);

            // if (Array.isArray(roomResultList) && roomResultList.length > 0) {
            if (Array.isArray(roomResultList_Participant) && Array.isArray(roomResultList_Outsider)) {
                this.ToggleRoomResultModal(roomResultList_Participant.length > 0 || roomResultList_Outsider.length > 0);
            }
            else {
                useAppService.getState().setModal('Operation Failed', 'Failed to receive result for Room &#60;' + roomCode + '&#62;<br /><br />Error:<br />' + errorMessage.join('<br />'));
            }
        }
        else {
            useAppService.getState().setModal('Error', 'Room <' + roomCode + '> not found.');
        }
    }
    ResetRoomResultParams = () => {
        this.setState({
            RoomData: null,
            RoomResultReport: {},
            RoomResultList_Participant: [],
            RoomResultList_Outsider: [],
            RoomResultList_Absent: [],
            RoomResultList_Profiles: [],
            RoomResult_TotalRows: 0,
            RoomResult_PageIndex: 0,
            RoomResult_PageSize: 10,
            Hidden_RoomResult: null,
            Hidden_RoomData: null,
            Hidden_RoomResult_PlayerQuestionResultDetail: null,
            Toggle_RoomResult_Detail: false,
            RoomResult_Detail_Components: null,

            Toggle_RoomResult_Logs: false,
            RoomResult_Logs: null,
            RoomResult_Logs_Components: null,
            RoomResult_RefDate: null,
            RoomResult_Logs_ToggleFull: false,

            ReferenceLayoutScreen: LayoutScreen.None,   //2024.11.19
        });
    }
    TriggerToSyncRoomResultByRoomId_ViaApi = async (roomCode = '', roomId = '', forceToSyncAgain = false, forceToCalcAgain = false, skipSync = false) => {
        if (skipSync)
            return { success: true, message: 'skipped sync' };    //2024.11.19
        const { uid, authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        if (this.state.isDevMode === false) {
            await setDoc(doc(firestore, 'ILE_Portal_Status_Logs', uid), { 'status': '', 'log': '', 'uid': uid });
            this.ProcessingMessage_Subscribe(uid, roomCode);
        }
        roomId = CheckStringEmpty(roomId);
        let success = false;
        let message = '';
        if (roomId !== '') {
            // const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
            const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/Room/Result/TriggerSync/'
                + organizerId + '/'
                + authorId + '/'
                + roomId + '/'
                + forceToSyncAgain + '/'
                + forceToCalcAgain;
            // Api/LearningCentre/Quiz/Room/Result/TriggerSync/{organizerId}/{authorId}/{roomId}/{forceToSyncAgain}/{forceToCalcAgain}
            if (this.state.isDevMode)
                console.log('TriggerToSyncRoomResultByRoomId_ViaApi (url)\n' + url);

            await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        // 'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    if (this.state.isDevMode)
                        console.log('TriggerToSyncRoomResultByRoomId_ViaApi (response)', JSON.stringify(data));
                    if (!data.success) {
                        message = CheckObjectStringEmpty(data, 'message');
                        if (this.state.isDevMode)
                            console.log('Error', 'api - room result sync (failed).\n' + JSON.stringify(data));
                    }
                    success = true;
                })
                .catch(error => {
                    message = CheckObjectStringEmpty(error, 'message');
                    if (this.state.isDevMode)
                        console.log('Error', 'api - room result sync (error).\n' + message);
                });
        }
        if (this.state.isDevMode === false)
            this.ProcessingMessage_Unsubscribe();
        return { success, message };
    }
    GetRoomResult_ViaApi = async (roomCode, roomId, filterByClassroom = '') => {

        roomCode = CheckStringEmpty(roomCode);
        roomId = CheckStringEmpty(roomId);

        let success = false;
        let participant = [];
        let outsider = [];
        let absent = [];
        let profiles = [];
        let report = null;
        let message = '';

        if (roomCode !== '' && roomId !== '') {
            const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
            const url = `${GlobalSetting.ApiUrl}Api/LearningCentre/Quiz/Room/Result/List/${organizerId}/${authorId}/${roomCode}/${roomId}/${encodeURI(CheckStringEmpty(filterByClassroom, 'null'))}`;
            // Api/LearningCentre/Quiz/Room/Result/List/{organizerId}/{authorId}/{roomCode}/{roomId}/{filterByClassroom}

            await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        // 'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    if (this.state.isDevMode)
                        console.log('GetRoomResult_ViaApi (response)', JSON.stringify(data));
                    if (data.success) {
                        if (data.data.hasOwnProperty('report') && CheckNullValue(data.data, 'report') !== null)
                            report = data.data.report;
                        if (data.data.hasOwnProperty('participant') && CheckNullValue(data.data, 'participant') !== null)
                            participant = data.data.participant;
                        if (data.data.hasOwnProperty('outsider') && CheckNullValue(data.data, 'outsider') !== null)
                            outsider = data.data.outsider;
                        if (data.data.hasOwnProperty('report') && CheckNullValue(data.data, 'report') !== null)
                            report = data.data.report;
                        if (data.data.hasOwnProperty('profiles') && CheckNullValue(data.data, 'profiles') !== null)
                            profiles = data.data.profiles;
                    }
                    else {
                        message = CheckObjectStringEmpty(data, 'message');
                        if (this.state.isDevMode)
                            console.log('Error', 'api - room result list (failed).\n' + JSON.stringify(data));
                    }
                    success = true;
                })
                .catch(error => {
                    message = CheckObjectStringEmpty(error, 'message');
                    if (this.state.isDevMode)
                        console.log('Error', 'api - room result list (error).\n' + message);
                });
        }
        if (success) {
            if (report !== null) {
                report = CapitalizeJsonKeys(report);
                if (this.state.isDevMode)
                    console.log('Report \n' + JSON.stringify(report));
            }
            if (participant.length > 0) {
                // participant = CapitalizeJsonKeys(participant);
                // participant = this.PopulateRoomResultList(participant);
                if (this.state.isDevMode)
                    console.log('List \n' + JSON.stringify(participant));
            }
            if (outsider.length > 0) {
                // outsider = CapitalizeJsonKeys(outsider);
                // outsider = this.PopulateRoomResultList(outsider);
                if (this.state.isDevMode)
                    console.log('Outsider \n' + JSON.stringify(outsider));
            }
            if (absent.length > 0) {
                // absent = CapitalizeJsonKeys(absent);
                // absent = this.PopulateRoomResultList(absent);
                if (this.state.isDevMode)
                    console.log('Absents \n' + JSON.stringify(absent));
            }
            if (profiles.length > 0) {
                profiles = CapitalizeJsonKeys(profiles);
                profiles = this.PopulateRoomResultList(profiles);
                if (this.state.isDevMode)
                    console.log('Profiles \n' + JSON.stringify(profiles));
            }
        }
        return { success, report, participant, outsider, absent, profiles, message };
    }
    PopulateRoomResultList = (list = []) => {
        let _list = [];
        if (list.length > 0) {
            for (let i = 0; i < list.length; i++) {
                _list.push({
                    Rank: CheckObjectNumber(list[i], 'Rank', i + 1),

                    Name: CheckObjectStringEmpty(list[i], 'Name'),
                    Result: CheckObjectStringEmpty(list[i], 'Result'),
                    TimeConsumed: CheckObjectStringEmpty(list[i], 'TimeConsumed'),
                    Scores: CheckObjectStringEmpty(list[i], 'Scores'),
                    Email: CheckObjectStringEmpty(list[i], 'Email'),

                    FinishTimestamp: CheckObjectStringEmpty(list[i], 'FinishTimestamp'),
                    SubmitedOnUtc: CheckObjectStringEmpty(list[i], 'SubmitedOnUtc'),

                    Classroom: CheckObjectStringEmpty(list[i], 'Classroom'),
                    Gender: CheckObjectStringEmpty(list[i], 'Gender'),
                    Race: CheckObjectStringEmpty(list[i], 'Race'),
                    Guardian: CheckObjectStringEmpty(list[i], 'Guardian'),
                    ContactNumber: CheckObjectStringEmpty(list[i], 'ContactNumber'),
                    NationalState: CheckObjectStringEmpty(list[i], 'NationalState'),
                    DistrictArea: CheckObjectStringEmpty(list[i], 'DistrictArea'),
                    SchoolCode: CheckObjectStringEmpty(list[i], 'SchoolCode'),
                    School: CheckObjectStringEmpty(list[i], 'School'),

                    QuestionResult: CheckObjectNullValue(list[i], 'QuestionResult', []),

                    Uid: CheckObjectStringEmpty(list[i], 'Uid'),
                    UserProfileId: CheckObjectNumber(list[i], 'UserProfileId'),
                    OrganizerId: CheckObjectNumber(list[i], 'OrganizerId'),
                    GenderId: CheckObjectNumber(list[i], 'GenderId'),
                    RaceId: CheckObjectNumber(list[i], 'RaceId'),
                    QuestionSetUniqueId: CheckObjectStringEmpty(list[i], 'QuestionSetUniqueId'),
                    TotalQuestion: CheckObjectNumber(list[i], 'TotalQuestion'),
                    TimeElapsed: CheckObjectNumber(list[i], 'TimeElapsed'),

                    ScoreDecimal: CheckObjectNumber(list[i], 'ScoreDecimal'),
                    IsOrganizerStudent: CheckObjectBoolean(list[i], 'IsOrganizerStudent'),
                });
            }
            _list.sort((a, b) => a.Rank - b.Rank);
        }
        return _list;
    }
    RoomResultListComponent = (tabName = '', list = []) => {
        let components = [];
        if (Array.isArray(list) && list.length > 0) {
            const profiles = this.state.RoomResultList_Profiles;
            for (let i = 0; i < list.length; i++) {
                const profile = profiles.find(x => x.UserProfileId === list[i]);
                if (CheckNullValue(profile) !== null) {
                    components.push(<tr key={'result-list-item-' + i}>
                        <td>{profile.Rank}</td>
                        <td style={{ textAlign: 'left' }}>{profile.Name}</td>
                        {/* <td>{profile.Result}</td> */}
                        <td><div dangerouslySetInnerHTML={{ __html: CheckObjectStringEmpty(profile, 'Result').replace(" (", "<br />(") }}></div></td>
                        <td>{GetDurationText(profile.TimeElapsed)}<br />{profile.TimeConsumed}</td>
                        <td>{Number(profile.Scores).toFixed(2)}%</td>
                        <td>{profile.Email}</td>
                        <td>
                            <Button className="btn btn-info" title="Show Logs" onClick={() => this.ShowLogs(tabName, i)}>...</Button>
                        </td>
                        <td>
                            <Button className="btn btn-info" title="Show Result Details" onClick={() => this.ShowResultDetail(tabName, i)}>...</Button>
                        </td>
                        {
                            this.state.IsAllowResetOnRoomResult ?
                                <td>
                                    <Button className="btn btn-danger" title="Reset Student Result" onClick={() => this.ToggleResetResultModal(tabName, i)}>Reset</Button>
                                </td>
                                : null
                        }
                    </tr>);
                }
            }
        }
        else {
            components.push(<tr><td colSpan='15' align='center'>- list is empty. -</td></tr>);
        }
        return (components);
    }
    RoomResultTableComponent = (tabName = '', list = []) => {
        return (<table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
            <thead>
                <tr>
                    <th style={{ width: 0 }}>Rank</th>
                    <th style={{ textAlign: 'left' }}>Name</th>
                    <th style={{ width: 100 }}>Result</th>
                    <th style={{ width: 0 }}>Time Consumed</th>
                    <th style={{ width: 0 }}>Scores</th>
                    <th>Email</th>
                    <th style={{ width: 0 }}>Logs</th>
                    <th style={{ width: 0 }}>Details</th>
                    {
                        this.state.IsAllowResetOnRoomResult ?
                            <th style={{ width: 0 }}>Action</th>
                            : null
                    }
                </tr>
            </thead>
            <tbody>
                {
                    Array.isArray(list) && list.length > 0 ?
                        this.RoomResultListComponent(tabName, list)
                        :
                        <tr>
                            <td colSpan='15' align='center'>- list is empty. -</td>
                        </tr>
                }
                {
                    // this.state.RoomResultList_Participant.length === 0 ? null :
                    //     PagingComponents(15, this.state.RoomResult_TotalRows, this.state.RoomResult_PageIndex, this.state.RoomResult_PageSize, this.CbFn_RoomResult_PagingComponents_PageSize, this.CbFn_RoomResult_PagingComponents_PageIndex)
                }
            </tbody>
        </table>);
    }
    RoomResultButtonsComponent = () => {
        // const roomData = this.state.RoomData;
        return (<div className="floating-div-corner-lower-left">
            <div className="content" style={{ width: 350 }}>
                <table style={{ width: '100%' }}>
                    <tbody>
                        {
                            this.state.ToggleRoomResultButtons ?
                                <>
                                    {/* <tr>
                                        <td>
                                            <Button className="rr-btn" variant="primary" >Save Room List (XLSX)</Button>
                                        </td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>
                                            <Button className="rr-btn" variant="primary" >Save Room's TimeTable (XLSX)</Button>
                                        </td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>
                                            <Button className="rr-btn" variant="secondary" >Preview Room's TimeTable (HTML)</Button>
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>
                                            <Button className="rr-btn" variant="primary" onClick={() => this.Save_RoomResultReport_AsXLSX()}>Save Room Result Report (XLSX)</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Button className="rr-btn" variant="secondary" onClick={() => this.Print_RoomResultReport()}>Print Room Result Report</Button>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <Button className="rr-btn" variant="primary" >Save Room's School Statistic Report (XLSX)</Button>
                                        </td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>
                                            <Button className="rr-btn" variant="secondary" >Print Room's Statistic Report</Button>
                                        </td>
                                    </tr> */}
                                </>
                                : null
                        }
                        <tr>
                            <td>
                                <button
                                    className='btn-link'
                                    type='button'
                                    onClick={() => this.setState({ ToggleRoomResultButtons: !this.state.ToggleRoomResultButtons })}
                                >{this.state.ToggleRoomResultButtons ? 'hide buttons' : 'show buttons'}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>);
    }
    getNameOfReport = (name = '') => {
        const reportName = CheckStringEmpty(name, 'Report');

        const groupId = CheckObjectStringEmpty(this.state.RoomData, 'GroupId');
        const group = useAppService.getState().groupOptions.find(x => String(x.id) === groupId);
        const groupName = CheckObjectStringEmpty(group, 'value', groupId);

        const subjectId = CheckObjectStringEmpty(this.state.RoomData, 'SubjectId');
        const subject = useAppService.getState().subjectOptions.find(x => String(x.id) === subjectId);
        const subjectName = CheckObjectStringEmpty(subject, 'value', subjectId);

        const roomCode = CheckObjectStringEmpty(this.state.RoomData, 'RoomCode');
        const roomId = CheckObjectStringEmpty(this.state.RoomData, 'RoomId');

        const fileName = reportName.replaceAll(' ', '-')
            + (groupName.length === 0 ? '' : "_" + groupName.replaceAll(' ', '-'))
            + (subjectName.length === 0 ? '' : "_" + subjectName.replaceAll(' ', '-'))
            + (roomCode.length === 0 ? '' : "_" + roomCode)
            + (roomId.length === 0 ? '' : "_" + roomId)
            + "_(" + moment().format('YYYY-MM-DD_HHmm') + ")";

        if (this.state.isDevMode) {
            console.log('getNameOfReport ='
                + '\n groupId = ' + groupId
                + '\n groupName = ' + groupName
                + '\n subjectId = ' + subjectId
                + '\n subjectName = ' + subjectName
                + '\n roomCode = ' + roomCode
                + '\n roomId = ' + roomId
                + '\n fileName = ' + fileName
            );
        }
        return fileName;
    }
    // DownloadReportAsXLSX = (tableId = '', reportName = '', isRankingList = true) => {
    //     //2023.10.26 - simplest method.
    //     if (tableId === '')
    //         return null;
    //     const table = document.getElementById(tableId);
    //     if (table !== null) {
    //         const wb = XLSX.utils.table_to_book(table, { raw: true });
    //         const filename = this.getNameOfReport(reportName, isRankingList);
    //         XLSX.writeFile(wb, filename + ".xlsx");
    //     }
    // }
    DownloadRoomResultAsXLSX = () => {

        const fileName = this.getNameOfReport('Room-Result');

        let ws_roomData = null;
        const roomData_com = document.getElementById('Table_RoomData');
        if (roomData_com !== null)
            ws_roomData = XLSX.utils.table_to_sheet(roomData_com, { raw: true });

        //2024.11.19 - disabled, not needed atm.
        // let ws_roomResult = null;
        // const roomResult_com = document.getElementById('Table_RoomResult');
        // if (roomResult_com !== null)
        //     ws_roomResult = XLSX.utils.table_to_sheet(roomResult_com, { raw: true });

        let ws_roomResult_PQRD = null;
        const roomResult_PQRD_com = document.getElementById('Table_RoomResult_PlayerQuestionResultDetail');
        if (roomResult_PQRD_com !== null)
            ws_roomResult_PQRD = XLSX.utils.table_to_sheet(roomResult_PQRD_com, { raw: true });

        let wb = XLSX.utils.book_new();
        // if (ws_roomData !== null && ws_roomResult !== null && ws_roomResult_PQRD !== null) {
        if (ws_roomData !== null && ws_roomResult_PQRD !== null) {

            //2024.11.19
            let extraInfo = '';
            switch (this.state.ReferenceLayoutScreen) {
                case LayoutScreen.ManageReportRoomsResult:
                    extraInfo += " (" + this.state.ReferenceFilterByClassroom + ")";
                    break;
                default: break;
            }

            // XLSX.utils.book_append_sheet(wb, ws_roomResult, 'Room Result', true);
            // XLSX.utils.book_append_sheet(wb, ws_roomData, 'Room Detail', true);
            // XLSX.utils.book_append_sheet(wb, ws_roomResult_PQRD, 'Player Question Result Detail', true);
            XLSX.utils.book_append_sheet(wb, ws_roomData, 'Room Detail', true);
            // XLSX.utils.book_append_sheet(wb, ws_roomResult_PQRD, 'Room Result (Partial)', true);
            XLSX.utils.book_append_sheet(wb, ws_roomResult_PQRD, 'Room Result' + extraInfo, true);
            // XLSX.utils.book_append_sheet(wb, ws_roomResult, 'Room Result (Detailed)', true);     //2024.11.19 - disabled, not needed atm.
            XLSX.writeFile(wb, fileName + ".xlsx");
        }
    }
    GetPlaceholder_Group = () => {
        if (this.state.RoomData !== null) {
            const groupOptions = useAppService.getState().groupOptions;
            let _findIndex = groupOptions.findIndex(x => Number(x.id) === CheckObjectNumber(this.state.RoomData, 'GroupId'));
            if (_findIndex > -1)
                return String(groupOptions[_findIndex].label);
        }
        return Locale("not-specify-group", this.state.locale);
    }
    GetPlaceholder_Subject = () => {
        if (this.state.RoomData !== null) {
            const subjectOptions = useAppService.getState().subjectOptions;
            let _findIndex = subjectOptions.findIndex(x => Number(x.id) === CheckObjectNumber(this.state.RoomData, 'SubjectId'));
            if (_findIndex > -1)
                return String(subjectOptions[_findIndex].label);
        }
        return Locale("not-specify-subject", this.state.locale);
    }
    SetHiddenRoomDataDiv = async () => {
        let _htmlStrings = null;
        const roomData = this.state.RoomData;
        if (roomData !== null) {
            const tmp_supportedFileFormats = CheckObjectNullValue(roomData, 'SupportedDocExt', []);
            const supportedFileFormats = Array.isArray(tmp_supportedFileFormats) ? tmp_supportedFileFormats.join(', ') : 'N/A';

            //2024.11.19
            let extraInfo = '';
            switch (this.state.ReferenceLayoutScreen) {
                case LayoutScreen.ManageReportRoomsResult:
                    extraInfo += "<tr><td>Classroom</td><td> : </td><td>" + this.state.ReferenceFilterByClassroom + "</td></tr>";
                    break;
                default: break;
            }

            _htmlStrings = "<table class='table tbStyle' id='Table_RoomData' padding={'10'}><tbody>" + extraInfo

                // + "<tr><td>Created Date</td><td> : </td><td>" + CheckObjectStringEmpty(roomData, 'Date') + "</td></tr>"
                + "<tr><td>Date Start</td><td> : </td><td>" + CheckObjectStringEmpty(roomData, 'DateStart') + "</td></tr>"
                + "<tr><td>Date End</td><td> : </td><td>" + CheckObjectStringEmpty(roomData, 'DateEnd') + "</td></tr>"
                + "<tr><td>Time Start</td><td> : </td><td>" + CheckObjectStringEmpty(roomData, 'TimeStart') + "</td></tr>"
                + "<tr><td>Time End</td><td> : </td><td>" + CheckObjectStringEmpty(roomData, 'TimeEnd') + "</td></tr>"

                + "<tr><td>Room Title</td><td> : </td><td>" + CheckObjectStringEmpty(roomData, 'RoomTitle') + "</td></tr>"
                + "<tr><td>Room Code</td><td> : </td><td>" + CheckObjectStringEmpty(roomData, 'RoomCode') + "</td></tr>"
                + "<tr><td>Room Id</td><td> : </td><td>" + CheckObjectStringEmpty(roomData, 'RoomId') + "</td></tr>"
                + "<tr><td>Room Type</td><td> : </td><td>" + RoomTypeOptions[CheckObjectNumber(roomData, 'RoomType')].label + "</td></tr>"

                + "<tr><td>Group</td><td> : </td><td>" + this.GetPlaceholder_Group() + "</td></tr>"
                + "<tr><td>Subject</td><td> : </td><td>" + this.GetPlaceholder_Subject() + "</td></tr>"
                + "<tr><td>Question Quantity</td><td> : </td><td>" + CheckObjectStringEmpty(roomData, 'QnQty') + "</td></tr>"
                + "<tr><td>Duration</td><td> : </td><td>" + GetDurationText(CheckObjectNumber(roomData, 'Duration'), true) + "</td></tr>"
                + "<tr><td>Duration per Question</td><td> : </td><td>" + Math.round(CheckObjectNumber(roomData, 'Duration') / CheckObjectNumber(roomData, 'QnQty')) + "</td></tr>"

                + (CheckObjectNumber(roomData, 'RoomType') !== 1 ? '' :
                    "<tr><td>Supported Document Extension(s)</td><td> : </td><td>" + supportedFileFormats + "</td></tr>")

                + "<tr><td>Remark</td><td> : </td><td>" + CheckObjectStringEmpty(roomData, 'Remark', '-') + "</td></tr>"
                + "<tr><td>ExtraUrl</td><td> : </td><td>" + CheckObjectStringEmpty(roomData, 'ExtraUrl', '-') + "</td></tr>"

                + "<tr><td>Random Question Mode</td><td> : </td><td>" + (CheckObjectBoolean(roomData, 'RandomQuestionMode') ? 'Yes' : 'No') + "</td></tr>"
                // + "<tr><td>Restrict Access To Time Range Only</td><td> : </td><td>" + (CheckObjectBoolean(roomData, 'RestrictAccessToTimeRangeOnly') ? 'Yes' : 'No') + "</td></tr>"
                // + "<tr><td>Enabled Statistic Report</td><td> : </td><td>" + (CheckObjectBoolean(roomData, 'EnableStatisticReport') ? 'Yes' : 'No') + "</td></tr>"

                + "</tbody></table>";
        }
        this.setState({
            Hidden_RoomData: CheckNullValue(_htmlStrings),
        });
    }
    SetHiddenRoomResultDiv = async () => {
        let _htmlStrings = null;
        const profiles = this.state.RoomResultList_Profiles;
        const resultList = this.state.RoomResultList_Participant;
        if (Array.isArray(resultList) && resultList !== null) {

            //QnQty.
            //2024.05.13 - added by request.
            //#region === Player Question Result Detail ===
            const roomData = this.state.RoomData;
            let QnQty = CheckObjectNumber(roomData, 'QnQty');
            if (QnQty <= 0 && Array.isArray(profiles)) {
                const tmp_questionResult = CheckObjectNullValue(profiles[0], 'QuestionResult');
                if (Array.isArray(tmp_questionResult))
                    QnQty = tmp_questionResult.length;
            }
            //#endregion

            //start.
            _htmlStrings = "<table class='table table-bordered tbStyle tb-min-width' id='Table_RoomResult' padding={'10'}>";

            //thead.
            let thead = ["<thead><tr>",
                "<th class='right'>Rank</th>",
                "<th>Email</th>",
                "<th class='left'>Name</th>",
                "<th>Classroom</th>",
                "<th>Scores</th>",
                "<th width='150'>Result</th>",
                "<th>Time&nbsp;<br/>Consumed</th>",
                "<th>Finish&nbsp;<br/>Timestamp</th>",
                "<th>Gender</th>",
                "<th>Race</th>",
                "<th>State</th>",
                "<th>District&nbsp;<br/>Area</th>",
                // "<th>School Code</th>",
                // "<th>School</th>",
                "<th>Guardian</th>",
                "<th>Contact&nbsp;<br/>Number</th>"];

            //#region === School ===
            //2024.11.15 - added by SIM EDU request.
            const { organizerId } = GetPropIds(useGlobal.getState().user);
            if (organizerId === 1) {
                thead.push("<th>School</th>");
            }
            //#endregion

            //#region === Player Question Result Detail ===
            //2024.05.13 - added by request.
            for (let q = 0; q < QnQty; q++) {
                thead.push("<th width='fit-content'>Q" + (q + 1) + "</th>");
            }
            thead.push("</tr></thead>");
            //#endregion

            _htmlStrings += thead.join('');

            //tbody.
            _htmlStrings += "<tbody>";
            if (resultList.length > 0) {
                resultList.map((profileId, key) => {
                    const profile = profiles.find(x => x.UserProfileId === profileId);
                    let _tmp = "<tr>";

                    // Object.keys(data).map((dataKey, index)=>{
                    //     _tmp += "<td>" + CheckObjectStringEmpty(profile, dataKey) + "</td>";
                    // });

                    _tmp += "<td>" + CheckObjectNumber(profile, 'Rank') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Email') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Name').replaceAll(' ', '&nbsp;') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Classroom').replaceAll(' ', '&nbsp;') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Scores') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Result').replaceAll(' ', '&nbsp;').replace("(", "<br/>(") + "</td>";
                    _tmp += "<td>" + GetDurationText(CheckObjectNumber(profile, 'TimeElapsed')) + "&nbsp;<br />" + CheckObjectStringEmpty(profile, 'TimeConsumed') + "</td>";

                    // _tmp += "<td>" + CheckObjectStringEmpty(profile, 'FinishTimestamp') + "</td>";
                    //2024.05.20
                    if (CheckObjectNullValue(profile, 'FinishTimestamp') !== null)
                        _tmp += "<td>" + moment.utc(CheckObjectStringEmpty(profile, 'FinishTimestamp')).local().format('YYYY-MM-DD HH:mm:ss') + "</td>";
                    else
                        _tmp += "<td>-</td>";

                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Gender') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Race') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'NationalState').replaceAll(' ', '&nbsp;') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'DistrictArea').replaceAll(' ', '&nbsp;') + "</td>";
                    // _tmp += "<td>" + CheckObjectStringEmpty(profile, 'SchoolCode') + "</td>";
                    // _tmp += "<td>" + CheckObjectStringEmpty(profile, 'School').replaceAll(' ', '&nbsp;') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Guardian').replaceAll(' ', '&nbsp;') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'ContactNumber') + "</td>";

                    //#region === School ===
                    //2024.11.15 - added by SIM EDU request.
                    if (organizerId === 1) {
                        _tmp += "<td>" + CheckObjectStringEmpty(profile, 'School').replaceAll(' ', '&nbsp;') + "</td>";
                    }
                    //#endregion

                    //#region === Player Question Result Detail ===
                    //2024.05.13 - added by request.
                    const qResult = CheckObjectNullValue(profile, 'QuestionResult');
                    if (Array.isArray(qResult)) {
                        for (let q = 0; q < QnQty; q++) {
                            if (q < qResult.length) {
                                _tmp += "<td>" + CheckObjectStringEmpty(qResult[q], 'Value', (CheckObjectBoolean(qResult[q], 'IsCorrect') ? '✔' : '❌')) + "</td>";
                            }
                            else {
                                _tmp += "<td>-</td>";
                            }
                        }
                    }
                    else {
                        for (let q = 0; q < QnQty; q++) {
                            _tmp += "<td>-</td>";
                        }
                    }
                    //#endregion

                    _tmp += "</tr>";
                    _htmlStrings += _tmp;
                    return null;
                });
            }
            else {
                _htmlStrings += "<td colspan='200' align='center'>- list is empty -</td>";
            }
            _htmlStrings += "</tbody>";

            //end.
            _htmlStrings += '</table>';
        }
        this.setState({
            Hidden_RoomResult: CheckNullValue(_htmlStrings),
        });
    }
    SetHiddenRoomResultPlayerQuestionResultDetailDiv = async () => {
        let _htmlStrings = null;
        const profiles = this.state.RoomResultList_Profiles;
        let resultList = this.state.RoomResultList_Participant;
        if (Array.isArray(resultList) && resultList !== null) {

            //2024.11.19
            switch (this.state.ReferenceLayoutScreen) {
                case LayoutScreen.ManageReportRoomsResult:
                    // console.log('SetHiddenRoomResultPlayerQuestionResultDetailDiv (resultList) (source) =\n' + JSON.stringify(resultList));
                    const unattendedStudentIds = CheckObjectNullValue(CheckObjectNullValue(this.state.RoomResultReport, 'ChartAttendance'), 'UnattendedStudentIds', []);
                    // console.log('SetHiddenRoomResultPlayerQuestionResultDetailDiv (absents) (source) =\n' + JSON.stringify(unattendedStudentIds));
                    const t_resultList = [...new Set([...resultList, ...unattendedStudentIds])];
                    let t_resultList_profile = [];
                    for (let r = 0; r < t_resultList.length; r++) {
                        const profile = profiles.find(x => x.UserProfileId === t_resultList[r]);
                        t_resultList_profile.push(profile);
                    }
                    t_resultList_profile.sort((a, b) => a.Name.localeCompare(b.Name));
                    resultList = t_resultList_profile.map((data, key) => { return data.UserProfileId; });
                    // const t_resultList_name = t_resultList_profile.map((data, key) => { return data.Name; });
                    // console.log('SetHiddenRoomResultPlayerQuestionResultDetailDiv (t_resultList) =\n' + JSON.stringify(t_resultList));
                    // console.log('SetHiddenRoomResultPlayerQuestionResultDetailDiv (t_resultList_name) =\n' + JSON.stringify(t_resultList_name));
                    // console.log('SetHiddenRoomResultPlayerQuestionResultDetailDiv (resultList) (after) =\n' + JSON.stringify(resultList));
                    break;
                default: break;
            }

            //start.
            _htmlStrings = "<table class='table tbStyle' id='Table_RoomResult_PlayerQuestionResultDetail' padding={'10'}><tbody>";

            //QnQty.
            const roomData = this.state.RoomData;
            let QnQty = CheckObjectNumber(roomData, 'QnQty');
            if (QnQty <= 0) {
                const tmp_questionResult = CheckObjectNullValue(profiles[0], 'QuestionResult');
                if (Array.isArray(tmp_questionResult))
                    QnQty = tmp_questionResult.length;
            }

            //thead.
            _htmlStrings += "<thead><tr>";

            //2024.11.20
            if (this.state.ReferenceLayoutScreen === LayoutScreen.ManageRoom)
                _htmlStrings += "<th>Rank</th>";
            else
                _htmlStrings += "<th>#</th>";

            _htmlStrings += "<th>Email</th><th>Name</th>";

            //2024.05.13 - added by request.
            //#region === more profile detail ===
            _htmlStrings += "<th>Classroom</th> <th>Scores</th> <th>Time<br/>Consumed</th>";
            //#endregion

            //#region === School ===
            //2024.11.15 - added by SIM EDU request.
            const { organizerId } = GetPropIds(useGlobal.getState().user);
            if (organizerId === 1) {
                _htmlStrings += "<th>School</th>";
            }
            //#endregion

            for (let th = 0; th < QnQty; th++) {
                _htmlStrings += "<th>Q" + (th + 1) + "</th>";
            }
            _htmlStrings += "</tr></thead>";

            //tbody.
            _htmlStrings += "<tbody>";
            if (resultList.length > 0) {
                for (let r = 0; r < resultList.length; r++) {

                    const profile = profiles.find(x => x.UserProfileId === resultList[r]);

                    _htmlStrings += "<tr>";

                    //2024.11.20
                    if (this.state.ReferenceLayoutScreen === LayoutScreen.ManageRoom)
                        _htmlStrings += "<td>" + CheckObjectNumber(profile, 'Rank') + "</td>";
                    else
                        _htmlStrings += "<td>" + (r + 1) + "</td>";

                    _htmlStrings += "<td>" + CheckObjectStringEmpty(profile, 'Email') + "</td>"
                        + "<td>" + CheckObjectStringEmpty(profile, 'Name') + "</td>";

                    //2024.05.13 - added by request.
                    //#region === more profile detail ===
                    _htmlStrings += "<td>" + CheckObjectStringEmpty(profile, 'Classroom').replaceAll(' ', '&nbsp;') + "</td>";
                    const scores = CheckObjectNumber(profile, 'Scores');
                    _htmlStrings += "<td>" + (scores === 0 ? '0.00' : scores.toFixed(2)) + "</td>";
                    const timeElapsed = CheckObjectNumber(profile, 'TimeElapsed');
                    _htmlStrings += "<td>" + (timeElapsed === 0 ? '(0.000)' : GetDurationText(timeElapsed) + "&nbsp;<br />" + CheckObjectStringEmpty(profile, 'TimeConsumed')) + "</td>";
                    //#endregion

                    //#region === School ===
                    //2024.11.15 - added by SIM EDU request.
                    if (organizerId === 1) {
                        _htmlStrings += "<td>" + CheckObjectStringEmpty(profile, 'School') + "</td>";
                    }
                    //#endregion

                    const qResult = CheckObjectNullValue(profile, 'QuestionResult');
                    if (Array.isArray(qResult)) {
                        for (let q = 0; q < QnQty; q++) {
                            if (q < qResult.length)
                                _htmlStrings += "<td>" + CheckObjectStringEmpty(qResult[q], 'Value', (CheckObjectBoolean(qResult[q], 'IsCorrect') ? '✔' : '❌')) + "</td>";
                            else
                                _htmlStrings += "<td></td>";
                        }
                    }
                    else {
                        for (let q = 0; q < QnQty; q++) {
                            _htmlStrings += "<td></td>";
                        }
                    }
                    _htmlStrings += "</tr>";
                }

                //2024.11.19
                //#region === total correct & total wrong for each question ===
                //2024.11.20
                let col_count = 6;
                if (organizerId === 1)  //2024.11.27
                    col_count = 7;
                // if (this.state.ReferenceLayoutScreen === LayoutScreen.ManageRoom) {
                //     col_count = 7;
                // }
                _htmlStrings += "<tr><td colspan='" + (col_count + QnQty) + "'></td></tr>";
                let totalCorrectWrong = [];
                for (let qs = 0; qs < QnQty; qs++) {
                    let totalCorrect = 0;
                    let totalWrong = 0;
                    for (let r = 0; r < resultList.length; r++) {
                        const profile_index = profiles.findIndex(x => x.UserProfileId === resultList[r]);
                        if (profile_index > -1) {
                            const profile = profiles[profile_index];
                            if (CheckObjectNumber(profile, 'ScoreDecimal') > 0) {
                                const qResult = CheckObjectNullValue(profile, 'QuestionResult');
                                if (Array.isArray(qResult) && qResult.length > 0 && qs < qResult.length) {
                                    const result = CheckObjectStringEmpty(qResult[qs], 'Value');
                                    // if (result !== '✔' && result !== '❌')
                                    //     result = CheckObjectBoolean(qResult[qs], 'IsCorrect') ? '✔' : '❌';
                                    switch (result) {
                                        case '✔': totalCorrect++; break;
                                        case '❌': totalWrong++; break;
                                        default: break;
                                    }
                                }
                            }
                        }
                    }
                    totalCorrectWrong.push({ idx: qs, correct: totalCorrect, wrong: totalWrong, unknown: resultList.length - totalCorrect - totalWrong });
                }
                _htmlStrings += "<tr><td colspan='" + (col_count - 1) + "'></td><td align='right'>❌</td>";
                for (let qs = 0; qs < QnQty; qs++) {
                    _htmlStrings += "<td>" + totalCorrectWrong[qs].wrong + "</td>";
                }
                _htmlStrings += +"</tr>";
                _htmlStrings += "<tr><td colspan='" + (col_count - 1) + "'></td><td align='right'>✔</td>";
                for (let qs = 0; qs < QnQty; qs++) {
                    _htmlStrings += "<td>" + totalCorrectWrong[qs].correct + "</td>";
                }
                _htmlStrings += +"</tr>";
                _htmlStrings += "<tr><td colspan='" + (col_count - 1) + "'></td><td align='right'>N/A</td>";
                for (let qs = 0; qs < QnQty; qs++) {
                    _htmlStrings += "<td>" + totalCorrectWrong[qs].unknown + "</td>";
                }
                _htmlStrings += +"</tr>";
                //#endregion === total correct & total wrong for each question ===
            }
            else {
                _htmlStrings += "<td colspan='200' align='center'>- list is empty -</td>";
            }
            _htmlStrings += "</tbody>";

            //end.
            _htmlStrings += "</table>";
        }
        this.setState({
            Hidden_RoomResult_PlayerQuestionResultDetail: CheckNullValue(_htmlStrings),
        });
    }
    Save_RoomResultReport_AsXLSX = async () => {
        const resultList = this.state.RoomResultList_Participant;
        if (Array.isArray(resultList) && resultList !== null) {
            //populate table data.
            await this.SetHiddenRoomDataDiv();
            // await this.SetHiddenRoomResultDiv();     //2024.11.19 - disabled, not needed atm.
            await this.SetHiddenRoomResultPlayerQuestionResultDetailDiv();
            //download.
            this.DownloadRoomResultAsXLSX();
        }
    }
    Print_RoomResultReport = async () => {
        const resultList = this.state.RoomResultList_Participant;
        if (Array.isArray(resultList) && resultList !== null) {
            //populate table data.
            await this.SetHiddenRoomDataDiv();
            await this.SetHiddenRoomResultDiv();
            await this.SetHiddenRoomResultPlayerQuestionResultDetailDiv();
            //show in new tab & trigger print.
            var mywindow = window.open('', 'PRINT', 'height=700,width=1000');
            mywindow.document.write('<html><head><title>Room Result Report</title>');
            mywindow.document.write('</head><body >');
            mywindow.document.write('<h1>' + document.title + '</h1>');
            mywindow.document.write(document.getElementById('Div_Print_RoomResult_hidden').innerHTML.replace('blue', 'black'));
            mywindow.document.write('</body></html>');
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
            mywindow.print();
            mywindow.close();
        }
    }
    //#region === Outsider(s) ===
    SetHiddenRoomResultDiv_Outsider = async () => {
        let _htmlStrings = null;
        const profiles = this.state.RoomResultList_Profiles;
        const resultList = this.state.RoomResultList_Outsider;
        if (Array.isArray(resultList) && resultList !== null) {

            //QnQty.
            //2024.05.13 - added by request.
            //#region === Player Question Result Detail ===
            const roomData = this.state.RoomData;
            let QnQty = CheckObjectNumber(roomData, 'QnQty');
            if (QnQty <= 0 && Array.isArray(profiles)) {
                const tmp_questionResult = CheckObjectNullValue(profiles[0], 'QuestionResult');
                if (Array.isArray(tmp_questionResult))
                    QnQty = tmp_questionResult.length;
            }
            //#endregion

            //start.
            _htmlStrings = "<table class='table table-bordered tbStyle tb-min-width' id='Table_RoomResult_Outsider' padding={'10'}>";

            //thead.
            let thead = ["<thead><tr>",
                "<th class='right'>Rank</th>",
                "<th>Email</th>",
                "<th class='left'>Name</th>",
                "<th>Classroom</th>",
                "<th>Scores</th>",
                "<th width='150'>Result</th>",
                "<th>Time&nbsp;<br/>Consumed</th>",
                "<th>Finish&nbsp;<br/>Timestamp</th>",
                "<th>Gender</th>",
                "<th>Race</th>",
                "<th>State</th>",
                "<th>District&nbsp;<br/>Area</th>",
                // "<th>School Code</th>",
                // "<th>School</th>",
                "<th>Guardian</th>",
                "<th>Contact&nbsp;<br/>Number</th>"];

            //#region === School ===
            //2024.11.15 - added by SIM EDU request.
            const { organizerId } = GetPropIds(useGlobal.getState().user);
            if (organizerId === 1) {
                thead.push("<th>School</th>");
            }
            //#endregion

            //#region === Player Question Result Detail ===
            //2024.05.13 - added by request.
            for (let q = 0; q < QnQty; q++) {
                thead.push("<th width='fit-content'>Q" + (q + 1) + "</th>");
            }
            thead.push("</tr></thead>");
            //#endregion
            _htmlStrings += thead.join('');

            //tbody.
            _htmlStrings += "<tbody>";
            if (resultList.length > 0) {
                resultList.map((profileId, key) => {
                    const profile = profiles.find(x => x.UserProfileId === profileId);
                    let _tmp = "<tr>";
                    // Object.keys(data).map((dataKey, index)=>{
                    //     _tmp += "<td>" + CheckObjectStringEmpty(data, dataKey) + "</td>";
                    // });
                    _tmp += "<td>" + CheckObjectNumber(profile, 'Rank') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Email') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Name').replaceAll(' ', '&nbsp;') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Classroom').replaceAll(' ', '&nbsp;') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Scores') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Result').replaceAll(' ', '&nbsp;').replace("(", "<br/>(") + "</td>";
                    _tmp += "<td>" + GetDurationText(CheckObjectNumber(profile, 'TimeElapsed')) + "&nbsp;<br />" + CheckObjectStringEmpty(profile, 'TimeConsumed') + "</td>";

                    // _tmp += "<td>" + CheckObjectStringEmpty(profile, 'FinishTimestamp') + "</td>";
                    //2024.05.20
                    if (CheckObjectNullValue(profile, 'FinishTimestamp') !== null)
                        _tmp += "<td>" + moment.utc(CheckObjectStringEmpty(profile, 'FinishTimestamp')).local().format('YYYY-MM-DD HH:mm:ss') + "</td>";
                    else
                        _tmp += "<td>-</td>";

                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Gender') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Race') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'NationalState').replaceAll(' ', '&nbsp;') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'DistrictArea').replaceAll(' ', '&nbsp;') + "</td>";
                    // _tmp += "<td>" + CheckObjectStringEmpty(profile, 'SchoolCode') + "</td>";
                    // _tmp += "<td>" + CheckObjectStringEmpty(profile, 'School').replaceAll(' ', '&nbsp;') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'Guardian').replaceAll(' ', '&nbsp;') + "</td>";
                    _tmp += "<td>" + CheckObjectStringEmpty(profile, 'ContactNumber') + "</td>";

                    //#region === School ===
                    //2024.11.15 - added by SIM EDU request.
                    if (organizerId === 1) {
                        _tmp += "<td>" + CheckObjectStringEmpty(profile, 'School').replaceAll(' ', '&nbsp;') + "</td>";
                    }
                    //#endregion

                    //#region === Player Question Result Detail ===
                    //2024.05.13 - added by request.
                    const qResult = CheckObjectNullValue(profile, 'QuestionResult');
                    if (Array.isArray(qResult)) {
                        for (let q = 0; q < QnQty; q++) {
                            if (q < qResult.length) {
                                _tmp += "<td>" + CheckObjectStringEmpty(qResult[q], 'Value', (CheckObjectBoolean(qResult[q], 'IsCorrect') ? '✔' : '❌')) + "</td>";
                            }
                            else {
                                _tmp += "<td>-</td>";
                            }
                        }
                    }
                    else {
                        for (let q = 0; q < QnQty; q++) {
                            _tmp += "<td>-</td>";
                        }
                    }
                    //#endregion

                    _tmp += "</tr>";
                    _htmlStrings += _tmp;
                    return null;
                });

                //2024.11.27
                //#region === total correct & total wrong for each question ===
                let col_count = 14;
                if (organizerId === 1)
                    col_count = 15;
                _htmlStrings += "<tr><td colspan='" + (col_count + QnQty) + "'></td></tr>";
                let totalCorrectWrong = [];
                for (let qs = 0; qs < QnQty; qs++) {
                    let totalCorrect = 0;
                    let totalWrong = 0;
                    for (let r = 0; r < resultList.length; r++) {
                        const profile_index = profiles.findIndex(x => x.UserProfileId === resultList[r]);
                        if (profile_index > -1) {
                            const profile = profiles[profile_index];
                            if (CheckObjectNumber(profile, 'ScoreDecimal') > 0) {
                                const qResult = CheckObjectNullValue(profile, 'QuestionResult');
                                if (Array.isArray(qResult) && qResult.length > 0 && qs < qResult.length) {
                                    const result = CheckObjectStringEmpty(qResult[qs], 'Value');
                                    // if (result !== '✔' && result !== '❌')
                                    //     result = CheckObjectBoolean(qResult[qs], 'IsCorrect') ? '✔' : '❌';
                                    switch (result) {
                                        case '✔': totalCorrect++; break;
                                        case '❌': totalWrong++; break;
                                        default: break;
                                    }
                                }
                            }
                        }
                    }
                    totalCorrectWrong.push({ idx: qs, correct: totalCorrect, wrong: totalWrong, unknown: resultList.length - totalCorrect - totalWrong });
                }
                _htmlStrings += "<tr><td colspan='" + (col_count - 1) + "'></td><td align='right'>❌</td>";
                for (let qs = 0; qs < QnQty; qs++) {
                    _htmlStrings += "<td>" + totalCorrectWrong[qs].wrong + "</td>";
                }
                _htmlStrings += +"</tr>";
                _htmlStrings += "<tr><td colspan='" + (col_count - 1) + "'></td><td align='right'>✔</td>";
                for (let qs = 0; qs < QnQty; qs++) {
                    _htmlStrings += "<td>" + totalCorrectWrong[qs].correct + "</td>";
                }
                _htmlStrings += +"</tr>";
                _htmlStrings += "<tr><td colspan='" + (col_count - 1) + "'></td><td align='right'>N/A</td>";
                for (let qs = 0; qs < QnQty; qs++) {
                    _htmlStrings += "<td>" + totalCorrectWrong[qs].unknown + "</td>";
                }
                _htmlStrings += +"</tr>";
                //#endregion === total correct & total wrong for each question ===
            }
            else {
                _htmlStrings += "<td colspan='200' align='center'>- list is empty -</td>";
            }
            _htmlStrings += "</tbody>";

            //end.
            _htmlStrings += '</table>';
        }
        this.setState({
            Hidden_RoomResult_Outsider: CheckNullValue(_htmlStrings),
        });
    }
    SetHiddenRoomResultPlayerQuestionResultDetailDiv_Outsider = async () => {
        let _htmlStrings = null;
        const profiles = this.state.RoomResultList_Profiles;
        const resultList = this.state.RoomResultList_Outsider;
        if (Array.isArray(resultList) && resultList !== null) {

            //start.
            _htmlStrings = "<table class='table tbStyle' id='Table_RoomResult_PlayerQuestionResultDetail_Outsider' padding={'10'}><tbody>";

            //QnQty.
            const roomData = this.state.RoomData;
            let QnQty = CheckObjectNumber(roomData, 'QnQty');
            if (QnQty <= 0) {
                const tmp_questionResult = CheckObjectNullValue(profiles[0], 'QuestionResult');
                if (Array.isArray(tmp_questionResult))
                    QnQty = tmp_questionResult.length;
            }

            //thead.
            _htmlStrings += "<thead><tr>";
            _htmlStrings += "<th>Rank</th> <th>Email</th> <th>Name</th>";

            //#region === more profile detail ===
            //2024.05.13 - added by request.
            _htmlStrings += "<th>Classroom</th> <th>Scores</th> <th>Time<br/>Consumed</th>";
            //#endregion

            //#region === School ===
            //2024.11.15 - added by SIM EDU request.
            const { organizerId } = GetPropIds(useGlobal.getState().user);
            if (organizerId === 1) {
                _htmlStrings += "<th>School</th>";
            }
            //#endregion

            for (let th = 0; th < QnQty; th++) {
                _htmlStrings += "<th>Q" + (th + 1) + "</th>";
            }
            _htmlStrings += "</tr></thead>";

            //tbody.
            _htmlStrings += "<tbody>";
            if (resultList.length > 0) {
                for (let r = 0; r < resultList.length; r++) {

                    const profile = profiles.find(x => x.UserProfileId === resultList[r]);

                    _htmlStrings += "<tr>";
                    _htmlStrings += "<td>" + CheckObjectNumber(profile, 'Rank') + "</td>"
                        + "<td>" + CheckObjectStringEmpty(profile, 'Email') + "</td>"
                        + "<td>" + CheckObjectStringEmpty(profile, 'Name') + "</td>";

                    //#region === more profile detail ===
                    //2024.05.13 - added by request.
                    _htmlStrings += "<td>" + CheckObjectStringEmpty(profile, 'Classroom').replaceAll(' ', '&nbsp;') + "</td>";
                    _htmlStrings += "<td>" + CheckObjectStringEmpty(profile, 'Scores') + "</td>";
                    _htmlStrings += "<td>" + GetDurationText(CheckObjectNumber(profile, 'TimeElapsed')) + "&nbsp;<br />" + CheckObjectStringEmpty(profile, 'TimeConsumed') + "</td>";
                    //#endregion

                    //#region === School ===
                    //2024.11.15 - added by SIM EDU request.
                    if (organizerId === 1) {
                        _htmlStrings += "<td>" + CheckObjectStringEmpty(profile, 'School') + "</td>";
                    }
                    //#endregion

                    const qResult = CheckObjectNullValue(profile, 'QuestionResult');
                    if (Array.isArray(qResult)) {
                        for (let q = 0; q < QnQty; q++) {
                            if (q < qResult.length) {
                                _htmlStrings += "<td>" + CheckObjectStringEmpty(qResult[q], 'Value', (CheckObjectBoolean(qResult[q], 'IsCorrect') ? '✔' : '❌')) + "</td>";
                            }
                            else {
                                _htmlStrings += "<td>-</td>";
                            }
                        }
                    }
                    else {
                        for (let q = 0; q < QnQty; q++) {
                            _htmlStrings += "<td>-</td>";
                        }
                    }
                    _htmlStrings += "</tr>";
                }

                //2024.11.27
                //#region === total correct & total wrong for each question ===
                let col_count = 6;
                if (organizerId === 1)
                    col_count = 7;
                _htmlStrings += "<tr><td colspan='" + (col_count + QnQty) + "'></td></tr>";
                let totalCorrectWrong = [];
                for (let qs = 0; qs < QnQty; qs++) {
                    let totalCorrect = 0;
                    let totalWrong = 0;
                    for (let r = 0; r < resultList.length; r++) {
                        const profile_index = profiles.findIndex(x => x.UserProfileId === resultList[r]);
                        if (profile_index > -1) {
                            const profile = profiles[profile_index];
                            if (CheckObjectNumber(profile, 'ScoreDecimal') > 0) {
                                const qResult = CheckObjectNullValue(profile, 'QuestionResult');
                                if (Array.isArray(qResult) && qResult.length > 0 && qs < qResult.length) {
                                    const result = CheckObjectStringEmpty(qResult[qs], 'Value');
                                    // if (result !== '✔' && result !== '❌')
                                    //     result = CheckObjectBoolean(qResult[qs], 'IsCorrect') ? '✔' : '❌';
                                    switch (result) {
                                        case '✔': totalCorrect++; break;
                                        case '❌': totalWrong++; break;
                                        default: break;
                                    }
                                }
                            }
                        }
                    }
                    totalCorrectWrong.push({ idx: qs, correct: totalCorrect, wrong: totalWrong, unknown: resultList.length - totalCorrect - totalWrong });
                }
                _htmlStrings += "<tr><td colspan='" + (col_count - 1) + "'></td><td align='right'>❌</td>";
                for (let qs = 0; qs < QnQty; qs++) {
                    _htmlStrings += "<td>" + totalCorrectWrong[qs].wrong + "</td>";
                }
                _htmlStrings += +"</tr>";
                _htmlStrings += "<tr><td colspan='" + (col_count - 1) + "'></td><td align='right'>✔</td>";
                for (let qs = 0; qs < QnQty; qs++) {
                    _htmlStrings += "<td>" + totalCorrectWrong[qs].correct + "</td>";
                }
                _htmlStrings += +"</tr>";
                _htmlStrings += "<tr><td colspan='" + (col_count - 1) + "'></td><td align='right'>N/A</td>";
                for (let qs = 0; qs < QnQty; qs++) {
                    _htmlStrings += "<td>" + totalCorrectWrong[qs].unknown + "</td>";
                }
                _htmlStrings += +"</tr>";
                //#endregion === total correct & total wrong for each question ===
            }
            else {
                _htmlStrings += "<td colspan='200' align='center'>- list is empty -</td>";
            }
            _htmlStrings += "</tbody>";

            //end.
            _htmlStrings += "</table>";
        }
        this.setState({
            Hidden_RoomResult_PlayerQuestionResultDetail_Outsider: CheckNullValue(_htmlStrings),
        });
    }
    Download_RoomResult_Outsider_AsXLSX = () => {

        const fileName = this.getNameOfReport('Room-Result-Outsider');

        let ws_roomResult = null;
        const roomResult_com = document.getElementById('Table_RoomResult_Outsider');
        if (roomResult_com !== null)
            ws_roomResult = XLSX.utils.table_to_sheet(roomResult_com, { raw: true });

        let ws_roomData = null;
        const roomData_com = document.getElementById('Table_RoomData');
        if (roomData_com !== null)
            ws_roomData = XLSX.utils.table_to_sheet(roomData_com, { raw: true });

        let ws_roomResult_PQRD = null;
        const roomResult_PQRD_com = document.getElementById('Table_RoomResult_PlayerQuestionResultDetail_Outsider');
        if (roomResult_PQRD_com !== null)
            ws_roomResult_PQRD = XLSX.utils.table_to_sheet(roomResult_PQRD_com, { raw: true });

        let wb = XLSX.utils.book_new();
        if (ws_roomResult !== null && ws_roomData !== null && ws_roomResult_PQRD !== null) {
            // XLSX.utils.book_append_sheet(wb, ws_roomResult, 'Room Result', true);
            // XLSX.utils.book_append_sheet(wb, ws_roomData, 'Room Detail', true);
            // XLSX.utils.book_append_sheet(wb, ws_roomResult_PQRD, 'Player Question Result Detail', true);
            XLSX.utils.book_append_sheet(wb, ws_roomData, 'Room Detail', true);
            XLSX.utils.book_append_sheet(wb, ws_roomResult_PQRD, 'Room Result (Partial)', true);
            XLSX.utils.book_append_sheet(wb, ws_roomResult, 'Room Result (Detailed)', true);
            XLSX.writeFile(wb, fileName + ".xlsx");
        }
    }
    Save_RoomResultReport_Outsider_AsXLSX = async () => {
        const resultList = this.state.RoomResultList_Participant;
        if (Array.isArray(resultList) && resultList !== null) {
            //populate table data.
            await this.SetHiddenRoomDataDiv();
            await this.SetHiddenRoomResultDiv_Outsider();
            await this.SetHiddenRoomResultPlayerQuestionResultDetailDiv_Outsider();
            //download.
            this.Download_RoomResult_Outsider_AsXLSX();
        }
    }
    Print_RoomResultReport_Outsider = async () => {
        const resultList = this.state.RoomResultList_Participant;
        if (Array.isArray(resultList) && resultList !== null) {
            //populate table data.
            await this.SetHiddenRoomDataDiv();
            await this.SetHiddenRoomResultDiv_Outsider();
            await this.SetHiddenRoomResultPlayerQuestionResultDetailDiv_Outsider();
            //show in new tab & trigger print.
            var mywindow = window.open('', 'PRINT', 'height=700,width=1000');
            mywindow.document.write('<html><head><title>Room Result Report (Outsider)</title>');
            mywindow.document.write('</head><body >');
            mywindow.document.write('<h1>' + document.title + '</h1>');
            mywindow.document.write(document.getElementById('Div_Print_RoomResult_hidden').innerHTML.replace('blue', 'black'));
            mywindow.document.write('</body></html>');
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
            mywindow.print();
            mywindow.close();
        }
    }
    //#endregion === Outsider(s) ===
    // CbFn_RoomResult_PagingComponents_PageSize = (pageSize = GlobalSetting.PageSize) => {
    //     this.setState({
    //         RoomResult_PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
    //     }, () => {
    //         // const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
    //         // localStorage.setItem(`ManageRoom_PageSize_${uid}_${organizerId}`, this.state.PageSize);
    //         // setTimeout(() => {
    //         //     this.LoadRoomList_ViaApi();
    //         // }, 500);
    //     });
    // }
    // CbFn_RoomResult_PagingComponents_PageIndex = (pageIndex = 0) => {
    //     this.setState({
    //         RoomResult_PageIndex: pageIndex,
    //     }, () => {
    //         // setTimeout(() => {
    //         //     this.LoadRoomList_ViaApi();
    //         // }, 500);
    //     });
    // }
    ShowResultDetail = (tabName = '', index = -1) => {
        if (tabName === '' || index < 0) {
            useAppService.getState().setModal('', 'invalid params');
            return null;
        }

        let resultList = this.state.RoomResultList_Participant;
        if (tabName === 'outsider')
            resultList = this.state.RoomResultList_Outsider;

        const profiles = this.state.RoomResultList_Profiles;
        const student = profiles.find(x => x.UserProfileId === resultList[index]);

        // const student = resultList[index];
        const questionResults = CheckObjectNullValue(student, 'QuestionResult', []);

        if (
            Array.isArray(resultList) === false || resultList.length === 0
            || (Array.isArray(questionResults) && Array(questionResults).length === 0)
        ) {
            useAppService.getState().setModal('', 'No result is found.');
            return null;
        }

        const roomData = this.state.RoomData;
        let QnQty = CheckObjectNumber(roomData, 'QnQty');
        if (QnQty <= 0) {
            if (Array.isArray(questionResults))
                QnQty = questionResults.length;
        }

        //html.
        let _htmlStrings = '<div class="player-result-detail">';
        _htmlStrings += '<table cellPadding="10" width="100%" class="table table-bordered table-hover" style="table-layout:fixed;"><tbody>';

        //info.
        _htmlStrings += '<tr><td colspan="2" align="center"><b>' + CheckObjectStringEmpty(student, 'Name') + '<br />' + CheckObjectStringEmpty(student, 'Email') + '</b></td></tr>';
        _htmlStrings += '<tr><td>Room Code : ' + CheckObjectStringEmpty(roomData, 'RoomCode')
            + '</td><td align="right">' + moment(CheckObjectStringEmpty(student, 'SubmitedOnUtc'), 'YYYY-MM-DD HH:mm:ss').local().format('MMM D, YYYY (YYYY-MM-DD)')
            + '</td></tr>';
        _htmlStrings += '<tr><td>' + this.GetPlaceholder_Group()
            + '</td><td align="right">' + this.GetPlaceholder_Subject() + '</td></tr>';
        _htmlStrings += '<tr><td colspan="2" align="center"><b>' + CheckObjectStringEmpty(roomData, 'RoomTitle') + '</b></td></tr>';

        //totals.
        let totalCorrect = 0;
        questionResults.forEach(element => {
            if (CheckObjectBoolean(element, 'IsCorrect'))
                totalCorrect++;
        });
        _htmlStrings += '<tr><td colspan="2"><div style="display:flex;column-gap:20px;justify-content:center;font-weight:bold;">'
            + '<span style="color:blue;">Correct (' + totalCorrect + ')</span>'
            + '<span style="color:red;">Wrong (' + (questionResults.length - totalCorrect) + ')</span>'
            + '<span>Total (' + questionResults.length + ')</span>'
            + '</div></td></tr>';

        //questions.
        _htmlStrings += '<tr><td colspan="2"><table class="table-bordered tb-row-hover" cellPadding="10" width="100%" style="background-color:#fff;">';
        _htmlStrings += '<thead><tr><th width="50%" style="text-align:right;">Question</th><th>Result</th></tr></thead><tbody>';
        questionResults.forEach(item => {
            _htmlStrings += '<tr><td align="right">(' + CheckObjectStringEmpty(item, 'Answer') + ') <b>Q ' + CheckObjectStringEmpty(item, 'No') + '</b></td>'
                + '<td>' + CheckObjectStringEmpty(item, 'Value') + ' (' + CheckObjectStringEmpty(item, 'Select') + ')</td></tr>';
        });
        _htmlStrings += '</tbody></table></td></tr>';

        //end.
        _htmlStrings += '</tbody></table>';
        _htmlStrings += '</div>';

        //state.
        this.setState({
            Toggle_RoomResult_Detail: true,
            RoomResult_Detail_Components: CheckNullValue(_htmlStrings),
        });
    }
    ShowLogs = async (tabName = '', index = -1) => {
        if (tabName === '' || index < 0) {
            useAppService.getState().setModal('', 'invalid params');
            return null;
        }
        useAppService.getState().setModal('', 'loading logs...', null, AlertMode.Loading);

        let resultList = this.state.RoomResultList_Participant;
        if (tabName === 'outsider')
            resultList = this.state.RoomResultList_Outsider;

        const profiles = this.state.RoomResultList_Profiles;
        const student = profiles.find(x => x.UserProfileId === resultList[index]);

        // const student = resultList[index];
        const uid = CheckObjectStringEmpty(student, 'Uid');
        const name = CheckObjectStringEmpty(student, 'Name');

        const roomData = this.state.RoomData;
        const roomCode = CheckObjectStringEmpty(roomData, 'RoomCode');
        // const roomId = CheckObjectStringEmpty(roomData, 'RoomId');

        let logs = null;

        //latest logs location - FS
        // await getDocs(query(collection(firestore, 'LiveQuiz_User_Logs/' + uid + '/' + roomId)))
        //     .then((querySnapshot) => {
        //         let data = [];
        //         querySnapshot.forEach((doc) => {
        //             data.push(doc.data());
        //         });
        //         if (data.length > 0) {
        //             const log = CheckObjectStringEmpty(data, 'Log');
        //             const time = CheckObjectStringEmpty(data, 'Time');
        //             if (log !== '' && time !== '')
        //                 logs.push({ Log: log, Time: time });
        //         }
        //     }).catch(error => {
        //         errorMessage = error;
        //     });

        //for legacy logs - RTDB.
        let ref_date = moment(CheckObjectStringEmpty(roomData, 'DateStart') + ' 00:00:00');
        if (logs === null) {
            //fetch from RTDB.
            let ref_path = uid + '/' + ref_date.year() + '/' + (ref_date.month() + 1) + '/' + ref_date.date();
            if (this.state.isDevMode)
                console.log('ref_path = \n' + ref(dbLogs, ref_path).toString());

            let loopAgain = false;
            try {
                do {
                    logs = null;
                    let _snapShot = null;
                    await get(ref(dbLogs, ref_path)).then((snapshot) => {
                        if (snapshot.exists())
                            _snapShot = snapshot.val();
                    }).catch(error => {
                        if (this.state.isDevMode)
                            console.log(name + ' | ' + uid + '\n get logs (failed) =\n' + JSON.stringify(error));
                    });
                    logs = _snapShot;

                    //temporary for backup. future will fetch from api.
                    if (logs === null) {
                        let history = null;
                        let data = [];
                        await getDocs(query(collection(firestore, 'LiveQuizHistory/' + uid + '/List'),
                            where('RoomCode', '==', roomCode), limit(1)))
                            .then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                });
                            }).catch(error => {
                                if (this.state.isDevMode)
                                    console.log('LiveQuizHistory (error) ' + error + ' | ' + name + ' | ' + uid);
                            });
                        if (data.length > 0)
                            history = data[0];
                        if (history !== null) {
                            if (this.state.isDevMode)
                                console.log('LiveQuizHistory (history) = \n' + JSON.stringify(history));
                            ref_date = moment(CheckObjectStringEmpty(history, 'SubmittedOnUTC', CheckObjectStringEmpty(history, 'LastEnteredDateTimeUtc')));
                            ref_path = uid + '/' + ref_date.year() + '/' + (ref_date.month() + 1) + '/' + ref_date.date();
                            loopAgain = true;
                            if (this.state.isDevMode)
                                console.log('loop again (history) ref_path = \n' + ref(dbLogs, ref_path).toString());
                        }
                    }
                    else {
                        loopAgain = false;
                    }
                } while (loopAgain);
            }
            catch (error) {
                useAppService.getState().setModal('', error.message);
            }

            //populate logs data.
            if (typeof (logs) === 'object' && logs !== null) {
                const keys = Object.keys(logs);
                const values = Object.values(logs);

                let _logArray = [];
                keys.map((data, key) => {
                    return _logArray.push({ Time: data, Log: values[key] });
                });
                _logArray.sort((a, b) => {
                    if (moment(a.Time) > moment(b.Time))
                        return 1;
                    if (moment(a.Time) < moment(b.Time))
                        return -1;
                    return 0;
                });

                logs = _logArray;
            }
        }

        //no logs or error.
        if (logs === null) {
            useAppService.getState().setModal('Logs :: ' + name, 'No log(s) available.');
            return null;
        } else {
            useAppService.getState().setModal('', 'populating logs...', null, AlertMode.Loading);
            if (this.state.isDevMode)
                console.log(name + ' | ' + uid + '\n logs = \n' + JSON.stringify(logs));
        }

        //html.
        let _htmlStrings = '<div class="player-logs">';
        _htmlStrings += this.PopulateLogsHtml(logs, roomCode, ref_date);
        _htmlStrings += '</div>';

        await Delay(1000);
        useAppService.getState().setModal();

        //state.
        this.setState({
            Toggle_RoomResult_Logs: true,
            RoomResult_Logs: logs,
            RoomResult_Logs_Components: CheckNullValue(_htmlStrings),
            RoomResult_RefDate: ref_date,
            RoomResult_Logs_ToggleFull: false,
        });
    }
    PopulateLogsHtml = (logs, roomCode, ref_date, showFullLog = false) => {

        //html.
        let _htmlStrings = '<table cellPadding="10" width="100%" class="table table-bordered table-hover">'
            + '<thead><tr><th width="105px">Time</th><th>Log</th></thead>'
            + '<tbody>';

        //logs.
        if (Array.isArray(logs)) {
            logs.map(async (data, key) => {
                let proceed = true;
                if (showFullLog === false)
                    if (data.Log.includes('Room') && data.Log.includes(roomCode) === false)
                        proceed = false;

                if (proceed) {
                    const hr = String(data.Time).substring(0, 2);
                    const min = String(data.Time).substring(2, 4);
                    const sec = String(data.Time).substring(4, 6);

                    const dateTime = ref_date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
                    const logText = String(data.Log).replace(roomCode, "<font color='blue'><u>" + roomCode + "</u></font>");

                    _htmlStrings += "<tr><td>" + moment.utc(dateTime).local().format("hh:mm:ss A")
                        + "</td><td width='665px' align='left'>"
                        + logText.match(new RegExp('.{1,' + 85 + '}', 'g')).join('<br />')
                        + "</td></tr>";
                }
            });
            _htmlStrings = _htmlStrings.replace(new RegExp('Platform', 'g'), "<font color='blue'>Platform</font>");
        }

        //end.
        _htmlStrings += '</tbody></table>';

        //state.
        return _htmlStrings;
    }
    ToggleFullLogs = async (toggleFull = false) => {
        this.setState({
            Toggle_RoomResult_Logs: false,
        });
        await Delay(300);
        const roomCode = CheckObjectStringEmpty(this.state.RoomData, 'RoomCode');
        const ref_date = this.state.RoomResult_RefDate;
        //html.        
        const _htmlStrings = '<div class="player-logs">' + this.PopulateLogsHtml(this.state.RoomResult_Logs, roomCode, ref_date, toggleFull) + '</div>';
        //state.
        this.setState({
            Toggle_RoomResult_Logs: true,
            RoomResult_Logs_Components: CheckNullValue(_htmlStrings),
            RoomResult_Logs_ToggleFull: toggleFull,
        });
    }
    CloseLogs = () => {
        this.setState({
            Toggle_RoomResult_Logs: false,
            RoomResult_Logs: null,
            RoomResult_Logs_Components: null,
            RoomResult_RefDate: null,
            RoomResult_Logs_ToggleFull: false,
        });
    }
    //2024.06.20
    SetHiddenRoomAttendance = async () => {
        const report = this.state.RoomResultReport;
        if (report === null)
            return null;
        const chartReport = CheckObjectNullValue(report, 'ChartAttendance');
        if (chartReport === null)
            return null;
        // const profiles = CheckObjectNullValue(report, 'StudentProfiles');
        // const profiles = this.state.RoomResultList_Participant;
        // if (profiles === null)
        //     return null;
        const profiles = this.state.RoomResultList_Profiles;
        if (profiles === null)
            return null;

        const attendedStudentIds = CheckObjectNullValue(chartReport, 'AttendedStudentIds', []);
        const unattendedStudentIds = CheckObjectNullValue(chartReport, 'UnattendedStudentIds', []);

        //Present.
        let present_profiles_sorted = [];
        if (Array.isArray(attendedStudentIds) && Array.isArray(profiles)) {
            attendedStudentIds.map((id, key) => {
                const data_index = profiles.findIndex(x => x.UserProfileId === id);
                if (data_index > -1)
                    present_profiles_sorted.push(profiles[data_index]);
                return null;
            });
            present_profiles_sorted.sort((a, b) => {
                if (a.Classroom !== b.Classroom)
                    return a.Classroom.localeCompare(b.Classroom);
                return a.Name.localeCompare(b.Name);
            });
        }

        //Absent.
        let absent_profiles_sorted = [];
        if (Array.isArray(unattendedStudentIds) && Array.isArray(profiles)) {
            unattendedStudentIds.map((id, key) => {
                const data_index = profiles.findIndex(x => x.UserProfileId === id);
                if (data_index > -1)
                    absent_profiles_sorted.push(profiles[data_index]);
                return null;
            });
            absent_profiles_sorted.sort((a, b) => {
                if (a.Classroom !== b.Classroom)
                    return a.Classroom.localeCompare(b.Classroom);
                return a.Name.localeCompare(b.Name);
            });
        }

        //Present.
        let html_Present = '<table class="table" id="attendance-table-present"><thead><tr>'
            + '<th style="width:40px;">#</th>'
            + '<th style="width:0px">Classroom</th>'
            + '<th style="width:60px;">Scores</th>'
            + '<th style="textAlign:left;">Name</th>'
            + '<th style="width:220px;">Email</th>'
            // + '<th style="width:220px;">Guardian</th>'
            // + '<th style="width:135px;">Contact Number</th>'
            + '</tr></thead><tbody>';
        if (present_profiles_sorted.length === 0) {
            html_Present += '<tr><td colspan="15">-n/a-</td></tr>';
        } else {
            present_profiles_sorted.map((data, key) => {
                html_Present += '<tr>'
                    + '<td>' + (key + 1) + '</td>'
                    + '<td>' + CheckObjectStringEmpty(data, 'Classroom', '-') + '</td>'
                    + '<td>' + CheckObjectNumber(data, 'Scores').toFixed(2) + '</td>'
                    + '<td style="textAlign:left">' + CheckObjectStringEmpty(data, 'Name', '-') + '</td>'
                    + '<td>' + CheckObjectStringEmpty(data, 'Email', '-') + '</td>'
                    // + '<td>' + CheckObjectStringEmpty(data, 'Guardian', '-') + '</td>'
                    // + '<td>' + CheckObjectStringEmpty(data, 'ContactNumber', '-') + '</td>'      
                    + '</tr>';
                return null;
            });
        }
        html_Present += '</tbody></table>';

        //Absent.
        let html_Absent = '<table class="table" id="attendance-table-absent"><thead><tr>'
            + '<th style="width:40px;">#</th>'
            + '<th style="width:0px">Classroom</th>'
            + '<th style="width:60px;">Scores</th>'
            + '<th style="textAlign:left;">Name</th>'
            + '<th style="width:220px;">Email</th>'
            // + '<th style="width:220px;">Guardian</th>'
            // + '<th style="width:135px;">Contact Number</th>'
            + '</tr></thead><tbody>';
        if (absent_profiles_sorted.length === 0) {
            html_Absent += '<tr><td colspan="15">-n/a-</td></tr>';
        } else {
            absent_profiles_sorted.map((data, key) => {
                html_Absent += '<tr>'
                    + '<td>' + (key + 1) + '</td>'
                    + '<td>' + CheckObjectStringEmpty(data, 'Classroom', '-') + '</td>'
                    + '<td>' + CheckObjectNumber(data, 'Scores').toFixed(2) + '</td>'
                    + '<td style="textAlign:left">' + CheckObjectStringEmpty(data, 'Name', '-') + '</td>'
                    + '<td>' + CheckObjectStringEmpty(data, 'Email', '-') + '</td>'
                    // + '<td>' + CheckObjectStringEmpty(data, 'Guardian', '-') + '</td>'
                    // + '<td>' + CheckObjectStringEmpty(data, 'ContactNumber', '-') + '</td>'
                    + '</tr>';
                return null;
            });
        }
        html_Absent += '</tbody></table>';

        //save.
        this.setState({
            Hidden_Room_Attendance_Present: html_Present,
            Hidden_Room_Attendance_Absent: html_Absent,
        });
    }
    //2024.06.20
    DownloadRoomAttendanceAsXLSX = () => {
        const fileName = this.getNameOfReport('Room-Attendance');

        let ws_roomData = null;
        const roomData_com = document.getElementById('Table_RoomData');
        if (roomData_com !== null)
            ws_roomData = XLSX.utils.table_to_sheet(roomData_com, { raw: true });

        let ws_roomAttendance_Present = null;
        const roomAttendance_Present_com = document.getElementById('attendance-table-present');
        if (roomAttendance_Present_com !== null)
            ws_roomAttendance_Present = XLSX.utils.table_to_sheet(roomAttendance_Present_com, { raw: true });

        let ws_roomAttendance_Absent = null;
        const roomAttendance_Absent_com = document.getElementById('attendance-table-absent');
        if (roomAttendance_Absent_com !== null)
            ws_roomAttendance_Absent = XLSX.utils.table_to_sheet(roomAttendance_Absent_com, { raw: true });

        let wb = XLSX.utils.book_new();
        if (ws_roomData !== null && ws_roomAttendance_Present !== null && ws_roomAttendance_Absent !== null) {
            XLSX.utils.book_append_sheet(wb, ws_roomData, 'Room Detail', true);
            XLSX.utils.book_append_sheet(wb, ws_roomAttendance_Present, 'Attendance (Present)', true);
            XLSX.utils.book_append_sheet(wb, ws_roomAttendance_Absent, 'Attendance (Absent)', true);
            XLSX.writeFile(wb, fileName + ".xlsx");
        }
    }
    //2024.06.20
    Save_Attendance_AsXLSX = async () => {
        const resultList = this.state.RoomResultList_Participant;
        if (Array.isArray(resultList) && resultList !== null) {
            //populate table data.
            await this.SetHiddenRoomDataDiv();
            await this.SetHiddenRoomAttendance();
            //download.
            this.DownloadRoomAttendanceAsXLSX();
        }
    }
    RoomResultAttendanceComponent = () => {
        const report = this.state.RoomResultReport;
        if (report === null)
            return null;
        const chartReport = CheckObjectNullValue(report, 'ChartAttendance');
        if (chartReport === null)
            return null;
        // const profiles = CheckObjectNullValue(report, 'StudentProfiles');
        // const profiles = this.state.RoomResultList_Participant;
        // if (profiles === null)
        //     return null;
        const profiles = this.state.RoomResultList_Profiles;
        if (profiles === null)
            return null;

        const totalStudents = CheckObjectNumber(chartReport, 'Total');
        const totalAttendedStudents = CheckObjectNumber(chartReport, 'TotalAttended');
        const totalUnattendedStudents = CheckObjectNumber(chartReport, 'TotalUnattended');
        const attendedStudentIds = CheckObjectNullValue(chartReport, 'AttendedStudentIds', []);
        const unattendedStudentIds = CheckObjectNullValue(chartReport, 'UnattendedStudentIds', []);

        let components = [];
        components.push(<div className="tab-info">
            {/* <span>Total Student(s) : {totalStudents}</span><br />
            <span>Present : {totalAttendedStudents}</span><br />
            <span>Absent  : {Math.abs(totalUnattendedStudents)}</span> */}
            <table className="tb-room-attendance-info" cellPadding={5} cellSpacing={5} width='100%'>
                <tbody>
                    <tr>
                        <td>Total Student(s)</td><td>:</td><td className="right">{totalStudents}</td>
                    </tr>
                    <tr>
                        <td>Present</td><td>:</td><td className="right">{totalAttendedStudents}</td>
                    </tr>
                    <tr>
                        <td>Absent</td><td>:</td><td className="right">{Math.abs(totalUnattendedStudents)}</td>
                    </tr>
                    <tr><td height={10}></td></tr>
                    <tr>
                        <td colSpan={3} className="no-padding">
                            <Button className="rr-btn" variant="primary" onClick={() => this.Save_Attendance_AsXLSX()}>Download Attendance</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>);

        //Pie Chart.
        const options = {
            labels: ['Present', 'Absent'],
        };
        const series = [totalAttendedStudents, totalUnattendedStudents];
        components.push(<div className="donut" style={{ display: 'flex', justifyContent: 'center', }}>
            <Chart options={options} series={series} type='donut' width='500'></Chart>
        </div>);

        //Present.
        let present_profiles_sorted = [];
        if (Array.isArray(attendedStudentIds) && Array.isArray(profiles)) {
            attendedStudentIds.map((id, key) => {
                const data_index = profiles.findIndex(x => x.UserProfileId === id);
                if (data_index > -1)
                    present_profiles_sorted.push(profiles[data_index]);
                return null;
            });
            present_profiles_sorted.sort((a, b) => {
                if (a.Classroom !== b.Classroom)
                    return a.Classroom.localeCompare(b.Classroom);
                return a.Name.localeCompare(b.Name);
            });
        }

        //Absent.
        let absent_profiles_sorted = [];
        if (Array.isArray(unattendedStudentIds) && Array.isArray(profiles)) {
            unattendedStudentIds.map((id, key) => {
                const data_index = profiles.findIndex(x => x.UserProfileId === id);
                if (data_index > -1)
                    absent_profiles_sorted.push(profiles[data_index]);
                return null;
            });
            absent_profiles_sorted.sort((a, b) => {
                if (a.Classroom !== b.Classroom)
                    return a.Classroom.localeCompare(b.Classroom);
                return a.Name.localeCompare(b.Name);
            });
        }

        let present_classroom_td_bgcolor = { backgroundColor: '#fff' };
        let present_td_check = 0;
        let absent_classroom_td_bgcolor = { backgroundColor: '#fff' };
        let absent_td_check = 0;

        //Present / Absent tabs.
        components.push(<Tabs defaultActiveKey={0}>
            <Tab eventKey={0} title='Present' className="base-tab tab-attendance-present">
                <span className="span-total">Total Student(s): {present_profiles_sorted.length}</span>
                <table className="table table-bordered table-hover tbStyle">
                    <thead>
                        <tr>
                            <th style={{ width: 40 }}>#</th>
                            <th style={{ width: 0 }}>Classroom</th>
                            <th style={{ width: 60 }}>Scores</th>
                            <th style={{ textAlign: 'left' }}>Name</th>
                            <th style={{ width: 220 }}>Email</th>
                            {/* <th style={{ width: 220 }}>Guardian</th> */}
                            {/* <th style={{ width: 135 }}>Contact Number</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            present_profiles_sorted.length === 0 ?
                                <tr><td colSpan={15}>-n/a-</td></tr>
                                :
                                present_profiles_sorted.map((data, key) => {
                                    if (key > 0) {
                                        if (CheckObjectStringEmpty(present_profiles_sorted[key - 1], 'Classroom') !== CheckObjectStringEmpty(data, 'Classroom'))
                                            present_td_check++;
                                        if (present_td_check % 2 === 0)
                                            present_classroom_td_bgcolor = { backgroundColor: '#fff' };
                                        else
                                            present_classroom_td_bgcolor = { backgroundColor: '#eee' };
                                    }
                                    return <tr key={'tb-present-' + CheckObjectStringEmpty(data, 'UserProfileId')}>
                                        <td>{key + 1}</td>
                                        <td style={present_classroom_td_bgcolor}>{CheckObjectStringEmpty(data, 'Classroom', '-')}</td>
                                        <td>{CheckObjectNumber(data, 'Scores').toFixed(2)}</td>
                                        <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'Name', '-')}</td>
                                        <td>{CheckObjectStringEmpty(data, 'Email', '-')}</td>
                                        {/* <td>{CheckObjectStringEmpty(data, 'Guardian', '-')}</td> */}
                                        {/* <td>{CheckObjectStringEmpty(data, 'ContactNumber', '-')}</td> */}
                                    </tr>;
                                })
                        }
                    </tbody>
                </table>
            </Tab>
            <Tab eventKey={1} title='Absent' className="base-tab tab-attendance-absent">
                <span className="span-total">Total Student(s): {absent_profiles_sorted.length}</span>
                <table className="table table-bordered table-hover tbStyle">
                    <thead>
                        <tr>
                            <th style={{ width: 40 }}>#</th>
                            <th style={{ width: 0 }}>Classroom</th>
                            <th style={{ width: 60 }}>Scores</th>
                            <th style={{ textAlign: 'left' }}>Name</th>
                            <th style={{ width: 220 }}>Email</th>
                            {/* <th style={{ width: 220 }}>Guardian</th> */}
                            {/* <th style={{ width: 135 }}>Contact Number</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            absent_profiles_sorted.length === 0 ?
                                <tr><td colSpan={15}>-n/a-</td></tr>
                                :
                                absent_profiles_sorted.map((data, key) => {
                                    if (key > 0) {
                                        if (CheckObjectStringEmpty(absent_profiles_sorted[key - 1], 'Classroom') !== CheckObjectStringEmpty(data, 'Classroom'))
                                            absent_td_check++;
                                        if (absent_td_check % 2 === 0)
                                            absent_classroom_td_bgcolor = { backgroundColor: '#fff' };
                                        else
                                            absent_classroom_td_bgcolor = { backgroundColor: '#eee' };
                                    }
                                    return <tr key={'tb-absent-' + CheckObjectStringEmpty(data, 'UserProfileId')}>
                                        <td>{key + 1}</td>
                                        <td style={absent_classroom_td_bgcolor}>{CheckObjectStringEmpty(data, 'Classroom', '-')}</td>
                                        <td>{CheckObjectNumber(data, 'Scores').toFixed(2)}</td>
                                        <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'Name', '-')}</td>
                                        <td>{CheckObjectStringEmpty(data, 'Email', '-')}</td>
                                        {/* <td>{CheckObjectStringEmpty(data, 'Guardian', '-')}</td> */}
                                        {/* <td>{CheckObjectStringEmpty(data, 'ContactNumber', '-')}</td> */}
                                    </tr>;
                                })
                        }
                    </tbody>
                </table>
            </Tab>
        </Tabs>);

        return (components);
    }
    RoomResultPassedFailedComponent = () => {
        const report = this.state.RoomResultReport;
        if (report === null)
            return null;
        const chartReport = CheckObjectNullValue(report, 'ChartPassedFailed');
        if (chartReport === null)
            return null;
        // const profiles = CheckObjectNullValue(report, 'StudentProfiles');
        // const profiles = this.state.RoomResultList_Participant;
        // if (profiles === null)
        //     return null;
        const profiles = this.state.RoomResultList_Profiles;
        if (profiles === null)
            return null;

        const totalStudents = CheckObjectNumber(chartReport, 'Total');
        const totalPassed = CheckObjectNumber(chartReport, 'TotalPassed');
        const totalFailed = CheckObjectNumber(chartReport, 'TotalFailed');
        const passedStudentIds = CheckObjectNullValue(chartReport, 'PassedStudentIds', []);
        const failedStudentIds = CheckObjectNullValue(chartReport, 'FailedStudentIds', []);

        let components = [];
        components.push(<div className="tab-info">
            <span>Total Student(s) : {totalStudents}</span><br />
            <span>Passed : {totalPassed}</span><br />
            <span>Failed : {totalFailed}</span>
        </div>);

        //Pie Chart.
        const options = {
            labels: ['Passed', 'Failed'],
        };
        const series = [totalPassed, totalFailed];
        components.push(<div className="donut" style={{ display: 'flex', justifyContent: 'center', }}>
            <Chart options={options} series={series} type='donut' width='500'></Chart>
        </div>);

        //Passed.
        let passed_profiles_sorted = [];
        if (Array.isArray(passedStudentIds) && Array.isArray(profiles)) {
            passedStudentIds.map((id, key) => {
                const data_index = profiles.findIndex(x => x.UserProfileId === id);
                if (data_index > -1)
                    passed_profiles_sorted.push(profiles[data_index]);
                return null;
            });
            passed_profiles_sorted.sort((a, b) => {
                if (a.Classroom !== b.Classroom)
                    return a.Classroom.localeCompare(b.Classroom);
                return a.Name.localeCompare(b.Name);
            });
        }

        //Failed.
        let failed_profiles_sorted = [];
        if (Array.isArray(failedStudentIds) && Array.isArray(profiles)) {
            failedStudentIds.map((id, key) => {
                const data_index = profiles.findIndex(x => x.UserProfileId === id);
                if (data_index > -1)
                    failed_profiles_sorted.push(profiles[data_index]);
                return null;
            });
            failed_profiles_sorted.sort((a, b) => {
                if (a.Classroom !== b.Classroom)
                    return a.Classroom.localeCompare(b.Classroom);
                return a.Name.localeCompare(b.Name);
            });
        }

        //Absent.
        let absent_profiles_sorted = [];
        const absents = this.state.RoomResultList_Absent;
        if (Array.isArray(absents)) {
            // let found = false;
            absents.map((id, key) => {
                const data_index = profiles.findIndex(x => x.UserProfileId === id);
                if (data_index > -1)
                    absent_profiles_sorted.push(profiles[data_index]);
                return null;
                // found = false;
                // if (Array.isArray(passedStudentIds))
                //     passedStudentIds.map((id, key) => {
                //         const data_index = absents.findIndex(x => x.UserProfileId === data.UserProfileId);
                //         if (data_index > -1)
                //             found = true;
                //         return null;
                //     });
                // if (found === false && Array.isArray(failedStudentIds))
                //     failedStudentIds.map((id, key) => {
                //         const data_index = absents.findIndex(x => x.UserProfileId === data.UserProfileId);
                //         if (data_index > -1)
                //             found = true;
                //         return null;
                //     });
                // if (found === false)
                //     absent_profiles_sorted.push(data);
                // return null;
            });
            absent_profiles_sorted.sort((a, b) => {
                if (a.Classroom !== b.Classroom)
                    return a.Classroom.localeCompare(b.Classroom);
                return a.Name.localeCompare(b.Name);
            });
        }

        //Passed / Failed tabs.
        components.push(<Tabs defaultActiveKey={0}>
            <Tab eventKey={0} title='Passed' className="base-tab tab-result-passed">
                <span className="span-total">Total Student(s): {passed_profiles_sorted.length}</span>
                <table className="table table-bordered table-hover tbStyle">
                    <thead>
                        <tr>
                            <th style={{ width: 40 }}>#</th>
                            <th style={{ width: 0 }}>Classroom</th>
                            <th style={{ width: 60 }}>Scores</th>
                            <th style={{ textAlign: 'left' }}>Name</th>
                            <th style={{ width: 220 }}>Email</th>
                            {/* <th style={{ width: 220 }}>Guardian</th> */}
                            {/* <th style={{ width: 135 }}>Contact Number</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            passed_profiles_sorted.length === 0 ?
                                <tr><td colSpan={15}>-n/a-</td></tr>
                                :
                                passed_profiles_sorted.map((data, key) => {
                                    return <tr key={'tb-passed-' + CheckObjectStringEmpty(data, 'UserProfileId')}>
                                        <td>{key + 1}</td>
                                        <td>{CheckObjectStringEmpty(data, 'Classroom', '-')}</td>
                                        <td>{CheckObjectNumber(data, 'Scores', '-').toFixed(2)}</td>
                                        <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'Name', '-')}</td>
                                        <td>{CheckObjectStringEmpty(data, 'Email', '-')}</td>
                                        {/* <td>{CheckObjectStringEmpty(data, 'Guardian', '-')}</td> */}
                                        {/* <td>{CheckObjectStringEmpty(data, 'ContactNumber', '-')}</td> */}
                                    </tr>;
                                })
                        }
                    </tbody>
                </table>
            </Tab>
            <Tab eventKey={1} title='Failed' className="base-tab tab-result-failed">
                <span className="span-total">Total Student(s): {failed_profiles_sorted.length}</span>
                <table className="table table-bordered table-hover tbStyle">
                    <thead>
                        <tr>
                            <th style={{ width: 40 }}>#</th>
                            <th style={{ width: 0 }}>Classroom</th>
                            <th style={{ width: 60 }}>Scores</th>
                            <th style={{ textAlign: 'left' }}>Name</th>
                            <th style={{ width: 220 }}>Email</th>
                            {/* <th style={{ width: 220 }}>Guardian</th> */}
                            {/* <th style={{ width: 135 }}>Contact Number</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            failed_profiles_sorted.length === 0 ?
                                <tr><td colSpan={15}>-n/a-</td></tr>
                                :
                                failed_profiles_sorted.map((data, key) => {
                                    return <tr key={'tb-failed-' + CheckObjectStringEmpty(data, 'UserProfileId')}>
                                        <td>{key + 1}</td>
                                        <td>{CheckObjectStringEmpty(data, 'Classroom', '-')}</td>
                                        <td>{CheckObjectNumber(data, 'Scores', '-').toFixed(2)}</td>
                                        <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'Name', '-')}</td>
                                        <td>{CheckObjectStringEmpty(data, 'Email', '-')}</td>
                                        {/* <td>{CheckObjectStringEmpty(data, 'Guardian', '-')}</td> */}
                                        {/* <td>{CheckObjectStringEmpty(data, 'ContactNumber', '-')}</td> */}
                                    </tr>;
                                })
                        }
                    </tbody>
                </table>
            </Tab>
            {
                absent_profiles_sorted.length === 0 ? null :
                    <Tab eventKey={2} title='Absent' className="base-tab tab-result-absent">
                        <span className="span-total">Total Student(s): {absent_profiles_sorted.length}</span>
                        <table className="table table-bordered table-hover tbStyle">
                            <thead>
                                <tr>
                                    <th style={{ width: 40 }}>#</th>
                                    <th style={{ width: 0 }}>Classroom</th>
                                    <th style={{ width: 60 }}>Scores</th>
                                    <th style={{ textAlign: 'left' }}>Name</th>
                                    <th style={{ width: 220 }}>Email</th>
                                    {/* <th style={{ width: 220 }}>Guardian</th> */}
                                    {/* <th style={{ width: 135 }}>Contact Number</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    absent_profiles_sorted.length === 0 ?
                                        <tr><td colSpan={15}>-n/a-</td></tr>
                                        :
                                        absent_profiles_sorted.map((data, key) => {
                                            return <tr key={'tb-absent-' + CheckObjectStringEmpty(data, 'UserProfileId')}>
                                                <td>{key + 1}</td>
                                                <td>{CheckObjectStringEmpty(data, 'Classroom', '-')}</td>
                                                <td>{CheckObjectNumber(data, 'Scores').toFixed(2)}</td>
                                                <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'Name', '-')}</td>
                                                <td>{CheckObjectStringEmpty(data, 'Email', '-')}</td>
                                                {/* <td>{CheckObjectStringEmpty(data, 'Guardian', '-')}</td> */}
                                                {/* <td>{CheckObjectStringEmpty(data, 'ContactNumber', '-')}</td> */}
                                            </tr>;
                                        })
                                }
                            </tbody>
                        </table>
                    </Tab>
            }
        </Tabs>);

        return (components);
    }
    RoomResultTrendsComponent = () => {
        const report = this.state.RoomResultReport;
        if (report === null)
            return null;
        const chartReport = CheckObjectNullValue(report, 'ChartTrend');
        if (chartReport === null)
            return null;
        // const profiles = CheckObjectNullValue(report, 'StudentProfiles');
        // const profiles = this.state.RoomResultList_Participant;
        // if (profiles === null)
        //     return null;
        const profiles = this.state.RoomResultList_Profiles;
        if (profiles === null)
            return null;

        const totalStudents = CheckObjectNumber(chartReport, 'Total');
        const educationStages = CheckObjectNullValue(chartReport, 'EducationStages', []);
        const studentEducationStages = CheckObjectNullValue(chartReport, 'StudentEducationStages', []);

        let components = [];
        components.push(<div className="tab-info">
            <span>Total Student(s) : {totalStudents}</span>
            {
                Object.keys(studentEducationStages).map((name, key) => {
                    const stage = CheckObjectNullValue(studentEducationStages, name);
                    // if (Array.isArray(stage))
                    //     return (<><br /><span>{name} : {stage.length}</span></>);
                    // return null;
                    return (<><br /><span>{name} : {Array.isArray(stage) ? stage.length : 0}</span></>);
                })
            }
        </div>);

        //Tabs - populate data.
        let categories = [];
        let series_data = [];
        let tabs = [];
        if (Array.isArray(educationStages)) {
            educationStages.map((data, key) => {
                let tableItem = [];
                const name = CheckObjectStringEmpty(data, 'Name');
                const rangeBegin = CheckObjectStringEmpty(data, 'RangeBegin');
                const rangeEnd = CheckObjectStringEmpty(data, 'RangeEnd');
                categories.push(`${name} (${rangeBegin}~${rangeEnd})`);

                let profiles_found = [];
                const stage = CheckObjectNullValue(studentEducationStages, name);
                if (stage !== null && Array.isArray(stage)) {
                    series_data.push(stage.length);

                    if (stage.length > 0) {
                        // let profiles_found = [];
                        stage.map((id, key) => {
                            const data_index = profiles.findIndex(x => x.UserProfileId === id);
                            if (data_index > -1) {
                                // let profile = profiles[data_index];
                                // const findIndex = list.findIndex(x => x.UserProfileId === profile.Id);
                                // if (findIndex > -1)
                                //     profile.Scores = list[findIndex].Scores;
                                profiles_found.push(profiles[data_index]);

                                // const data = profiles[data_index];
                                // return tableItem.push(<tr>
                                //     <td>{key + 1}</td>
                                //     <td>{CheckObjectStringEmpty(data, 'Classroom', '-')}</td>
                                //     <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'Name', '-')}</td>
                                //     <td>{CheckObjectStringEmpty(data, 'Email', '-')}</td>
                                //     <td>{CheckObjectStringEmpty(data, 'Guardian', '-')}</td>
                                //     <td>{CheckObjectStringEmpty(data, 'ContactNumber', '-')}</td>
                                // </tr>);
                            }
                            // return tableItem.push(<tr><td>{key + 1}</td><td colSpan={15} style={{ textAlign: 'left' }}>{id}</td></tr>);
                            return null;
                        });
                        profiles_found.sort((a, b) => {
                            const scoreComparison = b.Scores - a.Scores;
                            if (scoreComparison !== 0) {
                                return scoreComparison;
                            }
                            return a.Classroom.localeCompare(b.Classroom);
                            // if (a.Classroom !== b.Classroom)
                            //     return a.Classroom.localeCompare(b.Classroom);
                            // return a.Name.localeCompare(b.Name);
                        });
                        profiles_found.map((data, key) => {
                            return tableItem.push(<tr key={'tb-trend-' + CheckObjectStringEmpty(data, 'UserProfileId')}>
                                <td>{key + 1}</td>
                                <td>{CheckObjectStringEmpty(data, 'Classroom', '-')}</td>
                                <td>{CheckObjectNumber(data, 'Scores', '-').toFixed(2)}</td>
                                <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'Name', '-')}</td>
                                <td>{CheckObjectStringEmpty(data, 'Email', '-')}</td>
                                <td>{CheckObjectStringEmpty(data, 'Guardian', '-')}</td>
                                <td>{CheckObjectStringEmpty(data, 'ContactNumber', '-')}</td>
                            </tr>);
                        })
                    }
                    // else {
                    //     tableItem.push(<tr><td colSpan={15}>- list is empty -</td></tr>);
                    // }
                }
                else {
                    series_data.push(0);
                    tableItem.push(<tr><td colSpan={15}>- list is empty -</td></tr>);
                }
                tabs.push(<Tab eventKey={key} title={name} className="base-tab tab-stage">
                    <table className="table table-bordered table-hover tbStyle">
                        <thead>
                            <tr><th style={{ textAlign: 'left' }} colSpan={15}>{'Student(s): ' + profiles_found.length}</th></tr>
                            <tr>
                                <th style={{ width: 40 }}>#</th>
                                <th style={{ width: 0 }}>Classroom</th>
                                <th style={{ width: 60 }}>Scores</th>
                                <th style={{ textAlign: 'left' }}>Name</th>
                                <th style={{ width: 220 }}>Email</th>
                                {/* <th style={{ width: 220 }}>Guardian</th> */}
                                {/* <th style={{ width: 135 }}>Contact Number</th> */}
                            </tr>
                        </thead>
                        <tbody>{tableItem}</tbody>
                    </table>
                </Tab>);
                return null;
            });
        }

        //Line Chart.
        const options = {
            stroke: {
                // curve: 'smooth',
                curve: 'straight',
            },
            markers: {
                size: 5,
                shape: 'circle',
                strokeColor: '#fff',
            },
            xaxis: {
                categories: categories
            }
        };
        const series = [{
            name: 'Student(s)',
            data: series_data
        }];
        components.push(<div className="line" style={{ display: 'flex', justifyContent: 'center', }}>
            <Chart options={options} series={series} type='line' width='700' height='500'></Chart>
        </div>);

        //Tabs.
        components.push(<Tabs defaultActiveKey={0}>{tabs}</Tabs>);

        return (components);
    }
    //#endregion === Room Result === end ===//

    //#region === Reset Room Result === start ===//
    //2024.07.19
    ToggleResetResultModal = async (tabName = '', index = -1) => {
        if (this.state.IsAllowResetOnRoomResult === false)
            return null;

        const toggle = !this.state.ResetResultModal_Toggle;
        this.setState({
            ResetResultModal_Toggle: toggle,
            ResetResult_TargetIndex: index,
            ResetResult_Modal: null,
            ResetResult_Processing: false,
        });
        await Delay(0);
        if (toggle) {
            const { authorId } = GetPropIds(useGlobal.getState().user);

            const resultList = this.state.RoomResultList_Participant;
            const profiles = this.state.RoomResultList_Profiles;
            const index = CheckNumber(this.state.ResetResult_TargetIndex, -1);

            if (index > -1 && Array.isArray(resultList) && Array.isArray(profiles)) {
                //uid.
                const student = profiles.find(x => x.UserProfileId === resultList[index]);
                const uid = CheckObjectStringEmpty(student, 'Uid');
                const email = CheckObjectStringEmpty(student, 'Email');
                const name = CheckObjectStringEmpty(student, 'Name');
                if (this.state.isDevMode)
                    console.log(`ConfirmResetStudentResult (Student) (${uid}) =\n${JSON.stringify(student)})`);

                //room id.
                const roomData = this.state.RoomData;
                const roomId = CheckObjectNumber(roomData, 'Id');

                //set modal.
                this.setState({
                    ResetResult_Modal: { authorId: authorId, roomId: roomId, uid: uid, email: email, name: name },
                });
            }
        }
        await Delay(0);
        if (this.state.isDevMode)
            console.log(`ToggleResetResultModal (${tabName}) ${index} \n${JSON.stringify(this.state.ResetResult_Modal)}`);
    }
    //2024.07.19
    ConfirmResetStudentResult = async () => {
        if (this.state.IsAllowResetOnRoomResult === false || this.state.ResetResult_Modal === null)
            return null;

        const { authorId, roomId, uid, name } = this.state.ResetResult_Modal;
        let success = false;
        let message = '';

        if (this.state.isDevMode)
            console.log(`ConfirmResetStudentResult (${authorId}) (${roomId}) (${uid}) (${name})`);

        //api.
        if (authorId > 0 && roomId > 0 && uid !== '') {

            //loading.
            useAppService.getState().setModal(`Student (${name})`, 'reseting result...', null, AlertMode.Loading);
            //calling api.
            await fetch(GlobalSetting.ApiUrl + `Api/LearningCentre/Quiz/Room/ResultAndHistory/Reset?authorId=${authorId}&roomId=${roomId}&uid=${uid}`,
                // Api/LearningCentre/Quiz/Room/ResultAndHistory/Reset?authorId={authorId}&roomId={roomId}&uid={uid}
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        // 'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    if (this.state.isDevMode)
                        console.log('GetRoomResult_ViaApi (response)', JSON.stringify(data));

                    success = CheckObjectBoolean(data, 'success');
                    message = CheckObjectStringEmpty(data, 'message');
                    if (this.state.isDevMode)
                        console.log(`api - reset room result (${success ? 'success' : 'failed'}) (${uid}) (${name}).\n ${JSON.stringify(data)}`);
                })
                .catch(error => {
                    message = CheckObjectStringEmpty(error, 'message');
                    if (this.state.isDevMode)
                        console.log(`api - reset room result (error) (${uid}) (${name}).\n${message}`);
                });
            //alert.
            useAppService.getState().setModal(success ? 'Success' : 'Failed',
                success ?
                    `Result for Student (${name}) has been reset.`
                    :
                    `Failed to reset result for Student (${name})`
            );
        }
        else {
            useAppService.getState().setModal('Failed to reset', `Incomplete data for Student (${name}), request has been halted.`);
        }
    }
    //#endregion === Reset Room Result === end ===//

    render = () => {
        return (<>

            {/* Room - Result - Modal */}
            <Modal size="xl" show={this.state.ShowRoomResultByRoomCodeModal}
                onHide={() => this.ToggleRoomResultModal()}
                centered>
                <Modal.Header closeButton={true}>
                    <Modal.Title style={{ display: 'flex', alignItems: 'center' }}>
                        Room &#60;{CheckObjectStringEmpty(this.state.RoomData, 'RoomCode')}&#62; Result
                        <Button
                            variant="primary"
                            onClick={() => {
                                this.ToggleRoomResultModal();
                                this.LoadResultFromSelectedRoom(
                                    CheckObjectStringEmpty(this.state.RoomData, 'RoomCode'),
                                    CheckObjectStringEmpty(this.state.RoomData, 'RoomId'),
                                    true, null, null, this.state.ReferenceLayoutScreen, this.state.ReferenceFilterByClassroom
                                );
                            }}
                            style={{ marginLeft: 15 }}
                            hidden={this.state.ReferenceLayoutScreen !== '' && this.state.ReferenceLayoutScreen !== LayoutScreen.ManageRoom}
                        >Refresh (Last Sync: {CheckObjectStringEmpty(this.state.RoomData, 'LastSyncDone', '-n/a-')})</Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                this.ToggleRoomResultModal();
                                this.LoadResultFromSelectedRoom(
                                    CheckObjectStringEmpty(this.state.RoomData, 'RoomCode'),
                                    CheckObjectStringEmpty(this.state.RoomData, 'RoomId'),
                                    true, true, null, this.state.ReferenceLayoutScreen, this.state.ReferenceFilterByClassroom
                                );
                            }}
                            style={{ marginLeft: 15 }}
                            hidden={this.state.ReferenceLayoutScreen !== '' && this.state.ReferenceLayoutScreen !== LayoutScreen.ManageRoom}
                        >Recalculate Scores</Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        defaultActiveKey={
                            this.state.RoomResultList_Participant === null || this.state.RoomResultList_Outsider === null ? ResultTabPage.List :
                                this.state.RoomResultList_Participant.length === 0 && this.state.RoomResultList_Outsider.length > 0 ?
                                    ResultTabPage.Outsider : ResultTabPage.List
                        }
                    >
                        <Tab eventKey={ResultTabPage.List} title="Result"
                            className="base-tab tab-result"
                        >
                            <div className="header">
                                <div className="title-div">
                                    <span className="title">Room's Result & Ranking</span>
                                    <span className="misc" hidden={this.state.RoomResultList_Participant === null}>(Student(s): {this.state.RoomResultList_Participant === null ? 0 : this.state.RoomResultList_Participant.length})</span>
                                </div>
                                <div className="buttons">
                                    <Button className="rr-btn" variant="primary" onClick={() => this.Save_RoomResultReport_AsXLSX()}>Save Room Result Report (XLSX)</Button>
                                    <Button className="rr-btn" variant="secondary" onClick={() => this.Print_RoomResultReport()}>Print Room Result Report</Button>
                                </div>
                            </div>
                            {this.RoomResultTableComponent('list', this.state.RoomResultList_Participant)}
                        </Tab>
                        <Tab eventKey={ResultTabPage.Attendance} title="Attendance"
                            className="base-tab tab-attendance"
                        >
                            <div className="header">
                                <div className="title-div">
                                    <span className="title">Attendance</span>
                                    {/* <span className="misc" hidden={this.state.RoomResultList_Participant === null}>(Student(s): {this.state.RoomResultList_Participant === null ? 0 : this.state.RoomResultList_Participant.length})</span> */}
                                </div>
                                <div className="buttons">
                                </div>
                            </div>
                            {this.RoomResultAttendanceComponent()}
                        </Tab>
                        <Tab eventKey={ResultTabPage.PassedFailed} title="Passed/Failed"
                            className="base-tab tab-passed-failed"
                        >
                            <div className="header">
                                <div className="title-div">
                                    <span className="title">Passed/Failed</span>
                                    <span className="misc" hidden={this.state.RoomResultList_Participant === null}>(Student(s): {this.state.RoomResultList_Participant === null ? 0 : this.state.RoomResultList_Participant.length})</span>
                                </div>
                                <div className="buttons">
                                </div>
                            </div>
                            {this.RoomResultPassedFailedComponent()}
                        </Tab>
                        <Tab eventKey={ResultTabPage.Trend} title="Trends (TP)"
                            className="base-tab tab-trends"
                        >
                            <div className="header">
                                <div className="title-div">
                                    <span className="title">Trends</span>
                                    <span className="misc">Tahap Pendidikan (Line Chart)</span>
                                    {/* <span className="misc" hidden={this.state.RoomResultList_Participant === null}>(Student(s): {this.state.RoomResultList_Participant === null ? 0 : this.state.RoomResultList_Participant.length})</span> */}
                                </div>
                                <div className="buttons">
                                </div>
                            </div>
                            {this.RoomResultTrendsComponent()}
                        </Tab>
                        {
                            Array.isArray(this.state.RoomResultList_Outsider) === false || this.state.RoomResultList_Outsider.length === 0 ?
                                null
                                :
                                <Tab eventKey={ResultTabPage.Outsider} title="Outsider"
                                    className="base-tab tab-outsider"
                                >
                                    <div className="header">
                                        <div className="title-div">
                                            <span className="title">Outsiders</span>
                                            <span className="misc">(excluded from statictic & charts)</span>
                                            <span className="misc">(Student(s) : {this.state.RoomResultList_Outsider === null ? 0 : this.state.RoomResultList_Outsider.length})</span>
                                        </div>
                                        <div className="buttons">
                                            <Button className="rr-btn" variant="primary" onClick={() => this.Save_RoomResultReport_Outsider_AsXLSX()}>Save Room Result Report (XLSX)</Button>
                                            <Button className="rr-btn" variant="secondary" onClick={() => this.Print_RoomResultReport_Outsider()}>Print Room Result Report</Button>
                                        </div>
                                    </div>
                                    {/* <div className="tab-info">
                                        <span>Total Student(s) : {this.state.RoomResultList_Outsider === null ? 0 : this.state.RoomResultList_Outsider.length}</span>
                                    </div> */}
                                    {this.RoomResultTableComponent('outsider', this.state.RoomResultList_Outsider)}
                                </Tab>
                        }
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleRoomResultModal()}>Close</Button>
                </Modal.Footer>
                {/* {this.RoomResultButtonsComponent()} */}
            </Modal>
            <div
                id='Div_Print_RoomResult_hidden'
                hidden={true}
            >
                <hr />
                <h4>Room Result</h4>
                <div
                    id='Div_RoomResult_hidden'
                    dangerouslySetInnerHTML={{ __html: this.state.Hidden_RoomResult }}
                />

                <hr />
                <h4>Room Result - Player Question Result Detail</h4>
                <div
                    id='Div_RoomResult_PlayerQuestionResultDetail_hidden'
                    dangerouslySetInnerHTML={{ __html: this.state.Hidden_RoomResult_PlayerQuestionResultDetail }}
                />

                <hr />
                <h4>Room Data</h4>
                <div
                    id='Div_RoomData_hidden'
                    dangerouslySetInnerHTML={{ __html: this.state.Hidden_RoomData }}
                />

                <hr />
                <h4>Room Result (Outsider)</h4>
                <div
                    id='Div_RoomResult_Outsider_hidden'
                    dangerouslySetInnerHTML={{ __html: this.state.Hidden_RoomResult_Outsider }}
                />

                <hr />
                <h4>Room Result (Outsider) - Player Question Result Detail</h4>
                <div
                    id='Div_RoomResult_PlayerQuestionResultDetail_Outsider_hidden'
                    dangerouslySetInnerHTML={{ __html: this.state.Hidden_RoomResult_PlayerQuestionResultDetail_Outsider }}
                />

            </div>
            <div
                id='Div_Print_Room_Attendance_hidden'
                hidden={true}
            >
                <hr />
                <h4>Room - Attendance - Present</h4>
                <div
                    id='Div_Room_Attendance_Present_hidden'
                    dangerouslySetInnerHTML={{ __html: this.state.Hidden_Room_Attendance_Present }}
                />

                <hr />
                <h4>Room - Attendance - Absent</h4>
                <div
                    id='Div_Room_Attendance_Absent_hidden'
                    dangerouslySetInnerHTML={{ __html: this.state.Hidden_Room_Attendance_Absent }}
                />
            </div>

            {/* Room - Result Detail for Student - Modal */}
            <Modal show={this.state.Toggle_RoomResult_Detail}
                onHide={() => this.setState({ Toggle_RoomResult_Detail: false, })}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Result Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: this.state.RoomResult_Detail_Components }}></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.setState({ Toggle_RoomResult_Detail: false, })}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Room - Logs for Student - Modal */}
            <Modal show={this.state.Toggle_RoomResult_Logs}
                id='modal-logs'
                size='lg'
                onHide={() => this.CloseLogs()}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Logs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: this.state.RoomResult_Logs_Components }}></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                        onClick={() => this.ToggleFullLogs(!this.state.RoomResult_Logs_ToggleFull)}
                        style={{ position: "absolute", left: 0, marginLeft: 15 }}
                    >{this.state.RoomResult_Logs_ToggleFull ? 'Show logs of current room only' : 'Show Full Logs'}</Button>
                    <Button variant="secondary"
                        onClick={() => this.CloseLogs()}
                    >Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Student - Room Result - Reset - Modal */}
            <Modal show={this.state.ResetResultModal_Toggle}
                onHide={() => this.ToggleResetResultModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title style={{ fontSize: 20 }}>Room Result - Reset</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'left' }}>
                    <Row><Col>Reset of Room Result is not reversible.</Col></Row>
                    <Row><Col><b>{CheckObjectStringEmpty(this.state.ResetResult_Modal, 'name')}</b></Col></Row>
                    <Row><Col><b>{CheckObjectStringEmpty(this.state.ResetResult_Modal, 'email')}</b></Col></Row>
                    <Row><Col>Are you sure you want to reset current student's room result ?</Col></Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleResetResultModal()} disabled={this.state.ResetResult_Processing}>Cancel</Button>
                    <Button variant="primary" onClick={() => this.ConfirmResetStudentResult()} disabled={this.state.ResetResult_Processing}>Confirm</Button>
                </Modal.Footer>
            </Modal >
        </>);
    }
}