import React, { createRef } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button } from "react-bootstrap";

import { Access, LayoutScreen, ReportMenu } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { CheckBoolean, CheckObjectNullValue, CheckObjectStringEmpty, CheckStringEmpty, ConsoleLog, GetPropIds } from "../utilities/GlobalFunctions";

import ReportRoomsResult from "./Reports/ReportRoomsResult";
import ReportStudentSummary from "./Reports/ReportStudentSummary";
import ReportAbsentStudentScreen from "./Reports/ReportAbsentStudentScreen";
import ReportStudentStatisticScreen from "./Reports/ReportStudentStatisticScreen";


export default class ManageReportScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        this.ReportRoomResult_Ref = createRef();
        this.ReportStudentSummary_Ref = createRef();
        this.ReportAbsentStudent_Ref = createRef();
        this.ReportStudentStatistic_Ref = createRef();
    }

    getInitState = () => ({
        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        isSuperAdmin: false,
        isMasterAdmin: false,
        isAdmin: false,
        isTeacher: false,
        // isAuthor: false,
        screen: LayoutScreen.ManageReport,
        Option: null,

        MenuButtonComponents: null,
    });

    componentWillUnmount = () => { }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        // const isTeacher = CheckBoolean(useGlobal.getState().isTeacher);
        this.setState({
            screen: CheckStringEmpty(useGlobal.getState().screen, LayoutScreen.ManageReport),
            isSuperAdmin: CheckBoolean(useGlobal.getState().isSuperAdmin),
            isMasterAdmin: CheckBoolean(useGlobal.getState().isMasterAdmin),
            isAdmin: CheckBoolean(useGlobal.getState().isAdmin),
            isTeacher: CheckBoolean(useGlobal.getState().isTeacher),
            // isAuthor: CheckBoolean(useGlobal.getState().isAuthor),
        }, () => {

            // //2025.01.07
            // if (isTeacher) {
            //     console.log(' isTeacher = ' + JSON.stringify(isTeacher));
            //     const target = useGlobal.getState().teacherScreenTarget;
            //     console.log(' target = ' + JSON.stringify(target));
            //     // if (typeof target === 'object' && target !== null && target !== undefined)
            //     setTimeout(() => {
            //         this.SetMenuOption(target);
            //     }, 500);
            //     // if (typeof target === 'object' && target !== null && target !== undefined) {
            //     //     const screen = CheckObjectStringEmpty(target, 'screen');
            //     //     if (screen !== '') {
            //     //         useGlobal.getState().setScreen(screen);
            //     //         this.setState({ Option: target, screen: screen });
            //     //     }
            //     // }
            // }

            this.PopulateMenuButtonComponents();
            if (typeof useGlobal.getState().setRefreshListCallbackFn === 'function')
                useGlobal.getState().setRefreshListCallbackFn(this.PopulateMenuButtonComponents);
        });
    }

    // //2025.01.07
    // RemoteAccessByReportOption = () => {
    //     if (this.state.isTeacher === false)
    //         return null;
    //     const target = useGlobal.getState().teacherScreenTarget;
    //     if (this.state.isDevMode)
    //         console.log('ManageReportScreen (target) = ' + JSON.stringify(target));
    //     if (typeof target !== 'object' || target === null || target === undefined)
    //         return null;
    //     this.SetMenuOption(target);
    //     // this.PopulateMenuButtonComponents();
    //     // if (typeof useGlobal.getState().setRefreshListCallbackFn === 'function')
    //     //     useGlobal.getState().setRefreshListCallbackFn(this.PopulateMenuButtonComponents);
    // }

    SetMenuOption = (option = null) => {
        if (option === null)
            return null;
        this.setState({ Option: option, });
        const screen = CheckObjectStringEmpty(option, 'Screen');
        if (screen !== '') {
            useGlobal.getState().setScreen(screen);
            this.setState({
                screen: screen,
            }, () => {
                this.PopulateMenuButtonComponents();
                ConsoleLog('SetSettingOption = ' + screen + ' / ' + useGlobal.getState().screen + '\n' + JSON.stringify(option));
                if (typeof useGlobal.getState().refreshListCallbackFn === 'function')
                    useGlobal.getState().refreshListCallbackFn();
            });
        }
    }

    PopulateMenuButtonComponents = () => {
        let components = [];
        Object.keys(ReportMenu).map((name, key) => {
            const data = ReportMenu[name];
            if (data.ShowInMenu) {
                //2024.11.26
                let proceed = false;
                switch (data.Show) {
                    case Access.All: proceed = true; break;
                    case Access.AdminOnly: proceed = this.state.isAdmin; break;
                    case Access.MasterAdminOnly: proceed = this.state.isMasterAdmin; break;
                    case Access.SuperAdminOnly: proceed = this.state.isSuperAdmin; break;
                    case Access.ByPermission:
                        const user = useGlobal.getState().user;
                        if (user !== null) {
                            const organizerInfoList = CheckObjectNullValue(user, 'OrganizerInfoList');
                            if (Array.isArray(organizerInfoList)) {
                                const { organizerId } = GetPropIds(user);
                                const organizer_findIndex = organizerInfoList.findIndex(x => x.OrganizerId === organizerId);
                                if (organizer_findIndex > -1) {
                                    const customPermissions_string = CheckObjectNullValue(organizerInfoList[organizer_findIndex], 'CustomPermissions');
                                    if (customPermissions_string !== null) {
                                        const customPermissions = JSON.parse(customPermissions_string);
                                        if (Array.isArray(customPermissions)) {
                                            // console.log('MenuButtonComponents (customPermissions) \n' + JSON.stringify(customPermissions));
                                            const section_findIndex = customPermissions.findIndex(x => Object.keys(x)[0] === String(data.Screen));
                                            // console.log(`MenuButtonComponents (ByPermission) (${section_findIndex}) (${data.Screen}) \n ${JSON.stringify(customPermissions[section_findIndex])}`);
                                            if (section_findIndex > -1) {
                                                const key_findIndex = Object.keys(customPermissions[section_findIndex][String(data.Screen)]).findIndex(x => x === 'view');
                                                if (key_findIndex > -1)
                                                    proceed = CheckBoolean(customPermissions[section_findIndex][String(data.Screen)]['view']);
                                                // console.log(`MenuButtonComponents (ByPermission) (${section_findIndex}) (${key_findIndex}) (${customPermissions[section_findIndex][String(data.Screen)]['view']})`);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        // console.log(`MenuButtonComponents (ByPermission) (${name}) (${proceed})`);
                        break;
                    default: break;
                }
                if (proceed) {
                    components.push(<Button
                        key={'ms-mp-btn-' + key}
                        variant={this.state.screen === data.Screen || this.state.screen === LayoutScreen.ManageReport ? 'primary' : 'secondary'}
                        onClick={() => this.SetMenuOption(data)}
                        style={data.Disabled ? { userSelect: 'none', cursor: 'not-allowed' } : {}}
                    >{data.Label}</Button>);
                }
            }
            return null;
        });
        this.setState({
            MenuButtonComponents: components,
        });
    }

    MainPanelComponent = () => {
        switch (CheckObjectStringEmpty(this.state.Option, 'Screen')) {

            //Manage Report Room Result.
            case ReportMenu.RoomResult.Screen:
                return (<ReportRoomsResult ref={ref => ref = this.ReportRoomResult_Ref} key='report-room-result' />);

            //Manage Report Student Summary.
            case ReportMenu.StudentSummary.Screen:
                return (<ReportStudentSummary ref={ref => ref = this.ReportStudentSummary_Ref} key='report-student-summary' />);

            //Manage Report Absent Student.
            case ReportMenu.AbsentStudent.Screen:
                return (<ReportAbsentStudentScreen ref={ref => ref = this.ReportAbsentStudent_Ref} key='report-absent-student' />);

            //Manage Report Student Statistic.
            case ReportMenu.StudentStatistic.Screen:
                return (<ReportStudentStatisticScreen ref={ref => ref = this.ReportStudentStatistic_Ref} key='report-student-statistic' />);

            //Manage Reports - buttons.
            default:
                return (<div className="manage-setting-buttons" key='report-options'>
                    <h4>Manage Reports</h4>
                    {this.state.MenuButtonComponents}
                </div>);
        }
    }

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<>
            <div className="manage-setting">
                {
                    this.state.isSuperAdmin || this.state.isMasterAdmin || this.state.isAdmin || this.state.isTeacher ?
                        this.state.screen === LayoutScreen.ManageReport ?
                            this.MainPanelComponent()
                            :
                            <>
                                <div className="left-panel" style={{ width: 250 }}>{this.state.MenuButtonComponents}</div>
                                <div className="main-panel">{this.MainPanelComponent()}</div>
                            </>
                        :
                        <span>Invalid permission.</span>
                }
            </div>
        </>);
    }
}