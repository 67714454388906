import React from "react";

import { useGlobal } from "../../utilities/GlobalVariables";
import { PermissionAccess, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckValueNA, Delay, GetPropIds, CheckNumber, CheckBoolean, CheckStringEmpty } from "../../utilities/GlobalFunctions";
import { GlobalSetting, LayoutScreen, PermissionAccessType } from "../../utilities/GlobalSetting";
import { AlertMode } from "../AlertComponent";
import { useAppService } from "../../services/AppService";
import { Button, Modal, ProgressBar } from "react-bootstrap";

const SettingInput = {
    None: 'none',
    Name: 'name',
    BC: 'bc',
    BM: 'bm',
    TagId: 'tagId',
    TagName: 'tagName',
    Remark: 'remark',
    DisplayOrder: 'displayOrder',
    Published: 'published',
    Display: 'display',
};

const settingTitle = 'Subject';

export default class SettingCustomSubject extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        isLoading: false,

        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: false,

        List: [],
        IsListLoaded: false,
        TotalRows: 0,
        PageIndex: 0,
        PageSize: 10,
        // OrderBy: 'Name',
        // OrderType: 'ASC',

        Toggle_EditSettingModal: false,
        SettingModal_Create: false,
        SettingModal_Index: -1,
        SettingModal: null,
        SettingModal_Cache: null,
        isUpdating: false,

        Toggle_RemoveSettingModal: false,

    });

    componentWillUnmount = () => { }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.LoadList_ViaApi();
        if (typeof useGlobal.getState().setRefreshListCallbackFn === 'function')
            useGlobal.getState().setRefreshListCallbackFn(this.LoadList_ViaApi);
    }

    //#region === list ===
    //2024.07.24
    CheckPermissions = async () => {
        this.setState({
            PA_View: PermissionAccess(LayoutScreen.ManageCustomSubject, PermissionAccessType.View),
            PA_Search: PermissionAccess(LayoutScreen.ManageCustomSubject, PermissionAccessType.Search),
            PA_Create: PermissionAccess(LayoutScreen.ManageCustomSubject, PermissionAccessType.Create),
            PA_Update: PermissionAccess(LayoutScreen.ManageCustomSubject, PermissionAccessType.Update),
            PA_Delete: PermissionAccess(LayoutScreen.ManageCustomSubject, PermissionAccessType.Delete),
            PA_Upload: PermissionAccess(LayoutScreen.ManageCustomSubject, PermissionAccessType.Upload),
            PA_Download: PermissionAccess(LayoutScreen.ManageCustomSubject, PermissionAccessType.Download),

            // PageSize: CheckNumber(localStorage.getItem(`ManageSetting_CustomGroup_List_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),
            PageSize: 99,
            isSuperAdmin: useGlobal.getState().isSuperAdmin,
            gv: useGlobal.getState(),
        });
        await Delay(0);
    }
    LoadList_ViaApi = async (active = true) => {

        await this.CheckPermissions();    //2024.07.24

        // if (this.state.PA_View === false)
        //     return null;

        this.setState({
            isLoading: true,
            List: [],
            TotalRows: 0,
            IsListLoaded: false,
        });
        window.scrollTo(0, 0);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Quiz/Subject/List/${organizerId}/${authorId}/${0}/${99999}`;
        //  Api/LearningCentre/Quiz/Subject/List/{organizerId}/{authorId}/{pageIndex}/{pageSize}
        let totalRows = 0;
        let _List = [];

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    if (data.data !== undefined)
                        if (Array.isArray(data.data)) {
                            _List = data.data;
                            totalRows = CheckObjectNumber(data.data, 'totalCount', _List.length);
                        }
                        else {
                            if (this.state.isDevMode)
                                console.log(`${settingTitle} list is empty.`);
                        }
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - setting - load list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - setting - load list (error)\n' + error.message);
            });

        this.setState({
            isLoading: false,
            List: JSON.parse(JSON.stringify(_List)),
            TotalRows: totalRows,
            IsListLoaded: true,
        }, () => {
            if (this.state.isDevMode) {
                console.log('TotalRows', totalRows);
                console.log('List', JSON.stringify(_List));
            }
        });
    }
    ListComponents = () => {
        let components = [];

        if (this.state.IsListLoaded === false)
            return null;

        if (this.state.List.length === 0)
            return (<tr><td colSpan={15} align='center'>- list is empty -</td></tr>);

        this.state.List.map((data, key) => {
            components.push(<tr key={'tbi_' + key}>
                <td>{this.state.PageIndex + key + 1}</td>
                <td className='left'>{CheckValueNA(data['name'])}</td>
                {/* <td>{CheckObjectBoolean(data, 'alwaysOnTop') ? '✔' : '-'}</td> */}
                <td>{CheckObjectNumber(data, 'displayOrder')}</td>
                {/* <td>{CheckObjectBoolean(data, 'published') ? '✔' : '-'}</td> */}
                <td>{CheckObjectBoolean(data, 'display') ? '✔' : '❌'}</td>
                {/* <td>{
                    CheckObjectNullValue(data, 'remark') === null ? '-' :
                        <Button
                            variant='info'
                            onClick={() => useAppService.getState().setModal(`Remark :: ${CheckValueNA(data['name'])}`, CheckValueNA(data['remark']))}
                        >View</Button>
                }</td> */}
                <td>
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => this.ToggleEditSettingModal(key)}
                    >{this.state.PA_Update ? 'Edit' : 'View'}</button>
                </td>
            </tr>);
            return null;
        });

        return (components);
    }
    //#endregion

    //#region === edit / new ===
    ToggleEditSettingModal = async (index = -1, create = false) => {

        if (create) {
            if (this.state.PA_Create === false) {
                useAppService.getState().setModal(`New ${settingTitle}`, 'Invalid permission.');
                return null;
            }
            else {
                const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
                const defaultModal = this.PopulateSettingModal(null, organizerId, authorId);
                this.setState({
                    // Toggle_EditSettingModal: true,
                    SettingModal_Create: true,
                    SettingModal_Index: -1,
                    SettingModal: defaultModal,
                    SettingModal_Cache: JSON.parse(JSON.stringify(defaultModal)),
                    isUpdating: false,
                });
                await Delay(200);
                this.setState({
                    Toggle_EditSettingModal: true,
                });
                return null;
            }
        }

        if (this.state.PA_Update === false) {
            useAppService.getState().setModal(`Edit ${settingTitle}`, 'Invalid permission.');
            return null;
        }

        //2024.09.27
        if (index < 0) {
            this.setState({ Toggle_EditSettingModal: false });
            await Delay(200);
        }

        // const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        this.setState({
            // Toggle_EditSettingModal: index < 0 ? false : !this.state.Toggle_EditSettingModal,
            SettingModal_Create: false,
            SettingModal_Index: index,
            SettingModal: index < 0 ? null : this.PopulateSettingModal(this.state.List[index]),
            SettingModal_Cache: index < 0 ? null : this.PopulateSettingModal(this.state.List[index]),
            isUpdating: false,
        });

        // this.setState({
        //     Toggle_EditSettingModal: index < 0 ? false : !this.state.Toggle_EditSettingModal,
        // });

        //2024.09.27
        if (index >= 0) {
            await Delay(200);
            this.setState({ Toggle_EditSettingModal: true });
        }

        if (this.state.isDevMode)
            console.log(`ToggleEditSettingModal (${index}) (${create}) =\n ${JSON.stringify(this.state.SettingModal)}`);
    }
    SettingModalComponent = () => {
        let components = [];
        const settingDetails = this.state.SettingModal;
        if (settingDetails !== null) {

            const { authorId, } = settingDetails;
            const readOnly = this.state.isSuperAdmin ? false : authorId === 1;

            //Name.
            components.push(<div className="form-group" key='s-e-name'>
                <label>Name</label>
                <input type="text" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.Name, e.target.value); }}
                    defaultValue={CheckObjectStringEmpty(settingDetails, SettingInput.Name)}
                    disabled={readOnly}
                ></input>
            </div>);

            //BC.
            components.push(<div className="form-group" key='s-e-bc'>
                <label>BC</label>
                <input type="text" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.BC, e.target.value); }}
                    defaultValue={CheckObjectStringEmpty(settingDetails, SettingInput.BC)}
                    disabled={readOnly}
                ></input>
            </div>);

            //BM.
            components.push(<div className="form-group" key='s-e-bm'>
                <label>BM</label>
                <input type="text" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.BM, e.target.value); }}
                    defaultValue={CheckObjectStringEmpty(settingDetails, SettingInput.BM)}
                    disabled={readOnly}
                ></input>
            </div>);

            //Remark.
            components.push(<div className="form-group" key='s-e-remark'>
                <label>Remark</label>
                <input type="text" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.Remark, e.target.value); }}
                    defaultValue={CheckObjectStringEmpty(settingDetails, SettingInput.Remark)}
                    disabled={readOnly}
                ></input>
            </div>);

            //DisplayOrder.
            components.push(<div className="form-group" key='s-e-display-order'>
                <label>DisplayOrder</label>
                <input type="number" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.DisplayOrder, e.target.value); }}
                    defaultValue={CheckObjectNumber(settingDetails, SettingInput.DisplayOrder)}
                    disabled={readOnly}
                ></input>
            </div>);

            //Published.
            components.push(<div className="form-group" key='s-e-published'>
                <label>Published</label>
                <input type="checkbox" className="form-check form-check-input"
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.Published, e.currentTarget.checked); }}
                    defaultChecked={CheckObjectBoolean(settingDetails, SettingInput.Published)}
                    disabled={readOnly}
                ></input>
            </div>);

            //Display.
            components.push(<div className="form-group" key='s-e-display'>
                <label>Display</label>
                <input type="checkbox" className="form-check form-check-input"
                    onChange={(e) => this.SetSettingValue(SettingInput.Display, e.currentTarget.checked)}
                    defaultChecked={CheckObjectBoolean(settingDetails, SettingInput.Display)}
                ></input>
            </div>);

        }
        return (components);
    }
    SetSettingValue = (property = SettingInput.None, value = null) => {
        let setting = this.state.SettingModal;
        if (setting === null || value === null)
            return null;
        switch (property) {
            case SettingInput.Name: setting[property] = String(value); break;
            case SettingInput.BC: setting[property] = String(value); break;
            case SettingInput.BM: setting[property] = String(value); break;
            case SettingInput.Remark: setting[property] = String(value); break;
            case SettingInput.DisplayOrder: setting[property] = CheckNumber(value); break;
            case SettingInput.Published: setting[property] = CheckBoolean(value); break;
            case SettingInput.Display: setting[property] = CheckBoolean(value); break;
            default: break;
        }
        this.setState({
            SettingModal: setting,
        });
    }
    ResetSetting = () => {
        this.setState({
            SettingModal: JSON.parse(JSON.stringify(this.state.SettingModal_Cache)),
        });
    }
    PopulateSettingModal = (modal = null, organizerId = 0, authorId = 0) => {
        return {
            id: CheckObjectNumber(modal, 'id'),
            organizerId: CheckObjectNumber(modal, 'organizerId', organizerId),
            authorId: CheckObjectNumber(modal, 'authorId', authorId),

            name: CheckObjectStringEmpty(modal, 'name'),
            bc: CheckObjectStringEmpty(modal, 'bc'),
            bm: CheckObjectStringEmpty(modal, 'bm'),
            tagId: CheckObjectNumber(modal, 'tagId'),
            tagName: CheckObjectStringEmpty(modal, 'tagName'),
            remark: CheckObjectStringEmpty(modal, 'remark'),

            alwaysOnTop: CheckObjectBoolean(modal, 'alwaysOnTop'),
            displayOrder: CheckObjectNumber(modal, 'displayOrder'),
            published: CheckObjectBoolean(modal, 'published'),

            display: CheckObjectBoolean(modal, 'display'),  //special.
        };
    }
    SettingModalValidation = (postData = null) => {
        if (postData === null)
            return { success: false, message: 'invalid modal.' };

        let messages = [];

        if (CheckObjectNullValue(postData, 'name') === null)
            messages.push('Name must not be empty.');

        return { success: messages.length === 0, message: messages.join('<br />') };
    }
    CUD_Setting_ViaApi = async (remove = false) => {

        const postData = this.PopulateSettingModal(this.state.SettingModal);
        const { success: validation_success, message: validation_message } = this.SettingModalValidation(postData);
        if (validation_success === false) {
            useAppService.getState().setModal('Validation Failed', validation_message);
            this.setState({
                isUpdating: false,
            });
            return null;
        }

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { textTitle, textBody, text, urlParam } = this.GetPostParams(postData, remove);
        this.setState({
            isUpdating: true,
        });
        useAppService.getState().setModal('', `${textTitle} setting...`, null, AlertMode.Loading);

        const {
            id: _subjectId,
            // organizerId: _organizerId, authorId: _authorId,
            name, bc, bm, remark,
            // alwaysOnTop, 
            displayOrder, published,
            display,  //special.
            // tagId, tagName, //etc
        } = postData;
        const json = JSON.stringify({
            OrganizerId: organizerId,    // CheckNumber(_organizerId),
            AuthorId: authorId,      //  _authorId,
            SubjectId: CheckNumber(_subjectId),
            Name: CheckStringEmpty(name),
            BC: CheckStringEmpty(bc),
            BM: CheckStringEmpty(bm),
            Remark: CheckStringEmpty(remark),
            // AlwaysOnTop: CheckBoolean(alwaysOnTop),
            DisplayOrder: CheckNumber(displayOrder),
            Published: CheckBoolean(published),
            Display: CheckBoolean(display),
            Remove: remove,

            // //etc.
            // TagId: 0,
            // TagName: 0,
            // CenterUserId: 0,
            // FirebaseUserId: '',
            // UniqueId: '',
            // Emails: [],
        });
        if (this.state.isDevMode)
            console.log(`CUD_Setting_ViaApi (${text}) (postData) =\n` + json);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/CustomSubject/${urlParam}`;
        // Api/LearningCentre/Organizer/CustomGroups

        let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('CUD_Setting_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                data = CheckObjectNullValue(data, 'data');

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', `api - ${text} (error)\n` + error.message);
            });

        if (success) {
            await this.LoadList_ViaApi();
            this.ToggleEditSettingModal();
            if (remove)
                this.ToggleRemoveSettingModal();
            await Delay(300);
            useAppService.getState().setModal();
            if (data !== null) {
                const findIndex = this.state.List.findIndex(x => x.id === data.id);
                if (findIndex > -1)
                    this.ToggleEditSettingModal(findIndex);
            }
            await Delay(500);
            useAppService.getState().setModal('', `${settingTitle} has been ${textBody}.`);
        }
        else {
            useAppService.getState().setModal('', `Failed to ${text} setting.<br /><br />` + msg);
        }
        this.setState({
            isUpdating: false,
        });
    }
    GetPostParams = (postData = null, remove = false) => {
        let textTitle = '';
        let textBody = '';
        let text = '';
        let urlParam = '';
        if (postData !== null) {
            if (remove) {
                textTitle = 'Removing';
                textBody = 'removed';
                text = 'remove';
                urlParam = 'Delete';
            }
            else {
                if (CheckObjectNumber(postData, 'id') <= 0) {
                    textTitle = 'Creating';
                    textBody = 'created';
                    text = 'create';
                    urlParam = 'Create';
                }
                else {
                    textTitle = 'Upating';
                    textBody = 'updated';
                    text = 'update';
                    urlParam = 'Update';
                }
            }
        }
        return { textTitle, textBody, text, urlParam };
    }
    //#endregion

    //#region === remove ===
    ToggleRemoveSettingModal = (modal = null) => {
        if (this.state.PA_Delete === false) {
            useAppService.getState().setModal('', 'Invalid permission.');
            return null;
        }
        this.setState({
            Toggle_RemoveSettingModal: modal === null ? false : !this.state.Toggle_RemoveSettingModal,
            isUpdating: false,
        });
    }
    //#endregion

    render = () => {
        return (<>
            <table className="table" style={{ tableLayout: 'fixed', marginBottom: 0 }}>
                <tbody>
                    <tr>
                        <td class="settingTitle">
                            <h5>Manage {settingTitle}</h5>
                        </td>
                        <td align="right">
                            <Button variant="primary" onClick={() => this.ToggleEditSettingModal(-1, true)}>Add New</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50'>#</th>
                        <th className="left">Name</th>
                        {/* <th width='125'>Always On Top</th> */}
                        <th width='115'>Display Order</th>
                        {/* <th width='65'>Published</th> */}
                        <th width='125'>Display</th>
                        <th width='75'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            // <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        // this.state.List.length === 0 ? null :
                        //     PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* Setting - Edit / Update - Modal */}
            <Modal show={this.state.Toggle_EditSettingModal}
                onHide={() => this.ToggleEditSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>{this.state.SettingModal_Create ? `New ${settingTitle}` : 'Edit :: '} {CheckObjectStringEmpty(this.state.SettingModal, 'name')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.SettingModalComponent()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"
                        onClick={() => this.ToggleRemoveSettingModal(this.state.SettingModal)}
                        style={{ position: "absolute", left: 0, marginLeft: 15 }}
                        disabled={this.state.isUpdating || (this.state.isSuperAdmin ? false : this.state.PA_Delete === false || CheckObjectNumber(this.state.SettingModal, 'authorId') === 1)}
                    >Remove</Button>
                    <Button variant="secondary" onClick={() => this.ToggleEditSettingModal()} disabled={this.state.isUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.ResetSetting()} disabled={this.state.isUpdating}>Reset</Button>
                    {
                        this.state.SettingModal_Create ?
                            <Button variant="primary"
                                // onClick={() => this.AddSetting_ViaApi()}
                                onClick={() => this.CUD_Setting_ViaApi()}
                                disabled={this.state.isUpdating || this.state.PA_Create === false}
                            >Create</Button>
                            :
                            <Button variant="primary"
                                onClick={() => this.CUD_Setting_ViaApi()}
                                disabled={this.state.isUpdating || this.state.PA_Update === false}
                            >Update</Button>
                    }
                </Modal.Footer>
            </Modal >

            {/* Setting - Remove (Confirm) - Modal */}
            <Modal show={this.state.Toggle_RemoveSettingModal}
                onHide={() => this.ToggleRemoveSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Remove :: {CheckObjectStringEmpty(this.state.SettingModal, 'name')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you sure you want to remove this {settingTitle.toLowerCase()} ?</p>
                    <p>If so, click confirm to continue.</p>
                    <p><i><b>Caution:</b> Once removed, {settingTitle.toLowerCase()} will not be able to restore.</i></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleRemoveSettingModal()}>Cancel</Button>
                    <Button variant="primary"
                        // onClick={() => this.RemoveSetting_ViaApi()}
                        onClick={() => this.CUD_Setting_ViaApi(true)}
                        disabled={this.state.isUpdating}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal >
        </>);
    }
}