import React from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";

import { GlobalSetting } from "../utilities/GlobalSetting";
import { useAppService } from "../services/AppService";
import { useGlobal } from "../utilities/GlobalVariables";
import { CapitalizeJsonKeys, CheckNullValue, CheckObjectBoolean, CheckObjectNullValue, CheckObjectStringEmpty, CheckStringEmpty, Delay, DelayUntil, DoNothing, GetPropIds } from "../utilities/GlobalFunctions";
import { AlertMode } from "./AlertComponent";

const ItemProperty = {
    Id: 'Id',
    Name: 'Name',
    Email: 'Email',
    TeacherType: 'TeacherType',
    SubjectInCharge: 'SubjectInCharge',
    GroupInCharge: 'GroupInCharge',
    ClassroomInCharge: 'ClassroomInCharge',
    Allowed: 'Allowed',
};

export default class QuestionSetComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        // locale: useGlobal.getState().locale,
        // redirect: false,
        // redirectLink: '/',
        isLoading: false,
        IsListLoaded: false,

        QuestionSetUniqueId: '',
        QuestionSet: null,

        TeacherList: [],
        QuestionSetTeacherListAccessModal_Preview_Toggle: false,

        QuestionSetTeacherListAccessModal_Edit_Toggle: false,
        TeacherList_Edit: [],
        TeacherList_Edit_Cache: [],
        QuestionSetTeacherListComponent_Edit_State: null,

        HasCallback: false,
    });

    componentWillUnmount = () => { }

    componentDidMount = () => {
        this.setState({
            QuestionSetUniqueId: this.props.questionSetUniqueId,
        });
    }

    //#region === QuestionSet Teacher List ===
    RemoteLoad_QuestionSetTeacherList = (uniqueId = '') => {
        this.ResetInitStateValue();
        const t_uniqueId = CheckStringEmpty(uniqueId);
        if (t_uniqueId !== '') {
            this.setState({
                QuestionSetUniqueId: t_uniqueId,
            }, () => {
                this.GetOrganizerTeacherList_ViaApi(true);
            });
        }
    }
    RemoteToggle_QuestionSetTeacherListAccessModal_Preview = (uniqueId = '') => {
        this.ResetInitStateValue();
        setTimeout(() => {
            this.ToggleQuestionSetTeacherListAccessModal_Preview(uniqueId);
        }, 0);
    }
    ResetInitStateValue = () => {
        this.setState({
            isLoading: false,
            IsListLoaded: false,
            TeacherList: [],
            TeacherList_Edit: [],
            TeacherList_Edit_Cache: [],
        });
    }
    GetOrganizerTeacherList_ViaApi = async (callback = false) => {

        const uniqueId = CheckStringEmpty(this.state.QuestionSetUniqueId);
        if (CheckNullValue(uniqueId) === null)
            return null;

        // useAppService.getState().setModal('', 'fetching teacher(s)...', null, AlertMode.Loading);

        this.setState({
            isLoading: true,
            IsListLoaded: false,
            TeacherList: [],
            HasCallback: callback,
        }, () => {
            if (callback === false)
                useAppService.getState().setModal('', 'fetching teacher(s)...', null, AlertMode.Loading);
        });
        await Delay(200);

        //callback - remote start.
        if (callback && typeof this.props.callbackUpdateTeacherList === 'function')
            this.props.callbackUpdateTeacherList([], false);

        const { organizerId } = GetPropIds(useGlobal.getState().user);
        const url = `${GlobalSetting.ApiUrl}Api/LearningCentre/Organizer/QuestionSet/Teacher/Access/List/${organizerId}/${uniqueId}`;
        // Api/LearningCentre/Organizer/QuestionSet/Teacher/Access/List/{organizerId}/{uniqueId}

        let done = false;
        let success = false;
        let errorMessage = '';
        let list = [];
        let questionSet = null;

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('GetOrganizerTeacherList_ViaApi (response) \n' + JSON.stringify(data));
                success = CheckObjectBoolean(data, 'success');
                if (success) {
                    questionSet = CapitalizeJsonKeys(data.data.questionSet);
                    if (Array.isArray(data.data.list))
                        list = CapitalizeJsonKeys(data.data.list);
                }
                else {
                    errorMessage = CheckObjectStringEmpty(data, 'message');
                }
                done = true;
            })
            .catch(error => {
                done = true;
                errorMessage = CheckObjectStringEmpty(error, 'message');
                if (this.state.isDevMode)
                    console.log('Error', 'api - teacher access list (error)\n' + errorMessage);
            });
        await DelayUntil(() => done === true);
        await Delay(1000);

        if (success)
            useAppService.getState().setModal();
        else
            useAppService.getState().setModal('', 'Failed to retrieve teacher list.<br /><br />' + errorMessage);

        this.setState({
            TeacherList: success ? list : [],
            QuestionSet: questionSet,
            isLoading: false,
            IsListLoaded: true,
        }, () => {
            //callback - remote end.
            if (callback && typeof this.props.callbackUpdateTeacherList === 'function')
                this.props.callbackUpdateTeacherList(list, true);
        });
    }
    ToggleQuestionSetTeacherListAccessModal_Preview = async (uniqueId = '') => {
        const toggle = !this.state.QuestionSetTeacherListAccessModal_Preview_Toggle;
        // console.log('ToggleQuestionSetTeacherListAccessModal_Preview (uniqueId) = ' + uniqueId);
        // console.log('ToggleQuestionSetTeacherListAccessModal_Preview (toggle) = ' + toggle);
        if (toggle) {
            const t_uniqueId = CheckStringEmpty(uniqueId);
            if (t_uniqueId !== '') {
                if (this.state.TeacherList.length === 0 || this.state.QuestionSetUniqueId !== t_uniqueId) {
                    this.setState({
                        QuestionSetUniqueId: t_uniqueId,
                    });
                    await this.GetOrganizerTeacherList_ViaApi();
                }
            }
            if (this.state.TeacherList.length === 0)
                return null;
            await Delay(200);
        }
        this.setState({
            QuestionSetTeacherListAccessModal_Preview_Toggle: this.state.TeacherList.length > 0 ? toggle : false,
        });
        if (!toggle) {
            await Delay(200);
            this.setState({
                TeacherList: [],
                TeacherList_Edit: [],
                TeacherList_Edit_Cache: [],
            });
        }
    }
    QuestionSetTeacherListComponent_Preview = () => {
        let components = [];
        let list = this.state.TeacherList;
        if (Array.isArray(list) && list.length > 0) {
            list.sort((a, b) => {
                if (a.Allowed === b.Allowed)
                    return a.Name.localeCompare(b.Name);
                return a.Allowed ? -1 : 1;
            });
            list.map((data, key) => {
                return components.push(<tr>
                    <td>{key + 1}</td>
                    <td className="left">
                        {CheckObjectStringEmpty(data, ItemProperty.Name)}<br />
                        <span style={{ color: 'gray', fontSize: 12 }}>{CheckObjectStringEmpty(data, ItemProperty.Email)}</span>
                    </td>
                    <td>{CheckObjectStringEmpty(data, ItemProperty.TeacherType).toUpperCase()}</td>
                    <td className="left">{this.GetSubjectInChargeComponent(data, ItemProperty.SubjectInCharge)}</td>
                    <td className="left">{this.GetGroupInChargeComponent(data, ItemProperty.GroupInCharge)}</td>
                    <td className="left">{this.GetClassroomInChargeComponent(data, ItemProperty.ClassroomInCharge)}</td>
                    <td className="icon-color">{
                        CheckObjectBoolean(data, ItemProperty.Allowed) ?
                            <i className="fa fa-check-circle orange" title="Allowed" style={{ fontSize: 30 }}></i>
                            : <i className="fa fa-times-circle red" title="Not Allowed" style={{ fontSize: 30 }}></i>
                    }</td>
                </tr>);
            });
        }
        else {
            if (this.state.isLoading)
                components.push(<tr><td colSpan={15}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>);
            else
                components.push(<tr><td colSpan={15}>-list is empty-</td></tr>);
            // else if (this.state.IsListLoaded)
            //     components.push(<tr><td colSpan={15}>-list is empty-</td></tr>);
            // if (CheckBoolean(this.props.teacherListIsLoaded) === false)
            //     components.push(<tr><td colSpan={15}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>);
            // else
            //     components.push(<tr><td colSpan={15}>
            //         <button type="button" className="btn" onClick={() => {
            //             this.props.callbackUpdateTeacherList([], false);
            //             this.GetOrganizerTeacherList_ViaApi();
            //         }}><i className="fa fa-refresh icon-color blue"></i> Refresh</button>
            //     </td></tr>);
        }
        return (<table className="table table-bordered table-hover tbStyle fixed-th" width='100%'>
            <thead>
                <tr>
                    <th width='45'>#</th>
                    <th className="left">Name</th>
                    <th width='75'>Teacher<br />Type</th>
                    <th width='275'>Subject(s)<br />In-Charge</th>
                    <th width='105'>Group(s)<br />In-Charge</th>
                    <th width='285'>Classroom(s)<br />In-Charge</th>
                    <th width='55'>Allow</th>
                </tr>
            </thead>
            <tbody>{components}</tbody>
        </table>);
    }
    //#endregion

    //#region === Shared ===
    GetSubjectInChargeComponent = (obj = null, property = '') => {
        let components = [];
        if (obj === null || property === '')
            return null;
        if (Array.isArray(obj[property]) === false)
            return null;
        obj[property].map((data, key) => {
            const t_name = CheckObjectStringEmpty(data, 'Name');
            if (t_name !== '')
                components.push(<li>{t_name}</li>);
            return null;
        });
        return (<ul style={{ listStyleType: 'square', padding: '0px 0px 0px 15px' }}>{components}</ul>);
    }
    GetGroupInChargeComponent = (obj = null, property = '') => {
        let components = [];
        if (obj === null || property === '')
            return null;
        if (Array.isArray(obj[property]) === false)
            return null;
        obj[property].map((data, key) => {
            const t_name = CheckObjectStringEmpty(data, 'Name');
            if (t_name !== '')
                components.push(<li>{t_name}</li>);
            return null;
        });
        return (<ul style={{ listStyleType: 'square', padding: '0px 0px 0px 15px' }}>{components}</ul>);
    }
    GetClassroomInChargeComponent = (obj = null, property = '') => {
        let components = [];
        if (obj === null || property === '')
            return null;
        if (Array.isArray(obj[property]) === false)
            return null;
        const subjectInCharge = obj[ItemProperty.SubjectInCharge];
        obj[property].map((data, key) => {
            const t_name = CheckObjectStringEmpty(data, 'Name');
            if (t_name !== '') {
                const t_subjects = CheckObjectNullValue(data, 'Subjects', []);
                let subjects = [];
                if (Array.isArray(t_subjects)) {
                    t_subjects.map((subject, skey) => {
                        const t_subject = CheckObjectStringEmpty(subject, 'Name');
                        return subjects.push(<li>{t_subject}</li>);
                    });
                }
                components.push(<li>{t_name}{
                    Array.isArray(subjectInCharge) && subjectInCharge.length > 0 && subjects.length > 0 ?
                        <ul style={{ listStyleType: 'disclosure-closed', padding: '0px 0px 0px 15px' }}>{subjects}</ul> : null
                }</li>);
            }
            return null;
        });
        return (<ul style={{ listStyleType: 'square', padding: '0px 0px 0px 15px' }}>{components}</ul>);
    }
    //#endregion

    //#region === Edit - QuestionSet Teacher List ===
    RemoteToggle_QuestionSetTeacherListAccessModal_Edit = (uniqueId = '') => {
        this.ResetInitStateValue();
        setTimeout(() => {
            this.ToggleQuestionSetTeacherListAccessModal_Edit(uniqueId);
        }, 0);
    }
    ToggleQuestionSetTeacherListAccessModal_Edit = async (uniqueId = '') => {
        const toggle = !this.state.QuestionSetTeacherListAccessModal_Edit_Toggle;
        if (toggle) {
            const t_uniqueId = CheckStringEmpty(uniqueId);
            if (t_uniqueId !== '') {
                if (this.state.TeacherList.length === 0 || this.state.QuestionSetUniqueId !== t_uniqueId) {
                    this.setState({
                        QuestionSetUniqueId: t_uniqueId,
                    });
                    await this.GetOrganizerTeacherList_ViaApi();
                    await Delay(200);
                }
            }
            if (this.state.TeacherList.length === 0)
                return null;
            if (this.state.TeacherList.length > 0) {
                this.setState({
                    TeacherList_Edit: CapitalizeJsonKeys(JSON.parse(JSON.stringify(this.state.TeacherList))),
                    TeacherList_Edit_Cache: CapitalizeJsonKeys(JSON.parse(JSON.stringify(this.state.TeacherList))),
                });
            }
            await Delay(200);
        }
        this.setState({
            QuestionSetTeacherListAccessModal_Edit_Toggle: toggle,
        });
        if (!toggle) {
            await Delay(200);
            this.setState({
                TeacherList_Edit: [],
                TeacherList_Edit_Cache: [],
            });
        }
    }
    ResetQuestionSetTeacherList_Edit = () => {
        this.setState({
            TeacherList_Edit: CapitalizeJsonKeys(JSON.parse(JSON.stringify(this.state.TeacherList_Edit_Cache))),
        });
    }
    QuestionSetTeacherListComponent_Edit = () => {
        let components = [];
        let list = this.state.TeacherList_Edit;
        if (Array.isArray(list) && list.length > 0) {
            list.sort((a, b) => a.Name.localeCompare(b.Name));
            list.map((data, key) => {
                const selected = CheckObjectBoolean(data, ItemProperty.Allowed);
                return components.push(<tr className={selected ? 'td-highlighted' : ''}>
                    <td >{key + 1}</td>
                    <td className="left">
                        {CheckObjectStringEmpty(data, ItemProperty.Name)}<br />
                        <span style={{ color: 'gray', fontSize: 12 }}>{CheckObjectStringEmpty(data, ItemProperty.Email)}</span>
                    </td>
                    <td>{CheckObjectStringEmpty(data, ItemProperty.TeacherType).toUpperCase()}</td>
                    <td className="left">{this.GetSubjectInChargeComponent(data, ItemProperty.SubjectInCharge)}</td>
                    <td className="left">{this.GetGroupInChargeComponent(data, ItemProperty.GroupInCharge)}</td>
                    <td className="left">{this.GetClassroomInChargeComponent(data, ItemProperty.ClassroomInCharge)}</td>
                    <td className="icon-color pointer" onClick={() => this.EditTeacherSetSelected(key)}                    >{
                        selected ?
                            <i className="fa fa-check-circle orange pointer" style={{ fontSize: 30 }}></i>
                            : <i className="fa fa-times-circle red pointer"></i>
                    }</td>
                </tr>);
            });
        }
        else {
            components.push(<tr><td colSpan={15}>-list is empty-</td></tr>);
        }
        return (<table className="table table-bordered table-hover tbStyle" width='100%'>
            <thead>
                <tr>
                    <th width='45'>#</th>
                    <th className="left">Name</th>
                    <th width='75'>Teacher<br />Type</th>
                    <th width='275'>Subject(s)<br />In-Charge</th>
                    <th width='105'>Group(s)<br />In-Charge</th>
                    <th width='285'>Classroom(s)<br />In-Charge</th>
                    <th width='55'>Allow</th>
                </tr>
            </thead>
            <tbody>{components}</tbody>
        </table>);
    }
    EditTeacherSetSelected = (index = -1) => {
        if (index < 0)
            return null;
        let list = this.state.TeacherList_Edit;
        list[index].Allowed = !list[index].Allowed;
        this.setState({
            TeacherList_Edit: list,
        });
    }
    UpdateQuestionSetTeacherListAccess_ViaApi = async () => {

        const uniqueId = CheckStringEmpty(this.state.QuestionSetUniqueId);
        if (CheckNullValue(uniqueId) === null)
            return null;

        useAppService.getState().setModal('', 'updating teacher access...', null, AlertMode.Loading);

        const { organizerId, authorId } = GetPropIds(useGlobal.getState().user);
        const url = `${GlobalSetting.ApiUrl}Api/LearningCentre/Organizer/QuestionSet/Teacher/Access/Update`;
        // Api/LearningCentre/Organizer/QuestionSet/Teacher/Access/Update

        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,
            QuestionSetUniqueId: uniqueId,
            Teachers: this.state.TeacherList_Edit.map((data, key) => { return { TeacherAuthorId: data.Id, TeacherUserId: data.UserId, Allowed: data.Allowed, }; }),
        });
        if (this.state.isDevMode)
            console.log('UpdateQuestionSetTeacherListAccess_ViaApi (json) \n' + json);

        let done = false;
        let success = false;
        let errorMessage = '';

        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('GetOrganizerTeacherList_ViaApi (response) \n' + JSON.stringify(data));
                success = CheckObjectBoolean(data, 'success');
                if (!success)
                    errorMessage = CheckObjectStringEmpty(data, 'message');
                done = true;
            })
            .catch(error => {
                done = true;
                errorMessage = CheckObjectStringEmpty(error, 'message');
                if (this.state.isDevMode)
                    console.log('Error', 'api - update - teacher access control (error)\n' + errorMessage);
            });
        await DelayUntil(() => done === true);

        if (success) {
            await this.GetOrganizerTeacherList_ViaApi(this.state.HasCallback);
            await Delay(200);
            this.ToggleQuestionSetTeacherListAccessModal_Edit();
            await Delay(200);
            useAppService.getState().setModal('', 'Teacher access list has been updated successfully.');
            const uniqueId = CheckStringEmpty(this.state.QuestionSetUniqueId);
            if (CheckNullValue(uniqueId) !== null)
                setTimeout(() => {
                    this.ToggleQuestionSetTeacherListAccessModal_Edit(uniqueId);
                }, 200);
        }
        else {
            useAppService.getState().setModal('', 'Failed to update teacher access control.<br /><br />' + errorMessage);
        }
    }
    //#endregion

    render = () => {
        return (<div className="">

            {/* Question Set - (Preview) Teacher(s) Access - Modal */}
            <Modal
                show={this.state.QuestionSetTeacherListAccessModal_Preview_Toggle}
                onHide={() => this.state.isLoading ? DoNothing() : this.ToggleQuestionSetTeacherListAccessModal_Preview()}
                centered
                size='xl'
            >
                <Modal.Header closeButton={this.state.isLoading === false}>
                    <Modal.Title style={{ display: 'flex', gap: 7 }}>
                        <span>Teacher Access (Preview)</span>
                        <span style={{ color: 'blue' }}>{CheckObjectStringEmpty(this.state.QuestionSet, 'Name')} ({CheckObjectStringEmpty(this.state.QuestionSet, 'SubjectName')}) ({CheckObjectStringEmpty(this.state.QuestionSet, 'GroupName')})</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.QuestionSetTeacherListComponent_Preview()}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleQuestionSetTeacherListAccessModal_Preview()}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Question Set - (Edit) Teacher(s) Access - Modal */}
            <Modal
                show={this.state.QuestionSetTeacherListAccessModal_Edit_Toggle}
                onHide={() => this.state.isLoading ? DoNothing() : this.ToggleQuestionSetTeacherListAccessModal_Edit()}
                centered
                size='xl'
            >
                <Modal.Header closeButton={this.state.isLoading === false}>
                    <Modal.Title>Edit - Teacher Access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: 47, marginTop: -5 }}>
                        <div className="pull-left" style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                            <span >Total Teacher(s): <b>{this.state.TeacherList_Edit.length}</b></span>
                        </div>
                        <div className="pull-right">
                            {/* <button type="button" className="btn btn-primary">Select/Unselect All Teachers</button> */}
                            <button type="button" className="btn btn-primary"
                                onClick={() => {
                                    let list = this.state.TeacherList_Edit;
                                    if (Array.isArray(list)) {
                                        const toggle = list.findIndex(x => x.Allowed === false) > -1 ? true : false;
                                        for (let i = 0; i < list.length; i++) {
                                            list[i].Allowed = toggle;
                                        }
                                        this.setState({
                                            TeacherList_Edit: list,
                                        });
                                    }
                                }}
                            >Set Allowed/Not Allowed To All Teachers</button>
                        </div>
                    </div>
                    {this.QuestionSetTeacherListComponent_Edit()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleQuestionSetTeacherListAccessModal_Edit()} disabled={this.state.isLoading}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.ResetQuestionSetTeacherList_Edit()} disabled={this.state.isLoading}>Reset</Button>
                    <Button variant="primary" onClick={() => this.UpdateQuestionSetTeacherListAccess_ViaApi()} disabled={this.state.isLoading}>Update</Button>
                </Modal.Footer>
            </Modal>

        </div>);
    }
}