import React from 'react';
import { Redirect } from 'react-router-dom';
import { Modal, Button, ProgressBar, Row, Col, ButtonGroup, ToggleButton } from 'react-bootstrap';
import moment from 'moment';
import '../css/PageStyle.scss';
import '../css/LiveQuizAdminPortal.scss';
// import { Locale, Lang } from '../../Localization/CustomLocalization.js';
import { BasicRolePermissions, DefaultCustomPermissionModel_Restricted, getMenuOptions, GlobalSetting, InputType, LayoutScreen, PermissionAccessType, SecretKey } from '../utilities/GlobalSetting';
import { CheckBoolean, CheckNullValue, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, ConsoleLog, Delay, DelayUntil, GetInputComponent, GetPropIds, PermissionAccess, SetTempTarget } from '../utilities/GlobalFunctions';
import { useGlobal } from '../utilities/GlobalVariables';
import { useAppService } from '../services/AppService';
import { AlertMode } from './AlertComponent';

//2024.09.13
const isOrganizerRadios = [
    { name: 'All', value: 'null' },
    { name: 'Organizer Only', value: 'true' },
    { name: 'Others', value: 'false' },
];

//2023.09.07
export default class ManageAuthorScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '',

        SecretKey: SecretKey.Admin,
        TableList: [],
        CachedTableList: [],
        IsTableListLoaded: false,
        IsLoading: false,
        IsOrganizer: 'true',  //null (show all), true (show organizer only), false (show others).
        ShowAll: false,

        NewAuthorModalUiToggle: false,
        IsNewAuthorCreated: false,
        IsAuthorDetailUpdated: false,
        // IsAuthorPermissionUpdated: false,

        SelectedTableItemIndex: -1,
        OrganizerList: [],
        IsOrganizerListLoaded: false,
        OrganizerListModal_Toggle: false,

        //2023.11.30
        AuthorDetailsModal_Toggle: false,
        SelectedAuthorDetails: null,

        AuthorModal: null,
        Create_AuthorModal_Toggle: false,
        Delete_AuthorModal_Toggle: false,

        //2024.07.24
        EditOrganizerPermissionsModal_Toggle: false,
        EditOPModal: null,
        CachedEditOPModal: null,
        EditOrganizerPermissions_Processing: false,
    });

    componentDidMount = async () => {
        //#region init.
        window.scrollTo(0, 0);
        useGlobal.getState().setScreen(LayoutScreen.ManageAuthor);
        const gv = useGlobal.getState();
        // if (CheckObjectBoolean(gv, 'isSuperAdmin') === false) {
        //     this.setState({
        //         redirectLink: '',
        //         redirect: true,
        //     });
        //     return null;
        // }
        if (!PermissionAccess(LayoutScreen.ManageAuthor, PermissionAccessType.View)) {
            this.setState({
                redirectLink: '',
                redirect: true,
            });
            useAppService.getState().setModal('Invalid Access', 'Invalid permission to access current page.');
            return null;
        }
        const { uid, organizerId } = GetPropIds(gv.user);
        this.setState({
            PageSize: CheckNumber(localStorage.getItem(`ManageAuthorScreen_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),
        });
        await useAppService.getState().getGroups();
        await useAppService.getState().getSubjects();
        await Delay(0);
        this.LoadAuthorList_ViaApi();
        useGlobal.getState().setRefreshListCallbackFn(this.LoadAuthorList_ViaApi);
        //#endregion
    }

    //#region === Author List ===
    LoadAuthorList_ViaApi = async () => {
        this.setState({
            IsLoading: true,
            TableList: [],
            IsTableListLoaded: false,
        });

        const { centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(useGlobal.getState().user);
        if (this.state.isDevMode)
            console.log('LoadAuthorList_ViaApi', centerUserId, authorId, authorRoleId, organizerId);

        let done = false;
        let _List = [];
        await fetch(GlobalSetting.ApiUrl
            + `Api/LearningCentre/User/Author/List?secret=${this.state.SecretKey}&authorId=${authorId}&isOrganizer=${this.state.IsOrganizer}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('LoadAuthorList_ViaApi', JSON.stringify(data));
                // success = data.success;
                if (data.success) {
                    if (CheckObjectNullValue(data, 'data') !== null)
                        if (Array.isArray(data.data) && Array(data.data).length > 0)
                            _List = data.data;
                        else
                            if (this.state.isDevMode)
                                console.log('Author list is empty.');
                }
                else {
                    useAppService.getState().setModal('Load Failed', 'Failed to retrive author list.'
                        + (data.message === null || data.message === '' ? null : '<br /><br />Error:<br />' + data.message));
                    if (this.state.isDevMode)
                        console.log('Error', 'api-author-load-list (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                useAppService.getState().setModal('Api Load Failed', 'Failed to load author list.'
                    + (error.message === null || error.message === '' ? null : '<br /><br />Error:<br />' + error.message));
                if (this.state.isDevMode)
                    console.log('Error', 'api-author-load-list (error)\n' + error.message);
                done = true;
            });
        await DelayUntil(() => done === true);

        //populate list.
        if (_List.length > 0) {
            let _newList = JSON.parse(JSON.stringify(_List));
            for (let i = 0; i < _newList.length; i++) {
                _newList[i].showPermissionDetail = false;
                _newList[i].isDirty = false;

                // //customPermissions.
                // const customPermissions_NotFound = CheckObjectNullValue(_newList[i], 'customPermissions') === null;
                // // let customPermissions_NotFound = false
                // // if (_newList[i].hasOwnProperty('customPermissions') === false || _newList[i]['customPermissions'] === null || _newList[i]['customPermissions'] === '')
                // //     customPermissions_NotFound = true;

                // console.log('customPermissions', i, 'start');

                let customPermissions = null;
                if (CheckObjectNullValue(_newList[i], 'customPermissions') === null)
                    customPermissions = JSON.parse(JSON.stringify(BasicRolePermissions));
                else
                    customPermissions = await this.CheckAndUpdateCustomPermissionsModel(JSON.parse(_newList[i]['customPermissions']));
                await DelayUntil(() => customPermissions !== null);
                _newList[i]['customPermissions'] = customPermissions;

                // console.log('customPermissions', i, 'end', JSON.stringify(_newList[i]['customPermissions']));
            }
            // console.log(JSON.stringify(_newList));
            _newList.sort((x, y) => y.isAdmin - x.isAdmin || y.organizers.length - x.organizers.length || x.id - y.id);
            _List = _newList;
            // console.log(JSON.stringify(_List));
        }
        if (this.state.isDevMode)
            console.log('LoadAuthorList_ViaApi (final)', JSON.stringify(_List));

        this.setState({
            IsLoading: false,
            TableList: _List,
            CachedTableList: JSON.parse(JSON.stringify(_List)),
            IsTableListLoaded: true,
        });
    }
    OrganizersComponent = (array) => {
        if (array === null || Array.isArray(array) === false)
            return null;

        let text = '';
        array.map((o, oKey) => {
            text += '(' + (oKey + 1) + ') ' + o.displayName;
            if (oKey < array.length - 1)
                text += '<br />';
            return null;
        });
        return text;
    }
    TableListComponent = () => {
        return (<table className='table table-bordered tb-row-hover' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
            <thead>
                <tr>
                    <th style={{ textAlign: 'center' }}>#</th>
                    <th style={{ width: '1%' }}>Name</th>
                    <th style={{}}>Organizer(s)</th>
                    {/* <th style={{ width: 425, }}>Permissions</th> */}
                    <th style={{ textAlign: 'center', width: 155, }}>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.state.TableList.length === 0 ?
                        <tr><td colSpan={4} align='center'>- list is empty -</td></tr>
                        :
                        this.state.TableList.map((data, itemKey) => {
                            const cachedData = this.state.CachedTableList[itemKey];
                            return (<>
                                <tr key={'row_' + itemKey} id={'row_' + itemKey}>
                                    <td onClick={() => this.ShowPermissionDetail(itemKey)} style={{ cursor: 'pointer' }} align="center" >
                                        {itemKey + 1}
                                    </td>
                                    <td onClick={() => this.ShowPermissionDetail(itemKey)} style={{ cursor: 'pointer' }}>
                                        <h4>{data['name']}</h4>
                                        <h5 style={{ color: 'gray' }}>{data['email']}</h5>
                                        <div style={{ display: 'flex', gap: 5 }}>
                                            {CheckBoolean(data['isSuperAdmin']) ? <span style={{ color: 'red', fontWeight: 'bolder' }}>(SuperAdmin)</span> : null}
                                            {CheckBoolean(data['isAdmin']) ? <span style={{ color: 'blue', fontWeight: 'bold' }}>(Admin)</span> : null}
                                            {CheckBoolean(data['isTeacher']) ? <span style={{ color: 'black', fontWeight: 'bold' }}>(Teacher)</span> : null}
                                            {CheckBoolean(data['isViewer']) ? <span style={{ color: 'green', fontWeight: 'bold' }}>(Viewer)</span> : null}
                                        </div>
                                    </td>
                                    <td onClick={() => this.ShowPermissionDetail(itemKey)} style={{ cursor: 'pointer' }}>
                                        <div dangerouslySetInnerHTML={{ __html: this.OrganizersComponent(cachedData['organizers']) }}></div>
                                    </td>
                                    {/* <td style={{ display: 'flex', justifyContent: 'center' }}>{this.PermissionCheckboxesComponent(data)}</td> */}
                                    <td align="center" style={{ verticalAlign: 'top', padding: 0 }}>
                                        <div className='row' style={{ padding: 5 }}>
                                            <div className='col'>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => this.ToggleEditAuthorDetailsModal(itemKey)}
                                                    style={{ width: '100%' }}
                                                    disabled={this.state.IsLoading}
                                                >Edit Detail</button>
                                            </div>
                                        </div>
                                        <div className='row' style={{ padding: 5, paddingTop: 0 }}>
                                            <div className='col'>
                                                <button
                                                    type="button"
                                                    className="btn btn-info"
                                                    onClick={() => this.LoadOrganizers_ViaApi(itemKey)}
                                                    style={{ width: '100%' }}
                                                    disabled={this.state.IsLoading}
                                                >Edit Organizers</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {
                                    CheckObjectBoolean(data, 'showPermissionDetail') === false ? null :
                                        <>
                                            <tr>
                                                <td rowSpan={2} style={{ backgroundColor: 'white' }}></td>
                                                <td colSpan={3} style={{ backgroundColor: 'white' }}>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <button
                                                                type="button"
                                                                className="btn btn-info"
                                                                onClick={() => this.ResetPermissions(itemKey)}
                                                                style={{ width: '100%' }}
                                                                disabled={this.state.IsLoading}
                                                            >Reset to Default Permissions</button>
                                                        </div>
                                                        <div className='col'>
                                                            <button
                                                                type="button"
                                                                className="btn btn-info"
                                                                onClick={() => this.ResetTableList()}
                                                                style={{ width: '100%' }}
                                                                disabled={this.state.IsLoading}
                                                            >Reset Permissions</button>
                                                        </div>
                                                        <div className='col'>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => this.UpdateAuthor_ViaAPI(itemKey, true)}
                                                                style={{ width: '100%' }}
                                                                disabled={this.state.IsLoading}
                                                            >Save Permission</button>
                                                        </div>
                                                    </div>
                                                </td></tr>

                                            <tr><td colSpan={3} style={{ backgroundColor: 'white' }}>{this.PermissionCheckboxesComponent(data)}</td></tr>
                                        </>
                                }
                            </>);
                        })
                }
            </tbody>
        </table>);
    }
    //2023.11.17
    ResetTableList = () => {
        let _List = this.state.CachedTableList;
        for (let i = 0; i < _List.length; i++) {
            _List[i]['showPermissionDetail'] = CheckObjectBoolean(this.state.TableList[i], 'showPermissionDetail');
        }
        this.setState({
            TableList: _List,
            CachedTableList: JSON.parse(JSON.stringify(_List)),
        });
    }
    UpdateAuthor_ViaAPI = async (index = -1, direct = false) => {

        if (index < 0)
            return null;

        if (direct) {
            let confirm = window.confirm('Continue to save ?');
            if (confirm === false)
                return null;
        }

        useAppService.getState().setModal('', 'Updating author...', null, AlertMode.Loading);
        this.setState({
            IsLoading: true,
            // IsAuthorPermissionUpdated: false,
        });

        let _List = [...this.state.TableList];
        const author = _List[index];

        const { centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(useGlobal.getState().user);
        if (this.state.isDevMode)
            console.log('UpdateAuthor_ViaAPI (Id)', centerUserId, authorId, authorRoleId, organizerId);

        const jsonData = JSON.stringify({
            secret: this.state.SecretKey,
            authorId: authorId,
            model: {
                id: CheckObjectNumber(author, 'id'),
                userId: CheckObjectNumber(author, 'userId'),
                firebaseUserId: CheckObjectStringEmpty(author, 'firebaseUserId'),

                //author.
                isViewer: CheckObjectBoolean(author, 'isViewer'),
                isAdmin: CheckObjectBoolean(author, 'isAdmin'),
                isTeacher: CheckObjectBoolean(author, 'isTeacher'),
                customPermissions: author.customPermissions,    //array.
                forceResetPassword: CheckObjectBoolean(author, 'forceResetPassword'),
                isActive: CheckObjectBoolean(author, 'isActive'),
                isAllowResetOnRoomResult: CheckObjectBoolean(author, 'isAllowResetOnRoomResult'),   //2024.07.19
                firstPageToLoad: CheckObjectStringEmpty(author, 'firstPageToLoad'),   //2024.09.13
                hideDashboard: CheckObjectBoolean(author, 'hideDashboard'),           //2024.09.13

                //user.
                name: CheckObjectStringEmpty(author, 'name'),
                email: CheckObjectStringEmpty(author, 'email'),
                dialingCode: CheckObjectStringEmpty(author, 'dialingCode'),
                phoneNumber: CheckObjectStringEmpty(author, 'phoneNumber'),

                //organizer mappings.
                organizers: author.organizers,      //array.
            },
        });
        if (this.state.isDevMode)
            console.log('UpdateAuthor_ViaAPI (json)', jsonData);

        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/User/Author/Update',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: jsonData,
            })
            .then(res => res.json())
            .then(data => {
                // if (this.state.isDevMode)
                //     console.log(JSON.stringify(data));
                if (data.success) {
                    _List[index].isDirty = false;
                    if (this.state.isDevMode)
                        console.log('Author has been updated.');
                }
                else {
                    useAppService.getState().setModal('Update Failed', 'Author update is unsuccessful.'
                        + (CheckObjectNullValue(data, 'message') === null ? '' : '<br /><br />Error:<br />' + data.message));
                    if (this.state.isDevMode)
                        console.log('Error', 'api-author-update (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                useAppService.getState().setModal('Api Update Failed', 'Failed to update author detail.'
                    + (CheckObjectNullValue(error, 'message') === null ? '' : '<br /><br />Error:<br />' + error.message));
                if (this.state.isDevMode)
                    console.log('Error', 'api-author-update (error)\n' + error.message);
            });

        const updateSuccess = _List[index].isDirty === false;
        this.setState({
            TableList: updateSuccess ? _List : this.state.TableList,
            CachedTableList: updateSuccess ? JSON.parse(JSON.stringify(_List)) : this.state.CachedTableList,
            IsLoading: false,
            // IsAuthorPermissionUpdated: true,
        }, () => {
            if (updateSuccess)
                useAppService.getState().setModal('', 'Author has been updated.');
        });
    }
    //#endregion === Author List ===

    //#region === Author (Primary) (Custom Permissions) related. ===
    //2023.09.11
    CheckAndUpdateCustomPermissionsModel = async (model = null) => {
        // console.log('CheckAndUpdateCustomPermissionsModel', JSON.stringify(model));
        let customPermissions = JSON.parse(JSON.stringify(BasicRolePermissions));
        if (model === null)
            return customPermissions;

        const entries = Object.values(customPermissions);
        const entries_model = Object.values(model);
        // console.log('customPermissions\n', JSON.stringify(customPermissions));
        // console.log('model\n', JSON.stringify(model));

        for (let i = 0; i < entries.length; i++) {
            const section = Object.keys(entries[i])[0];
            const section_action = Object.values(entries[i])[0];
            // console.log('section = ', JSON.stringify(section));
            // console.log('section_action = ', JSON.stringify(section_action));
            // console.log('section\n', JSON.stringify(customPermissions[i][section]));

            let modelSectionIndex = -1;
            try {
                for (let m = 0; i < entries_model.length; m++) {
                    const section_model = Object.keys(entries_model[m])[0];
                    if (CheckNullValue(section_model) !== null) {
                        if (section === section_model) {
                            modelSectionIndex = m;
                            break;
                        }
                    }
                }
            }
            catch (e) {
                // console.log('modelSectionIndex (error)', e);
            }
            // console.log('modelSectionIndex = ', modelSectionIndex);

            const section_action_keys = Object.keys(section_action);
            if (modelSectionIndex > -1) {
                // console.log('section_action_keys = ', JSON.stringify(section_action_keys));
                for (let action = 0; action < section_action_keys.length; action++) {
                    if (CheckNullValue(model[modelSectionIndex][section][section_action_keys[action]]) !== null)
                        customPermissions[i][section][section_action_keys[action]] = CheckBoolean(model[modelSectionIndex][section][section_action_keys[action]]);
                    else
                        customPermissions[i][section][section_action_keys[action]] = false;
                    // console.log(section_action_keys[action], '(model) = ', model[modelSectionIndex][section][section_action_keys[action]]);
                    // console.log(section_action_keys[action], ' = ', customPermissions[i][section][section_action_keys[action]]);
                }
            }
            else {
                for (let action = 0; action < section_action_keys.length; action++) {
                    customPermissions[i][section][section_action_keys[action]] = false;
                }
            }
            // console.log(section, i, JSON.stringify(customPermissions[i][section]));
        }
        return customPermissions;
    }
    HandleEditPermission = async (ele = null, selectAll = false) => {

        if (ele === null)
            return null;
        ConsoleLog(`HandleEditPermission ${ele.id}, ${ele.name}, ${ele.value}, ${ele.checked}`);

        const splits = String(ele.id).split('_');
        const authorId = Number(splits[0]);
        const section = splits[1];     //aka. title
        const action = selectAll ? 'select-all' : splits[2];    //aka. Read/Download/Upload/etc
        let _List = JSON.parse(JSON.stringify(this.state.TableList));    //[...this.state.TableList];
        const authorIndex = _List.findIndex(x => Number(x.id) === authorId);
        if (authorIndex > -1) {

            let section_index = -1;
            let action_index = -1;

            const t_permissions = await this.GetCustomPermissions(_List[authorIndex].customPermissions);
            let permissions = JSON.parse(JSON.stringify(t_permissions));

            for (let p = 0; p < permissions.length; p++) {
                const permission = Object.entries(permissions[p]);
                const title = String(permission[0][0]);
                if (title === section) {
                    // console.log('permission', JSON.stringify(permission));
                    // console.log('title', title);
                    section_index = p;
                    const actions = Object.entries(permission[0][1]);
                    for (let s = 0; s < actions.length; s++) {
                        const skey = String(actions[s][0]);
                        if (skey === action) {
                            action_index = s;
                            // console.log('skey', skey);
                            break;
                        }
                    }
                    break;
                }
            }
            ConsoleLog('HandleEditPermission ' + section + ' ' + action + ' ' + (selectAll ? 'selectAll' : 'single') + ' ' + section_index + ' ' + action_index);
            // ConsoleLog('(before) ' + JSON.stringify(permissions[section_index]));
            // ConsoleLog('(before) ' + JSON.stringify(permissions[section_index][section]));
            // ConsoleLog('(before) ' + JSON.stringify(permissions[section_index][section][action]));

            if (section_index > -1) {
                if (selectAll === false) {
                    if (action_index > -1)
                        permissions[section_index][section][action] = !ele.checked;
                }
                else {
                    const subCategories = Object.entries(permissions[section_index][section]);
                    for (let s = 0; s < subCategories.length; s++) {
                        const skey = String(subCategories[s][0]);
                        permissions[section_index][section][skey] = !ele.checked;
                    }
                }
            }
            // ConsoleLog('(after) ' + JSON.stringify(permissions[sectionIndex]));
            // ConsoleLog('(after) ' + JSON.stringify(permissions[sectionIndex][section]));
            // ConsoleLog('(after) ' + JSON.stringify(permissions[sectionIndex][section][action]));

            _List[authorIndex].customPermissions = permissions;
            _List[authorIndex].isDirty = JSON.stringify(this.state.CachedTableList[authorIndex].customPermissions)
                !== JSON.stringify(_List[authorIndex].customPermissions);

            this.setState({
                TableList: _List,
            }, () => {
                // ConsoleLog(`HandleEditPermission (done) \n ${JSON.stringify(permissions[sectionIndex])}`);
            });
        }
    }
    GetCustomPermissions = async (targetProfile = null) => {
        if (targetProfile === null)
            return this.state.TargetProfile;



        let profileCustomPermissions =
            Array.isArray(targetProfile['customPermissions']) ?
                targetProfile['customPermissions']
                :
                CheckNullValue(targetProfile['customPermissions']) === null ?
                    null
                    :
                    JSON.parse(targetProfile['customPermissions']);
        if (Array.isArray(profileCustomPermissions) === false)
            profileCustomPermissions = [];
        // console.log('GetCustomPermissions (profileCustomPermissions) (before) \n' + JSON.stringify(profileCustomPermissions));

        let updatedCustomPermissions = [];
        BasicRolePermissions.map((section, skey) => {
            const sectionName = Object.keys(section)[0];
            let t_section = {};
            const findIndex_section = profileCustomPermissions.findIndex(x => Object.keys(x).findIndex(y => String(y) === String(sectionName)) > -1);
            if (findIndex_section > -1) {
                // console.log('GetTeacherWithCustomPermissions (profileCustomPermissions) (middle) (section) \n' + JSON.stringify(profileCustomPermissions[findIndex_section][sectionName]));
                const source_ActionKeys = Object.keys(profileCustomPermissions[findIndex_section][sectionName]);
                // console.log('GetTeacherWithCustomPermissions (profileCustomPermissions) (middle) (source_ActionKeys) \n' + JSON.stringify(source_ActionKeys));
                let actions = {};
                Object.keys(section[sectionName]).map((action, akey) => {
                    const findIndex_action = source_ActionKeys.findIndex(x => String(x) === String(action));
                    if (findIndex_action > -1)
                        actions[action] = CheckBoolean(profileCustomPermissions[findIndex_section][sectionName][action]);
                    else
                        actions[action] = false;
                    // console.log('GetTeacherWithCustomPermissions (profileCustomPermissions) (middle) (action) ' + (findIndex_action < 0 ? 'not found' : 'found') + ' ' + action + ' = ' + String(actions[action]));
                    return null;
                });
                t_section[sectionName] = actions;
                updatedCustomPermissions.push(t_section);
            }
            else {
                t_section[sectionName] = DefaultCustomPermissionModel_Restricted;
                updatedCustomPermissions.push(t_section);
            }
            return null;
        });
        targetProfile['customPermissions'] = updatedCustomPermissions;
        // this.setState({
        //     TargetProfile: targetProfile,
        // });
        // console.log('GetTeacherWithCustomPermissions (updatedCustomPermissions) (after) \n' + JSON.stringify(updatedCustomPermissions));
        return targetProfile;
    }
    PermissionCheckboxesComponent = (targetProfile = null) => {

        let components = [];

        const profileWithCustomPermissions = targetProfile;
        // console.log('author (PermissionCheckboxesComponent) \n' + JSON.stringify(profileWithCustomPermissions));
        // console.log('author (PermissionCheckboxesComponent) \n' + JSON.stringify(profileWithCustomPermissions['customPermissions']));

        profileWithCustomPermissions['customPermissions'].map((data, key) => {
            const permissions = Object.entries(data).filter(x => x !== null);
            permissions.map((permission, pkey) => {

                // console.log(JSON.stringify(permission));

                const title = permission[0];
                const actions = Object.entries(permission[1]);
                // const subCategories = permission[1];

                // console.log(JSON.stringify(permission[0]));
                // console.log(JSON.stringify(permission[1]));
                // console.log(JSON.stringify(subCategories));

                let totalChecked = 0;
                let subComponents = [];
                for (let s = 0; s < actions.length; s++) {
                    const actionKey = String(actions[s][0]);
                    const checked = CheckBoolean(actions[s][1]);
                    const id = profileWithCustomPermissions.id + '_' + title + '_' + actionKey;
                    // console.log(`PermissionCheckboxesComponent (${title}) | ${skey} ${String(checked)} | ${JSON.stringify(subCategories[s])}`);
                    subComponents.push(<dd style={{ display: 'inline-grid', padding: 5 }}>
                        <input type='checkbox' id={id} name={id}
                            readOnly={true}
                            checked={checked}
                            style={{ cursor: 'pointer' }}
                            onChange={() => this.HandleEditPermission({ id: id, name: id, value: '', checked: checked }, false)}
                        />
                        <label htmlFor={id} style={{ cursor: 'pointer' }}>{actionKey.charAt(0).toUpperCase() + actionKey.slice(1)}</label>
                    </dd>);
                    if (checked)
                        totalChecked += 1;
                }
                const selectAllId = profileWithCustomPermissions.id + '_' + title + '_select-all';
                const checkedAll = totalChecked === actions.length;
                subComponents.push(<dd style={{ display: 'inline-grid', }}>
                    <input type='checkbox' id={selectAllId} name={selectAllId}
                        checked={checkedAll}
                        style={{ cursor: 'pointer' }}
                        onChange={() => this.HandleEditPermission({ id: selectAllId, name: selectAllId, value: '', checked: checkedAll }, true)}
                        readOnly={true}
                    />
                    <label htmlFor={selectAllId} style={{ cursor: 'pointer' }}>Select All</label>
                </dd>);
                //assign.
                components.push(<>
                    <dt>{title.charAt(0).toUpperCase() + title.slice(1)}</dt>
                    {subComponents}
                </>);

                return null;
            });
            return null;
        });
        return (<dl style={{ margin: 0, marginBottom: -18 }}>{components}</dl>);

        //#region === older codes before 2024.07.24

        // // if (useGlobal.getState().user === null || targetProfile === null)
        // //     return null;

        // // const array = author['customPermissions'];
        // // if (array === null || Array.isArray(array) === false)
        // //     return null;

        // let components = [];



        // const permissions = Object.entries(targetProfile['customPermissions']);
        // console.log(JSON.stringify(permissions));

        // for (let p = 0; p < permissions.length; p++) {
        //     const permission = Object.entries(permissions[p]);
        //     // console.log(JSON.stringify(permission));

        //     const title = String(permission[0][0]);
        //     const actions = Object.entries(permission[0][1]);
        //     // console.log(JSON.stringify(subCategories));

        //     let totalChecked = 0;
        //     let subComponents = [];
        //     for (let s = 0; s < actions.length; s++) {
        //         // const entry = Object.entries(subCategories[s]);
        //         const skey = String(actions[s][0]);
        //         const checked = CheckBoolean(actions[s][1]) ? true : false;
        //         const id = targetProfile.id + '_' + title + '_' + skey;
        //         subComponents.push(<dd style={{ display: 'inline-grid', padding: 5 }}>
        //             <input type='checkbox' id={id} name={id}
        //                 checked={checked}
        //                 style={{ cursor: 'pointer' }}
        //                 onClick={() => this.HandleEditPermission()} readOnly={true} />
        //             <label htmlFor={id} style={{ cursor: 'pointer' }}>{skey.charAt(0).toUpperCase() + skey.slice(1)}</label>
        //         </dd>);
        //         if (checked)
        //             totalChecked += 1;
        //     }
        //     const selectAllId = targetProfile.id + '_' + title + '_select-all';
        //     const checkedAll = totalChecked === actions.length;
        //     subComponents.push(<dd style={{ display: 'inline-grid', }}>
        //         <input type='checkbox' id={selectAllId} name={selectAllId}
        //             checked={totalChecked === actions.length}
        //             style={{ cursor: 'pointer' }}
        //             onClick={e => this.HandleEditPermission({ id: selectAllId, name: selectAllId, value: '', checked: checkedAll }, true)} readOnly={true} />
        //         <label htmlFor={selectAllId} style={{ cursor: 'pointer' }}>Select All</label>
        //     </dd>);

        //     components.push(<>
        //         <dt>{title.charAt(0).toUpperCase() + title.slice(1)}</dt>
        //         {subComponents}
        //     </>);
        // }
        // return (<dl style={{ margin: 0, marginBottom: -18 }}>{components}</dl>);

        //#endregion === older codes before 2024.07.24
    }
    ShowPermissionDetail = (index) => {
        let _List = this.state.TableList;
        _List.map((data, key) => {
            if (key === index)
                data['showPermissionDetail'] = !data['showPermissionDetail'];
            return null;
        });
        this.setState({
            TableList: _List,
        });
    }
    //2023.10.30
    ResetPermissions = (index) => {

        let confirm = window.confirm('Continue to reset all permissions ?');
        if (confirm === false)
            return null;

        let _List = this.state.TableList;
        _List[index]['customPermissions'] = JSON.parse(JSON.stringify(BasicRolePermissions));
        this.setState({
            TableList: _List,
        }, async () => {
            await this.UpdateAuthor_ViaAPI(index);
        });
    }
    //#endregion === Author (Primary) (Custom Permissions) related. ===

    //#region === Author - Edit ===
    //2023.11.30
    ToggleEditAuthorDetailsModal = async (index = -1) => {
        // this.setState({
        //     AuthorDetailsModal_Toggle: !this.state.AuthorDetailsModal_Toggle,
        //     SelectedTableItemIndex: index,
        //     // SelectedAuthorDetails: null,
        //     // IsLoading: false,
        // }, async () => {
        //     if (this.state.SelectedTableItemIndex > -1) {
        //         this.ResetAuthorDetails();
        //         await Delay(500);
        //         SetTempTarget(this.state.SelectedAuthorDetails);
        //         // this.SaveSelectedAuthorDetails(this.state.SelectedAuthorDetails);
        //     }
        //     else {
        //         this.setState({
        //             SelectedAuthorDetails: null,
        //             IsLoading: false,
        //         });
        //     }
        // });
        this.setState({
            SelectedTableItemIndex: index,
        });
        await Delay(0);
        if (this.state.SelectedTableItemIndex > -1) {
            await this.ResetAuthorDetails();
        }
        this.setState({
            IsLoading: false,
            AuthorDetailsModal_Toggle: !this.state.AuthorDetailsModal_Toggle,
        });
        await Delay(0);
        if (this.state.SelectedTableItemIndex < 0) {
            this.setState({
                SelectedAuthorDetails: null,
            });
            await Delay(0);
        }
    }
    //2023.11.30
    ResetAuthorDetails = async () => {
        this.setState({
            SelectedAuthorDetails: JSON.parse(JSON.stringify(this.state.TableList[this.state.SelectedTableItemIndex])),
        });
        await Delay(0);
        SetTempTarget(this.state.SelectedAuthorDetails);
        this.SaveSelectedAuthorDetails(this.state.SelectedAuthorDetails);
        await Delay(0);
    }
    //2023.11.30
    AuthorDetailsComponent = () => {
        let components = [];

        const authorDetails = this.state.SelectedAuthorDetails;
        if (authorDetails !== null) {

            components.push(<div className="form-group">
                <label>Name</label>
                {
                    GetInputComponent(InputType.Text, null,
                        authorDetails, 'name', null, '', this.state.locale,
                        this.SaveSelectedAuthorDetails, null, this.state.IsLoading)
                }
            </div>);

            // components.push(<div className="form-group">
            //     <label>Email</label>
            //     {
            //         GetInputComponent(InputType.Text, null,
            //             authorDetails, 'email', null, '', this.state.locale,
            //             this.SaveSelectedAuthorDetails, null, !this.state.IsLoading)
            //     }
            // </div>);

            components.push(<div className="form-group">
                <label>Dialing Code</label>
                {
                    GetInputComponent(InputType.Number, null,
                        authorDetails, 'dialingCode', null, '', this.state.locale,
                        this.SaveSelectedAuthorDetails, null, this.state.IsLoading)
                }
            </div>);

            components.push(<div className="form-group">
                <label>Phone Number</label>
                {
                    GetInputComponent(InputType.Number, null,
                        authorDetails, 'phoneNumber', null, '', this.state.locale,
                        this.SaveSelectedAuthorDetails, null, this.state.IsLoading)
                }
            </div>);

            // components.push(<div className="form-group">
            //     <label>Contact Number</label>
            //     {
            //         GetInputComponent(InputType.Text, null,
            //             authorDetails, 'contactNumber', null, '', this.state.locale,
            //             this.SaveSelectedAuthorDetails, null, this.state.IsLoading)
            //     }
            // </div>);

            components.push(<div className="form-group">
                <label>Force Reset Password on Next Login</label>
                <div className='row'>
                    <div className='col'>
                        {
                            GetInputComponent(InputType.Checkbox, null,
                                authorDetails, 'forceResetPassword', null, '', this.state.locale,
                                this.SaveSelectedAuthorDetails, null, this.state.IsLoading,
                                { width: 20, height: 20, })
                        }
                    </div>
                    {
                        CheckObjectNullValue(this.state.SelectedAuthorDetails, 'lastPasswordUpdateOnUtc') === null ? null :
                            <div className='col' style={{ flex: 20, padding: 0 }}>
                                <span style={{ fontSize: 14, color: 'gray' }}>(Last Password Update: {moment.utc(CheckObjectStringEmpty(this.state.SelectedAuthorDetails, 'lastPasswordUpdateOnUtc'), 'YYYY-MM-DD HH:mm:ss.sss').local().format('lll')})</span>
                            </div>
                    }
                </div>
            </div>);

            //2024.01.31
            components.push(<div className="form-group">
                <label>Admin</label>
                <div className='row'>
                    <div className='col'>
                        {
                            GetInputComponent(InputType.Checkbox, null,
                                authorDetails, 'isAdmin', null, '', this.state.locale,
                                this.SaveSelectedAuthorDetails, null, this.state.IsLoading,
                                { width: 20, height: 20, })
                        }
                    </div>
                </div>
            </div>);
            //2024.07.18
            components.push(<div className="form-group">
                <label>Teacher</label>
                <div className='row'>
                    <div className='col'>
                        {
                            GetInputComponent(InputType.Checkbox, null,
                                authorDetails, 'isTeacher', null, '', this.state.locale,
                                this.SaveSelectedAuthorDetails, null, this.state.IsLoading,
                                { width: 20, height: 20, })
                        }
                    </div>
                </div>
            </div>);
            components.push(<div className="form-group">
                <label>Viewer</label>
                <div className='row'>
                    <div className='col'>
                        {
                            GetInputComponent(InputType.Checkbox, null,
                                authorDetails, 'isViewer', null, '', this.state.locale,
                                this.SaveSelectedAuthorDetails, null, this.state.IsLoading,
                                { width: 20, height: 20, })
                        }
                    </div>
                </div>
            </div>);
            components.push(<div className="form-group">
                <label>Active</label>
                <div className='row'>
                    <div className='col'>
                        {
                            GetInputComponent(InputType.Checkbox, null,
                                authorDetails, 'isActive', null, '', this.state.locale,
                                this.SaveSelectedAuthorDetails, null, this.state.IsLoading,
                                { width: 20, height: 20, })
                        }
                    </div>
                </div>
            </div>);

            //2024.07.19
            components.push(<div className="form-group">
                <label>Enable Room Result Reset feature on Student</label>
                <div className='row'>
                    <div className='col'>
                        {
                            GetInputComponent(InputType.Checkbox, null,
                                authorDetails, 'isAllowResetOnRoomResult', null, '', this.state.locale,
                                this.SaveSelectedAuthorDetails, null, this.state.IsLoading,
                                { width: 20, height: 20, })
                        }
                    </div>
                </div>
            </div>);

            //2024.09.13
            components.push(<div className="form-group">
                <label>First Page To Load</label>
                <div className='row'>
                    <div className='col'>
                        {
                            GetInputComponent(InputType.Select,
                                getMenuOptions(),
                                authorDetails, 'firstPageToLoad', null, '', this.state.locale,
                                this.SaveSelectedAuthorDetails, null, this.state.IsLoading)
                        }
                    </div>
                </div>
            </div>);

            //2024.09.13
            components.push(<div className="form-group">
                <label>Hide Dashboard</label>
                <div className='row'>
                    <div className='col'>
                        {
                            GetInputComponent(InputType.Checkbox, null,
                                authorDetails, 'hideDashboard', null, '', this.state.locale,
                                this.SaveSelectedAuthorDetails, null, this.state.IsLoading,
                                { width: 20, height: 20, })
                        }
                    </div>
                </div>
            </div>);
        }

        return components;
    }
    //2023.11.30
    SaveSelectedAuthorDetails = (obj = null) => {
        if (obj !== undefined && obj !== null) {
            obj['contactNumber'] = CheckObjectStringEmpty(obj, 'dialingCode') + CheckObjectStringEmpty(obj, 'phoneNumber');
            this.setState({
                SelectedAuthorDetails: obj,
            });
        }
    }
    //2023.11.30
    ConfirmUpdateAuthorDetails = () => {
        let tableList = this.state.TableList;
        tableList[this.state.SelectedTableItemIndex] = this.state.SelectedAuthorDetails;
        this.setState({
            TableList: tableList,
        }, () => {
            this.UpdateAuthor_ViaAPI(this.state.SelectedTableItemIndex, true);
        });
    }
    //#endregion === Author - Edit ===

    //#region === Author - Create ===
    ToggleCreateNewAuthorModal = async () => {
        const toggle = !this.state.Create_AuthorModal_Toggle;
        if (toggle) {
            this.ResetAuthorModal();
            await Delay(200);
        }
        this.setState({
            Create_AuthorModal_Toggle: toggle,
        });
        if (!toggle) {
            await Delay(200);
            this.setState({ AuthorModal: null, });
        }
    }
    ResetAuthorModal = () => {
        const authorModal = this.PopulateAuthorModal();
        SetTempTarget(authorModal);
        this.setState({
            AuthorModal: authorModal,
        });
    }
    PopulateAuthorModal = (modal = null) => {
        const Id = CheckObjectNumber(modal, 'Id');
        const UserId = CheckObjectNumber(modal, 'UserId');
        const Password = CheckObjectStringEmpty(modal, 'Password');
        const Email = CheckObjectStringEmpty(modal, 'Email');
        const Name = CheckObjectStringEmpty(modal, 'Name');
        const DialingCode = CheckObjectStringEmpty(modal, 'DialingCode');
        const PhoneNumber = CheckObjectStringEmpty(modal, 'PhoneNumber');
        const ContactNumber = DialingCode + PhoneNumber;
        const IsAdmin = CheckObjectBoolean(modal, 'IsAdmin');
        const IsTeacher = CheckObjectBoolean(modal, 'IsTeacher');     //2024.07.18
        const IsViewer = CheckObjectBoolean(modal, 'IsViewer');
        const IsActive = CheckObjectBoolean(modal, 'IsActive');
        const ForceResetPassword = CheckObjectBoolean(modal, 'ForceResetPassword');
        const IsAllowResetOnRoomResult = CheckObjectBoolean(modal, 'IsAllowResetOnRoomResult');
        const FirstPageToLoad = CheckObjectStringEmpty(modal, 'FirstPageToLoad');     //2024.09.13
        const HideDashboard = CheckObjectBoolean(modal, 'HideDashboard'); //2024.09.13
        return {
            Id, UserId, Password, Email, Name, DialingCode, PhoneNumber, ContactNumber,
            IsAdmin, IsTeacher, IsViewer, IsActive, ForceResetPassword, IsAllowResetOnRoomResult,
            FirstPageToLoad, HideDashboard
        };
    }
    AuthorModalComponents = () => {

        let components = [];
        const targetProfile = this.state.AuthorModal;

        //Email.
        components.push(<div key='author-email' className="form-group">
            <label>Email Address</label>
            {GetInputComponent(InputType.Text, null, targetProfile, 'Email', null, 'Email', this.state.locale, this.SaveAuthorModal, null, false)}
        </div>);

        //Name.
        components.push(<div key='author-name' className="form-group">
            <label>Name</label>
            {GetInputComponent(InputType.Text, null, targetProfile, 'Name', null, 'Name', this.state.locale, this.SaveAuthorModal, null, false)}
        </div>);

        //Password.
        components.push(<div key='author-password' className="form-group">
            <label>Password</label>
            {GetInputComponent(InputType.Text, null, targetProfile, 'Password', null, 'Password', this.state.locale, this.SaveAuthorModal, null, false)}
        </div>);

        //Dialing Code.
        components.push(<div key='author-dialing-code' className="form-group">
            <label>DialingCode</label>
            {GetInputComponent(InputType.Text, null, targetProfile, 'DialingCode', null, 'Dialing Code', this.state.locale, this.SaveAuthorModal, null, false)}
        </div>);

        //Phone Number.
        components.push(<div key='author-phone-number' className="form-group">
            <label>Phone Number</label>
            {GetInputComponent(InputType.Text, null, targetProfile, 'PhoneNumber', null, 'Phone Number', this.state.locale, this.SaveAuthorModal, null, false)}
        </div>);

        //IsAdmin.
        components.push(<div key='author-isAdmin' className="form-group">
            <label>Admin</label>
            {GetInputComponent(InputType.Checkbox, null, targetProfile, 'IsAdmin', null, 'IsAdmin', this.state.locale, this.SaveAuthorModal, null, false)}
        </div>);

        //2024.07.18
        //IsTeacher.
        components.push(<div key='author-isAdmin' className="form-group">
            <label>Teacher</label>
            {GetInputComponent(InputType.Checkbox, null, targetProfile, 'IsTeacher', null, 'IsTeacher', this.state.locale, this.SaveAuthorModal, null, false)}
        </div>);

        //IsViewer.
        components.push(<div key='author-isViewer' className="form-group">
            <label>Viewer</label>
            {GetInputComponent(InputType.Checkbox, null, targetProfile, 'IsViewer', null, 'IsViewer', this.state.locale, this.SaveAuthorModal, null, false)}
        </div>);

        //IsActive.
        components.push(<div key='author-isActive' className="form-group">
            <label>Active</label>
            {GetInputComponent(InputType.Checkbox, null, targetProfile, 'IsActive', null, 'IsActive', this.state.locale, this.SaveAuthorModal, null, false)}
        </div>);

        //ForceResetPassword.
        components.push(<div key='author-forceResetPassword' className="form-group">
            <label>Force Reset Password on first login</label>
            {GetInputComponent(InputType.Checkbox, null, targetProfile, 'ForceResetPassword', null, 'ForceResetPassword', this.state.locale, this.SaveAuthorModal, null, false)}
        </div>);

        //IsAllowResetOnRoomResult.
        components.push(<div key='author-isAllowResetOnRoomResult' className="form-group">
            <label>Enable Room Result Reset feature on Student</label>
            {GetInputComponent(InputType.Checkbox, null, targetProfile, 'IsAllowResetOnRoomResult', null, 'IsAllowResetOnRoomResult', this.state.locale, this.SaveAuthorModal, null, false)}
        </div>);

        //2024.09.13
        components.push(<div key='author-firstPageToLoad' className="form-group">
            <label>First Page To Load</label>
            <div className='row'>
                <div className='col'>
                    {
                        GetInputComponent(InputType.Select,
                            getMenuOptions(),
                            targetProfile, 'FirstPageToLoad', null, 'FirstPageToLoad', this.state.locale,
                            this.SaveAuthorModal, null, false)
                    }
                </div>
            </div>
        </div>);

        //2024.09.13
        components.push(<div key='author-hideDashboard' className="form-group">
            <label>Hide Dashboard</label>
            {GetInputComponent(InputType.Checkbox, null, targetProfile, 'HideDashboard', null, 'HideDashboard', this.state.locale, this.SaveAuthorModal, null, false)}
        </div>);

        //return.
        return (components);
    }
    SaveAuthorModal = (tempTarget = null) => {
        if (CheckNullValue(tempTarget) !== null)
            this.setState({ AuthorModal: tempTarget, });
    }
    CreateAuthor_ViaApi = async () => {
        if (this.state.AuthorModal === null)
            return null;

        let success = false;
        let errorMessage = '';
        let responseData = null;

        this.setState({
            IsLoading: true,
        });
        useAppService.getState().setModal('', 'creating user author...', null, AlertMode.Loading);

        const authorModal = this.PopulateAuthorModal(this.state.AuthorModal);
        const { authorId } = GetPropIds(useGlobal.getState().user);
        const json = JSON.stringify({
            Secret: SecretKey.Admin,
            AuthorId: authorId,
            Model: authorModal,
        });

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Author/Create';

        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('CreateAuthor_ViaApi', JSON.stringify(data));
                success = CheckObjectBoolean(data, 'success');
                if (success)
                    responseData = CheckObjectNullValue(data, 'data');
                else
                    errorMessage = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                errorMessage = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'api-author-create (error)\n' + error.message);
            });

        //refresh list & close create modal ui.
        await this.LoadAuthorList_ViaApi();
        this.ToggleCreateNewAuthorModal();
        await Delay(300);

        //open edit modal ui.
        const findIndex = this.state.TableList.findIndex(x => x.email === authorModal.Email);
        if (findIndex > -1)
            this.ToggleEditAuthorDetailsModal(findIndex);

        this.setState({
            IsLoading: false,
        });
        useAppService.getState().setModal();    //close any alert.
        await Delay(500);
        if (success)
            useAppService.getState().setModal('', `Author &#60;${authorModal.Name}&#62; has been created successfully.`);
        else
            useAppService.getState().setModal('', 'Failed to create author.<br /><br />' + errorMessage);

        return { success, message: errorMessage, data: responseData };
    }
    //#endregion === Author - Create ===

    //#region === Author - Delete ===
    ToggleDeleteAuthorModal = (forceClose = false) => {
        this.setState({
            Delete_AuthorModal_Toggle: forceClose ? false : !this.state.Delete_AuthorModal_Toggle,
        });
    }
    DeleteAuthor_ViaApi = async () => {
        if (this.state.SelectedAuthorDetails === null)
            return null;

        let success = false;
        let errorMessage = '';
        let responseData = null;

        this.setState({
            // IsLoading: true,
        });
        useAppService.getState().setModal('', 'deleting user author...', null, AlertMode.Loading);

        const authorModal = this.PopulateAuthorModal(this.state.SelectedAuthorDetails);
        const { authorId } = GetPropIds(useGlobal.getState().user);
        const json = JSON.stringify({
            Secret: SecretKey.Admin,
            AuthorId: authorId,
            Model: authorModal,
        });
        if (this.state.isDevMode)
            console.log('DeleteAuthor_ViaApi', json);

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Author/Delete';

        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('DeleteAuthor_ViaApi', JSON.stringify(data));
                success = CheckObjectBoolean(data, 'success');
                if (success)
                    responseData = CheckObjectNullValue(data, 'data');
                else
                    errorMessage = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                errorMessage = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'api-author-delete (error)\n' + error.message);
            });

        //refresh list & close edit/delete modal ui.
        await this.LoadAuthorList_ViaApi();
        this.ToggleEditAuthorDetailsModal();
        this.ToggleDeleteAuthorModal(true);
        await Delay(300);

        this.setState({
            IsLoading: false,
        });
        useAppService.getState().setModal();    //close any alert.
        await Delay(500);
        if (success)
            useAppService.getState().setModal('', `Author &#60;${authorModal.Name}&#62; has been deleted successfully.`);
        else
            useAppService.getState().setModal('', 'Failed to delete author.<br /><br />' + errorMessage);

        return { success, message: errorMessage, data: responseData };
    }
    //#endregion

    //#region === Organizer List related. ===
    ToggleOrganizerListModal = (cancel = false) => {
        this.setState({
            OrganizerListModal_Toggle: !this.state.OrganizerListModal_Toggle,
        }, () => {
            if (this.state.OrganizerListModal_Toggle === false) {
                //reset.
                this.setState({
                    SelectedTableItemIndex: -1,
                });

                if (cancel)
                    this.ResetTableList();
            }
        });
    }
    //2023.11.17
    LoadOrganizers_ViaApi = async (index) => {

        this.setState({
            SelectedTableItemIndex: index,
        });
        await DelayUntil(() => this.state.SelectedTableItemIndex > -1);

        //load organizer list if empty.
        useAppService.getState().setModal('', 'fetching organizers...', null, AlertMode.Loading);
        this.setState({
            IsLoading: true,
            OrganizerList: [],
            IsOrganizerListLoaded: false,
        });
        await Delay(1000);

        const { authorId } = GetPropIds(useGlobal.getState().user);

        let _List = [];
        const targetAuthorId = CheckObjectNumber(this.state.TableList[this.state.SelectedTableItemIndex], 'id');
        if (this.state.isDevMode)
            console.log('authorId', targetAuthorId);

        if (targetAuthorId > 0) {
            await fetch(GlobalSetting.ApiUrl
                + 'Api/LearningCentre/Organizer/List?secret=' + this.state.SecretKey + '&authorId=' + targetAuthorId + '&requestAuthorId=' + authorId,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        // 'Content-Type': 'application/json',
                    }
                })
                .then(res => res.json())
                .then(data => {
                    // if (this.state.isDevMode)
                    //     console.log(JSON.stringify(data));
                    // success = data.success;
                    if (data.success) {
                        if (CheckObjectNullValue(data, 'data') !== null)
                            if (Array.isArray(data.data.list) && Array(data.data.list).length > 0)
                                _List = data.data.list;
                            else
                                if (this.state.isDevMode)
                                    console.log('Organizer list is empty.');
                    }
                    else {
                        useAppService.getState().setModal('Load Failed', 'Failed to retrive organizer list.'
                            + (data.message === null || data.message === '' ? null : '<br /><br />Error:<br />' + data.message));
                        if (this.state.isDevMode)
                            console.log('Error', 'api-organizer-load-list (failed)\n' + JSON.stringify(data));
                    }
                })
                .catch(error => {
                    useAppService.getState().setModal('Api Load Failed', 'Failed to load organizer list.'
                        + (error.message === null || error.message === '' ? null : '<br /><br />Error:<br />' + error.message));
                    if (this.state.isDevMode)
                        console.log('Error', 'api-organizer-load-list (error)\n' + error.message);
                });
        }
        if (Array.isArray(_List)) {
            let t_list = [];
            for (let i = 0; i < _List.length; i++) {
                let t_item = _List[i];
                if (Array.isArray(_List[i]['customPermissions'])) {
                    const t_permissions = await this.GetCustomPermissions(_List[i]['customPermissions']);
                    t_item['customPermissions'] = t_permissions;
                }
                t_list.push(t_item);
            }
            _List = t_list;
        }
        this.setState({
            IsLoading: false,
            OrganizerList: _List,
            IsOrganizerListLoaded: true,
        }, () => {
            ConsoleLog(`LoadOrganizers_ViaApi (List) \n ${JSON.stringify(_List)}`);
            if (_List.length > 0)
                useAppService.getState().setModal();
            else
                useAppService.getState().setModal('Failed', 'Failed to fetch organizer list.');
        });

        //show organizer list modal.
        if (this.state.IsOrganizerListLoaded)
            if (this.state.OrganizerList.length > 0)
                this.ToggleOrganizerListModal();
    }
    //2023.11.17
    SetOrganizerByTableItem = (index) => {
        if (this.state.OrganizerList.length > 0) {
            let _List = this.state.TableList;
            if (CheckObjectNullValue(_List[this.state.SelectedTableItemIndex], 'organizers') === null)
                _List[this.state.SelectedTableItemIndex]['organizers'] = [];

            const organizer = this.state.OrganizerList[index];
            let author_organizers = _List[this.state.SelectedTableItemIndex]['organizers'];
            if (author_organizers.findIndex(x => x.id === organizer.id) < 0) {
                //add to author's organizer list.
                author_organizers.push(organizer);
            }
            else {
                //remove from author's organizer list.
                author_organizers = author_organizers.filter(x => x.id !== organizer.id);
            }
            _List[this.state.SelectedTableItemIndex]['organizers'] = author_organizers;
            this.setState({
                TableList: _List,
            });
        }
    }
    //2023.11.17
    OrganizerListComponent = () => {
        return (<table className='table table-bordered tb-row-hover' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
            <thead>
                <tr>
                    <th style={{ textAlign: 'center' }}>#</th>
                    <th style={{ textAlign: 'left' }} colSpan={2}>Display Name</th>
                    <th style={{ textAlign: 'center' }}>Permissions</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.state.OrganizerList.length === 0 ?
                        <tr><td colSpan={3} align='center'>- list is empty -</td></tr>
                        :
                        this.state.OrganizerList.map((data, key) => {
                            let linked_organizer = false;
                            if (CheckObjectNullValue(this.state.TableList[this.state.SelectedTableItemIndex], 'organizers') !== null)
                                linked_organizer = this.state.TableList[this.state.SelectedTableItemIndex].organizers.findIndex(x => Number(x.id) === Number(data.id)) > -1;

                            //2025.01.21
                            let edit_btn_disabled = false;
                            if (CheckObjectNullValue(this.state.CachedTableList[this.state.SelectedTableItemIndex], 'organizers') !== null)
                                edit_btn_disabled = this.state.CachedTableList[this.state.SelectedTableItemIndex].organizers.findIndex(x => Number(x.id) === Number(data.id)) < 0;

                            return (<tr
                                key={'k_row_' + key}
                                id={'id_row_' + key}
                                // onClick={() => this.SetOrganizerByTableItem(key)}
                                style={{ cursor: 'pointer' }}
                                title={(edit_btn_disabled ? '- Check & Save before continue edit permissions -\n\n' : '') + CheckObjectStringEmpty(data, 'schoolAddress')}
                            >
                                <td>{key + 1}</td>
                                <td onClick={() => this.SetOrganizerByTableItem(key)}>
                                    <input type='checkbox' checked={linked_organizer} readOnly={true} style={{ marginTop: 5, cursor: 'pointer' }}></input>
                                </td>
                                <td align='left' style={linked_organizer ? { fontWeight: 'bold' } : {}}
                                    onClick={() => this.SetOrganizerByTableItem(key)}
                                >{CheckObjectStringEmpty(data, 'displayName', '-')}</td>
                                <td>
                                    <button type='button' className='btn btn-primary'
                                        onClick={() => this.ToggleEditOrganizerPermissionsModal(key)}
                                        disabled={edit_btn_disabled}
                                        title={edit_btn_disabled ? '- Check & Save before continue edit permissions -' : 'Edit Organizers Permission'}
                                    >Edit</button>
                                </td>
                            </tr>);
                        })
                }
            </tbody>
        </table>);
    }
    ToggleEditOrganizerPermissionsModal = async (index = -1) => {
        const toggle = !this.state.EditOrganizerPermissionsModal_Toggle;
        if (toggle) {
            const organizerList = this.state.OrganizerList;
            if (index > -1 && Array.isArray(organizerList)) {
                const organizer = organizerList[index];
                const updated_organizer = await this.GetCustomPermissions(organizer);
                this.setState({
                    EditOPModal: updated_organizer,
                    CachedEditOPModal: JSON.parse(JSON.stringify(updated_organizer)),
                });
            }
        }
        else {
            this.setState({
                EditOPModal: null,
                CachedEditOPModal: null,
            });
        }
        this.setState({
            EditOrganizerPermissionsModal_Toggle: index < 0 ? false : toggle,
        });
    }
    ResetAuthorCurrentOrganizerPermissions = () => {
        this.setState({
            EditOPModal: JSON.parse(JSON.stringify(this.state.CachedEditOPModal)),
        });
    }
    EditOrganizerPermissionComponent = () => {
        let components = [];

        const organizer = this.state.EditOPModal;
        if (organizer !== null) {

            components.push(<table cellPadding={5} width={640}>
                <tbody>
                    <tr><td width={125}>Display Name</td><td>:</td><td><b>{CheckObjectStringEmpty(organizer, 'displayName')}</b></td></tr>
                    <tr><td>School Code</td><td>:</td><td>{CheckObjectStringEmpty(organizer, 'schoolCode')}</td></tr>
                    <tr><td>School Address</td><td>:</td><td>{CheckObjectStringEmpty(organizer, 'schoolAddress')}</td></tr>
                </tbody>
            </table>);

            components.push(<hr />);

            components.push(<div className='row'><div className='col'>{this.PermissionCheckboxesComponent_Organizer(organizer)}</div></div>);
        }

        return components;
    }
    HandleEditPermission_Organizer = async (ele = null, selectAll = false) => {
        // const ele = e.currentTarget;
        if (ele === null)
            return null;
        // ConsoleLog(`HandleEditPermission_Organizer ${ele.id}, ${ele.name}, ${ele.value}, ${ele.checked}`);

        const splits = String(ele.id).split('_');
        // const authorId = Number(splits[0]);
        const section = splits[1];     //aka. title
        const action = selectAll ? 'select-all' : splits[2];    //aka. Read/Download/Upload/etc

        let modal = JSON.parse(JSON.stringify(this.state.EditOPModal));

        let section_index = -1;
        let action_index = -1;

        // const t_permissions = await this.GetCustomPermissions(modal.customPermissions);
        const t_permissions = modal.customPermissions;
        let permissions = JSON.parse(JSON.stringify(t_permissions));

        for (let p = 0; p < permissions.length; p++) {
            const permission = Object.entries(permissions[p]);
            const title = String(permission[0][0]);
            if (title === section) {
                // console.log('permission', JSON.stringify(permission));
                // console.log('title', title);
                section_index = p;
                const actions = Object.entries(permission[0][1]);
                for (let s = 0; s < actions.length; s++) {
                    const skey = String(actions[s][0]);
                    if (skey === action) {
                        action_index = s;
                        // console.log('skey', skey);
                        break;
                    }
                }
                break;
            }
        }

        ConsoleLog('HandleEditPermission_Organizer ' + section + ' ' + action + ' ' + (selectAll ? 'selectAll' : 'single') + ' ' + section_index + ' ' + action_index);
        // ConsoleLog('(before) ' + JSON.stringify(permissions[section_index]));
        // ConsoleLog('(before) ' + JSON.stringify(permissions[section_index][section]));
        // ConsoleLog('(before) ' + JSON.stringify(permissions[section_index][section][action]));

        if (section_index > -1) {
            if (selectAll === false) {
                if (action_index > -1)
                    permissions[section_index][section][action] = !ele.checked;
            }
            else {
                const subCategories = Object.entries(permissions[section_index][section]);
                for (let s = 0; s < subCategories.length; s++) {
                    const skey = String(subCategories[s][0]);
                    permissions[section_index][section][skey] = !ele.checked;
                }
            }
        }
        // ConsoleLog('(after) ' + JSON.stringify(permissions[sectionIndex]));
        // ConsoleLog('(after) ' + JSON.stringify(permissions[sectionIndex][section]));
        // ConsoleLog('(after) ' + JSON.stringify(permissions[sectionIndex][section][action]));

        modal.customPermissions = permissions;
        modal.isDirty = JSON.stringify(this.state.CachedEditOPModal.customPermissions)
            !== JSON.stringify(modal.customPermissions);

        this.setState({
            EditOPModal: modal,
        }, () => {
            ConsoleLog(`HandleEditPermission_Organizer (done) \n ${JSON.stringify(permissions[section_index])}`);
        });
        await Delay(0);
    }
    PermissionCheckboxesComponent_Organizer = (targetProfile = null) => {

        let components = [];

        const profileWithCustomPermissions = targetProfile;
        // console.log('teacher (Organizer_PermissionCheckboxesComponent) \n' + JSON.stringify(profileWithCustomPermissions));
        // console.log('teacher (Organizer_PermissionCheckboxesComponent) \n' + JSON.stringify(profileWithCustomPermissions['customPermissions']));

        profileWithCustomPermissions['customPermissions'].map((data, key) => {
            const permissions = Object.entries(data).filter(x => x !== null);
            permissions.map((permission, pkey) => {

                // console.log(JSON.stringify(permission));

                const title = permission[0];
                const actions = Object.entries(permission[1]);
                // const subCategories = permission[1];

                // console.log(JSON.stringify(permission[0]));
                // console.log(JSON.stringify(permission[1]));
                // console.log(JSON.stringify(subCategories));

                let totalChecked = 0;
                let subComponents = [];
                for (let s = 0; s < actions.length; s++) {
                    const actionKey = String(actions[s][0]);
                    const checked = CheckBoolean(actions[s][1]);
                    const id = profileWithCustomPermissions.id + '_' + title + '_' + actionKey;
                    // console.log(`Organizer_PermissionCheckboxesComponent (${title}) | ${skey} ${String(checked)} | ${JSON.stringify(subCategories[s])}`);
                    subComponents.push(<dd style={{ display: 'inline-grid', padding: 5 }}>
                        <input type='checkbox' id={id} name={id}
                            readOnly={true}
                            checked={checked}
                            style={{ cursor: 'pointer' }}
                            onChange={() => this.HandleEditPermission_Organizer({ id: id, name: id, value: '', checked: checked }, false)}
                        />
                        <label htmlFor={id} style={{ cursor: 'pointer' }}>{actionKey.charAt(0).toUpperCase() + actionKey.slice(1)}</label>
                    </dd>);
                    if (checked)
                        totalChecked += 1;
                }
                const selectAllId = profileWithCustomPermissions.Id + '_' + title + '_select-all';
                const checkedAll = totalChecked === actions.length;
                subComponents.push(<dd style={{ display: 'inline-grid', }}>
                    <input type='checkbox' id={selectAllId} name={selectAllId}
                        checked={checkedAll}
                        style={{ cursor: 'pointer' }}
                        onChange={() => this.HandleEditPermission_Organizer({ id: selectAllId, name: selectAllId, value: '', checked: checkedAll }, true)}
                        readOnly={true}
                    />
                    <label htmlFor={selectAllId} style={{ cursor: 'pointer' }}>Select All</label>
                </dd>);
                //assign.
                components.push(<>
                    <dt>{title.charAt(0).toUpperCase() + title.slice(1)}</dt>
                    {subComponents}
                </>);

                return null;
            });
            return null;
        });
        return (<dl style={{ margin: 0, marginBottom: -18 }}>{components}</dl>);
    }
    UpdateAuthorOrganizerPermissions_ViaAPI = async () => {

        let message = [];

        const updated_organizer = this.state.EditOPModal;
        if (updated_organizer === null)
            message.push('invalid modal.');

        let organizerList = this.state.OrganizerList;
        if (Array.isArray(organizerList) === false)
            message.push('invalid organizer list.');

        const organizer_index = organizerList.findIndex(x => Number(x.id) === Number(updated_organizer.id));
        if (organizer_index < 0)
            message.push('invalid modal index.');

        if (message.length > 0) {
            useAppService.getState().setModal('Error', message.join('<br />'));
            return null;
        }

        useAppService.getState().setModal('', 'updating permissions...', null, AlertMode.Loading);
        this.setState({
            EditOrganizerPermissions_Processing: true,
        });
        await Delay(0);

        const { authorId } = GetPropIds(useGlobal.getState().user);
        const targetAuthorId = CheckObjectNumber(this.state.TableList[this.state.SelectedTableItemIndex], 'id');

        let modal = JSON.stringify({
            Secret: SecretKey.Admin,
            AdminAuthorId: authorId,
            AuthorId: targetAuthorId,
            OrganizerId: Number(updated_organizer.id),
            CustomPermissions: JSON.stringify(updated_organizer['customPermissions']),
        });
        ConsoleLog(`UpdateAuthorOrganizerPermissions_ViaAPI (modal) \n ${modal}`);

        let success = false;
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Admin/Author/Organizer/CustomPermissions/Update',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: modal,
            })
            .then(res => res.json())
            .then(data => {
                ConsoleLog(`UpdateAuthorOrganizerPermissions_ViaAPI (response) \n ${JSON.stringify(data)}`);
                success = CheckObjectBoolean(data, 'success');
                if (!success) {
                    message.push(CheckObjectStringEmpty(data, 'message'));
                    ConsoleLog('(Error) api-author-organizer-permissions-update (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                message.push(error.message);
                ConsoleLog('(Error) api-author-organizer-permissions-update (error)\n' + error.message);
            });

        if (success) {
            organizerList[organizer_index] = updated_organizer;
            useAppService.getState().setModal('', 'Permissions has been updated.');
        }
        else {
            const errorMessage = message.length > 0 ? '<br />' + message.join('<br />') : '';
            useAppService.getState().setModal('Failed', 'Failed to update permissions.' + errorMessage);
        }

        this.setState({
            EditOrganizerPermissions_Processing: false,
            OrganizerList: organizerList,
            CachedEditOPModal: JSON.parse(JSON.stringify(updated_organizer)),
        }, () => {
            if (success)
                ConsoleLog(`UpdateAuthorOrganizerPermissions_ViaAPI (Updated) \n ${JSON.stringify(organizerList)}`);
        });
    }
    //#endregion === Organizer List related. ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (
            <div className="">
                <table className="table page-header">
                    <tbody>
                        <tr>
                            <td className="left">
                                <h5>Manage Author</h5>
                                <button
                                    type="button"
                                    className="btn-link"
                                    onClick={() => this.LoadAuthorList_ViaApi()}
                                    title="Refresh Author list"
                                ><i className="fa fa-refresh" title="Refresh Author list"></i></button>
                            </td>
                            <td className="center">
                                <ButtonGroup>
                                    {
                                        isOrganizerRadios.map((radio, key) => {
                                            return (<ToggleButton
                                                key={key}
                                                id={`radio-${key}`}
                                                type='radio'
                                                variant={key % 2 ? 'outline-success' : 'outline-danger'}
                                                name="radio-isOrganizer"
                                                value={radio.value}
                                                checked={this.state.IsOrganizer === radio.value}
                                                onChange={(e) => {
                                                    this.setState({
                                                        IsOrganizer: e.currentTarget.value,
                                                    }, () => {
                                                        this.LoadAuthorList_ViaApi();
                                                    });
                                                }}
                                            >{radio.name}</ToggleButton>);
                                        })
                                    }
                                </ButtonGroup>
                            </td>
                            <td className="right">
                                <Button
                                    variant='outline-primary'
                                    // disabled={true}
                                    onClick={() => this.ToggleCreateNewAuthorModal()}
                                >New Author</Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {
                    this.state.IsLoading && !this.state.IsTableListLoaded ?
                        <div style={{ padding: 10 }}>
                            <ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10, }} />
                        </div>
                        :
                        this.TableListComponent()
                }

                {/* Author Organizer List - Manage Author Organizer - Modal */}
                <Modal show={this.state.OrganizerListModal_Toggle}
                    size='lg'
                    onHide={() => this.ToggleOrganizerListModal(true)}
                    centered
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title style={{ fontSize: 20 }}>Manage Author's Organizer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>
                        <div className='row'><div className='col'><h4>{CheckObjectStringEmpty(this.state.TableList[this.state.SelectedTableItemIndex], 'name')}</h4></div></div>
                        <div className='row'><div className='col'><h5 style={{ color: 'gray' }}>{CheckObjectStringEmpty(this.state.TableList[this.state.SelectedTableItemIndex], 'email')}</h5></div></div>
                        <br />
                        {this.OrganizerListComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.ToggleOrganizerListModal(true)}>Cancel</Button>
                        <Button variant="secondary" onClick={() => this.ResetTableList()}>Reset</Button>
                        <Button variant="primary" onClick={() => this.UpdateAuthor_ViaAPI(this.state.SelectedTableItemIndex, true)}>Save</Button>
                    </Modal.Footer>
                    <div className="modal-ui-side-info-panel" style={{ position: 'fixed', left: 50 }}>
                        <h4>{CheckObjectStringEmpty(this.state.TableList[this.state.SelectedTableItemIndex], 'name')}</h4>
                        <h5 style={{ color: 'gray' }}>{CheckObjectStringEmpty(this.state.TableList[this.state.SelectedTableItemIndex], 'email')}</h5>
                    </div>
                </Modal>

                {/* 2024.07.24 */}
                {/* Author > Organizer - Manage Custom Permissions - Modal */}
                <Modal show={this.state.EditOrganizerPermissionsModal_Toggle}
                    // size='lg'
                    dialogClassName='modal-700w'
                    onHide={() => this.ToggleEditOrganizerPermissionsModal()}
                    centered
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title style={{ fontSize: 20 }}>Edit Current Organizer's Permissions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: 'grid', justifyContent: 'center' }}>{this.EditOrganizerPermissionComponent()}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.ToggleEditOrganizerPermissionsModal()}>Cancel</Button>
                        <Button variant="secondary" onClick={() => this.ResetAuthorCurrentOrganizerPermissions()}>Reset</Button>
                        <Button variant="primary" onClick={() => this.UpdateAuthorOrganizerPermissions_ViaAPI()}>Save</Button>
                    </Modal.Footer>
                </Modal>

                {/* Author Details - Manage Author Details - Modal */}
                <Modal show={this.state.AuthorDetailsModal_Toggle}
                    onHide={() => this.ToggleEditAuthorDetailsModal()}
                    centered
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title style={{ fontSize: 20 }}>Manage Author Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'left' }}>
                        {/* <div className='row'><div className='col'><b>{CheckObjectStringEmpty(this.state.SelectedAuthorDetails, 'name')}</b></div></div> */}
                        <div className='row'><div className='col'><b>{CheckObjectStringEmpty(this.state.SelectedAuthorDetails, 'email')}</b></div></div>
                        <br />
                        {this.AuthorDetailsComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" className='pull-left' onClick={() => this.ToggleDeleteAuthorModal()} disabled={this.state.IsLoading}>Delete</Button>
                        <Button variant="secondary" onClick={() => this.ToggleEditAuthorDetailsModal()} disabled={this.state.IsLoading}>Cancel</Button>
                        <Button variant="secondary" onClick={() => this.ResetAuthorDetails()} disabled={this.state.IsLoading}>Reset</Button>
                        <Button variant="primary" onClick={() => this.ConfirmUpdateAuthorDetails()} disabled={this.state.IsLoading}>Save</Button>
                    </Modal.Footer>
                </Modal >

                {/* Author - Create - Modal */}
                <Modal show={this.state.Create_AuthorModal_Toggle}
                    onHide={() => this.ToggleCreateNewAuthorModal()}
                    centered
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title style={{ fontSize: 20 }}>Author - Create</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'left' }}>
                        {this.AuthorModalComponents()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.ToggleCreateNewAuthorModal()} disabled={this.state.IsLoading}>Cancel</Button>
                        <Button variant="secondary" onClick={() => this.ResetAuthorModal()} disabled={this.state.IsLoading}>Reset</Button>
                        <Button variant="primary" onClick={() => this.CreateAuthor_ViaApi()} disabled={this.state.IsLoading}>Create</Button>
                    </Modal.Footer>
                </Modal >

                {/* Author - Delete - Modal */}
                <Modal show={this.state.Delete_AuthorModal_Toggle}
                    onHide={() => this.ToggleDeleteAuthorModal(true)}
                    centered
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title style={{ fontSize: 20 }}>Author - Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'left' }}>
                        <Row><Col>Author deletion is not reversible.</Col></Row>
                        <Row><Col><b>{CheckObjectStringEmpty(this.state.SelectedAuthorDetails, 'email')}</b></Col></Row>
                        <Row><Col><b>{CheckObjectStringEmpty(this.state.SelectedAuthorDetails, 'name')}</b></Col></Row>
                        <Row><Col>Are you sure you want to delete current author ?</Col></Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.ToggleDeleteAuthorModal(true)} disabled={this.state.IsLoading}>Cancel</Button>
                        <Button variant="primary" onClick={() => this.DeleteAuthor_ViaApi()} disabled={this.state.IsLoading}>Confirm</Button>
                    </Modal.Footer>
                </Modal >

            </div>
        );
    }
}